import React, {useMemo, useState, useCallback} from 'react'
import GeneralContainer from '../../../components/layout/GeneralContainer/GeneralContainer';
import './TicketsList.scss';
import useTideTable from "../../../hooks/useTideTable";
import {ticketsListSGroups} from "../../../services/modelUtils/ticketUtils";
import TideReactTable from "../../../components/TideReactTable/TideReactTable";
import TicketDetail from "../components/TicketDetail/TicketDetail";
import Select from 'react-select';
import Menu from "../Menu/Menu";
import TextInput from '../../../components/forms/TextInput/TextInput';
import useFormState from '../../../hooks/useFormState';
import moment from 'moment';
import { getUserFullName } from '../../../services/modelUtils/userUtils';

const TicketsList = () => {
    const [selectedTicket, setSelectedTicket] = useState(null);
    const { form, handleSimpleChange } = useFormState({});
    const [filters, setFilters] = useState({
        sGroups: ticketsListSGroups,
        'status': 'pending',
        'order[createdDate]': 'DESC'
    });

    const tableColumns = useMemo(() => ([
        {
            Header: 'ID',
            accessor: 'id'
        },
        {
            Header: 'Estado',
            accessor: 'status',
        },
    
        {
            Header: 'Fecha de registro',
            accessor: function (ticket) {
    
                if (!ticket.createdDate) {
                    return 'Sin fecha';
                }
    
                return moment(ticket.createdDate).format('DD/MM/YYYY HH:mm');
            },
        },
        {
            Header: 'Número de vidas',
            accessor: 'numOfLives',
        },
        {
            Header: 'Usuario',
            accessor: function (ticket) {
                return getUserFullName(ticket?.user)
            },
        },
        {
            Header: 'Email',
            accessor: 'user.email'
        },
        {
            Header: 'Acciones',
            accessor: function (ticket) {
                return <button className='btn btn-filter' onClick={(e) => setSelectedTicket(ticket)}>Ver detalle</button>;
            }
        }
    ]), []);

    const statusOptions = [
        {value: 'all', label: 'Todos'},
        {value: 'pending', label: 'Pendientes'},
        {value: 'rejected', label: 'Rechazados'},
        {value: 'approved', label: 'Aprobados'},
    ];

    const changeStatusFilter = useCallback((option) => {
        const newFilters = {...filters};
        if (option.value === 'all') {
            delete newFilters.status;
            setFilters({...newFilters});
            return;
        }
        setFilters({...newFilters, 'status': option.value});

    }, [setFilters, filters]);

    const requestFilters = useMemo(() => ({
        ...filters,
        id: form.ticketId,
        "user.email": form.email==='' ? undefined : form.email,
    }), [filters, form.ticketId, form.email]);

    const tideTable = useTideTable({entity: 'tickets', columns: tableColumns, requestFilters});

    const closeTicket = useCallback(() => {
        setSelectedTicket(null);
        tideTable.reload();
    }, [setSelectedTicket, tideTable]);

    const handleReload = () => {
        tideTable.reload();
    }

    return (
        <GeneralContainer className='GeneralContainer--display-block TicketsList' backButton={true} hideBackOnMobile={true} menu={false}>
            <Menu/>

                <h1 className='center'>Tickets</h1>

            <div className='table-container'>
                <div className='filters-container'>
                    <div className="filters">
                        <div className='filter'>
                            <Select onChange={changeStatusFilter} className='tide-react-select' options={statusOptions}/>
                        </div>
                        <div className="filter">
                            <TextInput
                                placeholder="Search by ticket ID"
                                onChange={handleSimpleChange('ticketId')}
                                value={form.ticketId}
                                type="number"
                            />
                        </div>
                        <div className="filter">
                            <TextInput
                                placeholder="Search by email"
                                onChange={handleSimpleChange('email')}
                                value={form.email}
                                type="email"
                            />
                        </div>
                    </div>

                    <button className='btn btn-filter' onClick={handleReload}>Recargar</button>
                </div>
                <TideReactTable {...tideTable.tableProps} />
            </div>

            {selectedTicket &&
                <TicketDetail ticket={selectedTicket} onClose={closeTicket}/>
            }
        </GeneralContainer>

    );
}

export default TicketsList;
