import avatar0Big from '../assets/images/avatars_big/0avatar_grande.png';
import avatar1Big from '../assets/images/avatars_big/1avatar_grande.png';
import avatar2Big from '../assets/images/avatars_big/2avatar_grande.png';
import avatar3Big from '../assets/images/avatars_big/3avatar_grande.png';
import avatar4Big from '../assets/images/avatars_big/4avatar_grande.png';
import avatar5Big from '../assets/images/avatars_big/5avatar_grande.png';
import avatar6Big from '../assets/images/avatars_big/6avatar_grande.png';
import avatar7Big from '../assets/images/avatars_big/7avatar_grande.png';
import avatar8Big from '../assets/images/avatars_big/8avatar_grande.png';
import avatar9Big from '../assets/images/avatars_big/9avatar_grande.png';
import avatar10Big from '../assets/images/avatars_big/10avatar_grande.png';
import avatar11Big from '../assets/images/avatars_big/11avatar_grande.png';
import avatar12Big from '../assets/images/avatars_big/12avatar_grande.png';
import avatar13Big from '../assets/images/avatars_big/13avatar_grande.png';
import avatar14Big from '../assets/images/avatars_big/14avatar_grande.png';
import avatar15Big from '../assets/images/avatars_big/15avatar_grande.png';
import avatar16Big from '../assets/images/avatars_big/16avatar_grande.png';

import avatar0Small from '../assets/images/avatars_small/0avatar.png';
import avatar1Small from '../assets/images/avatars_small/1avatar.png';
import avatar2Small from '../assets/images/avatars_small/2avatar.png';
import avatar3Small from '../assets/images/avatars_small/3avatar.png';
import avatar4Small from '../assets/images/avatars_small/4avatar.png';
import avatar5Small from '../assets/images/avatars_small/5avatar.png';
import avatar6Small from '../assets/images/avatars_small/6avatar.png';
import avatar7Small from '../assets/images/avatars_small/7avatar.png';
import avatar8Small from '../assets/images/avatars_small/8avatar.png';
import avatar9Small from '../assets/images/avatars_small/9avatar.png';
import avatar10Small from '../assets/images/avatars_small/10avatar.png';
import avatar11Small from '../assets/images/avatars_small/11avatar.png';
import avatar12Small from '../assets/images/avatars_small/12avatar.png';
import avatar13Small from '../assets/images/avatars_small/13avatar.png';
import avatar14Small from '../assets/images/avatars_small/14avatar.png';
import avatar15Small from '../assets/images/avatars_small/15avatar.png';
import avatar16Small from '../assets/images/avatars_small/16avatar.png';

export const avatars = [
    {id: "0", big: avatar0Big, small: avatar0Small},
    {id: "1", big: avatar1Big, small: avatar1Small},
    {id: "2", big: avatar2Big, small: avatar2Small},
    {id: "3", big: avatar3Big, small: avatar3Small},
    {id: "4", big: avatar4Big, small: avatar4Small},
    {id: "5", big: avatar5Big, small: avatar5Small},
    {id: "6", big: avatar6Big, small: avatar6Small},
    {id: "7", big: avatar7Big, small: avatar7Small},
    {id: "8", big: avatar8Big, small: avatar8Small},
    {id: "9", big: avatar9Big, small: avatar9Small},
    {id: "10", big: avatar10Big, small: avatar10Small},
    {id: "11", big: avatar11Big, small: avatar11Small},
    {id: "12", big: avatar12Big, small: avatar12Small},
    {id: "13", big: avatar13Big, small: avatar13Small},
    {id: "14", big: avatar14Big, small: avatar14Small},
    {id: "15", big: avatar15Big, small: avatar15Small},
    {id: "16", big: avatar16Big, small: avatar16Small},
];

export const getUserAvatar = (user) => {
    return avatars.find(avatar => avatar.id === user.avatar) || avatars[0];
}
