import React, {useCallback, useLayoutEffect} from 'react';
import './GamePlay.scss';
import {getUserAvatar} from "../../../../../services/avatarUtils";
import GameControl from "../../controller/GameControl";
import soundActive from "../../assets/game/bocina_activa_web.png";
import soundInactive from "../../assets/game/bocina_inactiva_web.png";
import playBtn from "../../../../../assets/images/buttons/boton_jugar_web.png";
import playBtnOff from "../../../../../assets/images/buttons/boton_jugar_gris_web.png";
import {useSelector} from "react-redux";


const GamePlay = ({game, onGameOver}) => {

    const me = useSelector(s=>s.api.me);
    const avatar = getUserAvatar(me);

    const canvasRef = React.useRef(null);
    const [timeRemaining, setTimeRemaining] = React.useState(0);
    const [points, setPoints] = React.useState(0);
    const [sound,setSound] = React.useState(true);
    const gameControlRef = React.useRef(null);
    const [gameReady, setGameReady] = React.useState(false);
    const [gameStarted, setGameStarted] = React.useState(false);

    const toggleSound = useCallback(() => {
        if(!gameControlRef.current) return;
        if(!sound)
            gameControlRef.current.enableSound();
        else
            gameControlRef.current.disableSound();
        setSound(!sound);

    }, [sound]);

    useLayoutEffect(()=>{

        const gameControl = new GameControl({
            gameDefinition: game,
            userPoints: me.points,
            canvas:canvasRef.current,
            onTimeChange: setTimeRemaining,
            onPointsChange: setPoints,
            onGameReady: ()=>setGameReady(true),
            onGameOver
        });
        setTimeRemaining(Math.floor(gameControl.gameTime/1000));
        gameControlRef.current = gameControl;

        return ()=>{
            gameControl.stop();
        }
    },[game, onGameOver, me]);

    const startGame = useCallback(()=>{
        setGameStarted(true);
        gameControlRef.current.run();
    },[]);

    return (
        <div className={"GamePlay"}>
            <div className='top-game'>
                <button className='sound-btn' onClick={toggleSound}>
                    <img className='speaker' alt='Bocina' src={sound? soundActive:soundInactive}/>
                </button>
                <img alt={"Avatar"} className={'avatar'} src={avatar.small} />
                <p className='game-name'>
                    <span className='pre'>Creando:</span><br/>
                    <span>{game.name}</span>
                </p>
                <img alt='Receta' className='recipe-thumb' src={game.recipeThumb.web} />
                <p className='time-container'>
                    <span className='time-title'>Tiempo restante: </span>
                    <span className='time'>{timeRemaining} seg</span>
                </p>
            </div>
            <div className='game-frame'>
                {!gameStarted &&
                    <div className='play-btn-container'>
                        <button className={'start-btn'} disabled={!gameReady} onClick={startGame}>
                            <img src={gameReady?playBtn:playBtnOff} className={'play-img'} alt={'Jugar'} /><br/>
                            {!gameReady && 'Cargando...'}
                        </button>
                    </div>}
                <div className='canvas-container'>


                    <canvas className='game-canvas' ref={canvasRef}/>


                </div>
            </div>

            <div className='bottom-game'>
                <div className='ingredients'>
                    <div className='ingredients-instructions'>
                        <span>ATRAPA CON EL BOWL LOS</span><br/>
                        <span className='big'>INGREDIENTES:</span>
                    </div>
                    <div className='ingredients-list'>
                        {game.ingredients.map((ingredient) => (
                            <img key={ingredient.name} alt={ingredient.name} className='ingredient-image' src={ingredient.image}/>
                        ))}
                    </div>
                </div>
                <div className='points-block'>
                    <p className='points-title'>PUNTOS</p>
                    <p className='points'>{points}</p>
                </div>
            </div>
        </div>
    );
};

export default GamePlay;
