import React, {useCallback, useContext, useState} from 'react';
import './Register.scss';
import TextInput from "../../../components/forms/TextInput/TextInput";
import useFormState from "../../../hooks/useFormState";
import useBoolean from "../../../hooks/useBoolean";
import {prepareUserFromRegister} from "../../../services/modelUtils/userUtils";
import {ApiContext} from "../../../services/api/api-config";
import {getNotifier} from "../../../services/notifier";
import TideEntitySelect from "../../../components/forms/TideEntitySelect/TideEntitySelect";
import GeneralContainer from '../../../components/layout/GeneralContainer/GeneralContainer';
import hotCakes from "../../../assets/images/register/hotcakes_registro_web.png";
import cookieLeft from '../../../assets/images/register/pleca_inferior_izquierda_registro_web.png';
import cookieRight from '../../../assets/images/register/pleca_inferior_derecha_registro_web.png';
import footerMobile from '../../../assets/images/corners/pleca_mobile_galleta_malteada.png';
import {useNavigate, useParams} from "react-router-dom";
import {paths} from "../../../services/routes/notLoggedRoutes";
import DateSelector from "../../../components/forms/DateSelector/DateSelector";
import ReCAPTCHA from "react-google-recaptcha";
import Select from "react-select";
import { isPromotionFinishedDate } from '../../../services/promotionFinishUtils';

const googleCaptchaKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_API_KEY;

const RegisterForm = () => {
    const api = useContext(ApiContext);
    const {form, handleSimpleChange, setForm} = useFormState({});
    const [captchaCode, setCaptchaCode] = useState(null);
    const recaptchaRef = React.createRef();


    const [terms, , , toggleTerms] = useBoolean();
    const navigate = useNavigate();
    const {code} = useParams();

    const signUp = useCallback((e) => {
        e.preventDefault();
        let user = {};
        // validate terms and conditions
        if (!terms) return getNotifier().error(('Debes de aceptar los Términos y Condiciones para continuar'));
        try {
            user = prepareUserFromRegister(form);
        } catch (e) {
            return getNotifier().error(e.message);
        }
        user.captchaCode = captchaCode;

        if(code){
            user.referredCode=code
        }

        api.users.register(user)
            .then(() => {
                getNotifier().success(('Tu registro se ha completado! Ahora puedes iniciar sesión.'));
                //redirect to thank you page
                navigate(paths.thankyou);
            })
            .catch(e => {
                recaptchaRef.current.reset();
                return getNotifier().error(e.detail);
            });

    }, [navigate, api, form, terms, captchaCode, code, recaptchaRef]);
    //const loading = false;

    const handlePhoneChange = (phone) => {
        const isValid = new RegExp("[0-9]").test(phone);

        if (!isValid) {
            return;
         }
        
        setForm({...form, phone: parseInt(phone)})
    }

    return (
        <GeneralContainer backButton={true} hideBackOnMobile={true} className={"register-container"} footer>
            <div className='Register Register__container'>
                <img src={cookieLeft} className='Register__cookie-left-bottom' alt='Imagen galleta'/>
                <img src={cookieRight} className='Register__cookie-right-bottom' alt='Imagen galleta'/>

                <div className='Register__left-column'>
                    <img src={hotCakes} className='Register__cookie-image' alt='Imagen galleta'/>
                </div>
                
                {!isPromotionFinishedDate() && 
                    <div className='Register__right-column'>
                        <div className='form-container' key={'form'}>
                            <form onSubmit={signUp} className='form'>
                                {code && <span>Código de referido: {code}</span>}
                                <TextInput
                                    placeholder="NOMBRE(S)"
                                    label="name"
                                    onChange={handleSimpleChange('name')}
                                    value={form.name}
                                />
                                <TextInput
                                    label="patLastName"
                                    placeholder="APELLIDO PATERNO"
                                    onChange={handleSimpleChange('patLastName')}
                                    value={form.patLastName || ''}
                                />
                                <TextInput
                                    label="matLastName"
                                    placeholder="APELLIDO MATERNO"
                                    onChange={handleSimpleChange('matLastName')}
                                    value={form.matLastName || ''}
                                />
                                <DateSelector
                                    label='birthday'
                                    text="FECHA DE NACIMIENTO"
                                    form={form}
                                    handleSimpleChange={handleSimpleChange}
                                />
                                <Select
                                    className='tide-react-select gender'
                                    classNamePrefix='tide-react-select'
                                    placeholder="GÉNERO"
                                    options={[
                                        {value: 'M', label: 'Masculino'},
                                        {value: 'F', label: 'Femenino'},
                                        {value: 'O', label: 'Otro'},
                                    ]}
                                    onChange={handleSimpleChange('gender')}
                                    />

                                <TideEntitySelect
                                    entity={'states'}
                                    value={form.state}
                                    onChange={handleSimpleChange('state')}
                                    preload={true}
                                    additionalFilters={{'pagination':false}}
                                    additionalApiOptions={{useCommonPath: false}}
                                    placeholder="ESTADO"
                                />
                                <TextInput
                                    label="city"
                                    placeholder="CIUDAD"
                                    onChange={handleSimpleChange('city')}
                                    value={form.city || ''}
                                />
                                <TextInput
                                    label="phone"
                                    type="tel"
                                    placeholder="TELÉFONO"
                                    onChange={handlePhoneChange}
                                    value={form.phone || ''}
                                    inputProps={{maxLength: 10}}
                                />
                                <TextInput
                                    label="email"
                                    placeholder="CORREO"
                                    onChange={handleSimpleChange('email')}
                                    value={form.email || ''}
                                />

                                <TextInput
                                    label="password"
                                    placeholder="CONTRASEÑA"
                                    onChange={handleSimpleChange('password')}
                                    value={form.password || ''}
                                    type={'password'}
                                />

                                <TextInput
                                    label="confirm-password"
                                    placeholder="CONFIRMAR CONTRASEÑA"
                                    onChange={handleSimpleChange('confirmPass')}
                                    value={form.confirmPass || ''}
                                    type={'password'}
                                />
                                <div className="captcha-container">
                                    <ReCAPTCHA ref={recaptchaRef} sitekey={googleCaptchaKey} className='captcha' onChange={setCaptchaCode}/>
                                </div>
                                <div className='remember terms-text--right'>
                                    <input type="checkbox"/>{'RECORDAR SESIÓN'}
                                </div>
                                <div className='terms-text'>
                                    <input type="checkbox" value={terms} onChange={toggleTerms}/>
                                    He leído y acepto el{' '}
                                    <a className={'terms-link'} href={paths.privacy} target={'_blank'} rel="noreferrer">Aviso de Privacidad</a>{' '}
                                    / <a className={'terms-link'}  target={'_blank'} href={paths.terms} rel="noreferrer">Términos y condiciones de uso.</a>
                                </div>
                                <button type="submit" className={"oreo-button register-button"}>REGÍSTRATE</button>
                            </form>
                        </div>

                        <img src={footerMobile} className='footer-mobile' alt=""/>

                    </div>}
                
                {isPromotionFinishedDate() && <div className='finish-promo'>La promoción terminó</div>}

            </div>

        </GeneralContainer>

    );
};

export default RegisterForm;
