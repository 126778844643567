import ticketAlSuper from '../../scenes/user/TicketUpload/assets/tickets/ticket-al-super.jpg';
import ticketCasaLey from '../../scenes/user/TicketUpload/assets/tickets/ticket-casa-ley.png';
import ticketWalmart from '../../scenes/user/TicketUpload/assets/tickets/ticket-walmart.jpg';
import ticket7Eleven from '../../scenes/user/TicketUpload/assets/tickets/ticket-7-eleven.jpg';
import ticketLaComer from '../../scenes/user/TicketUpload/assets/tickets/ticket-la-comer.jpg';
import ticketChedraui from '../../scenes/user/TicketUpload/assets/tickets/ticket-chedraui.jpg';
import ticketOxxo from '../../scenes/user/TicketUpload/assets/tickets/ticket-oxxo.jpg';
import ticketSumesa from '../../scenes/user/TicketUpload/assets/tickets/ticket-sumesa.jpg';
import ticketCityMarket from '../../scenes/user/TicketUpload/assets/tickets/ticket-city-market.jpg';
import ticketGoMart from '../../scenes/user/TicketUpload/assets/tickets/ticket-go-mart.jpg';
import ticketHeb from '../../scenes/user/TicketUpload/assets/tickets/ticket-heb.jpg';
import ticketCirculoK from '../../scenes/user/TicketUpload/assets/tickets/ticket-circulo-k.jpg';
import ticketSoriana from '../../scenes/user/TicketUpload/assets/tickets/ticket-soriana.jpg';
import ticketSams from '../../scenes/user/TicketUpload/assets/tickets/ticket-sams.jpg';
import ticketCornershop from '../../scenes/user/TicketUpload/assets/tickets/ticket-cornershop.jpg';
import ticketRappi from '../../scenes/user/TicketUpload/assets/tickets/ticket-rappi.jpg';
import ticketCostco from '../../scenes/user/TicketUpload/assets/tickets/ticket-costco.jpg';
import ticketCalimax from '../../scenes/user/TicketUpload/assets/tickets/ticket-calimax.jpg';

export const ticketsListSGroups = [
    "ticket_read",
    "ticket_read_user",
    "user_read",
    "read_created_date",
    "ticket_read_ticket_products",
    "ticket_product_read",
    "ticket_product_read_product",
    "product_read",
    "ticket_read_app_file",
    "app_file_read",
];
export const ticketStatus = {
    STATUS_PENDING: "PENDING",
    STATUS_REJECTED: "REJECTED",
    STATUS_APPROVED: "APPROVED",
}

export const ticketStatusTrans = (status) => {
    switch (status) {
        case ticketStatus.STATUS_PENDING:
            return "EN REVISIÓN";
        case ticketStatus.STATUS_REJECTED:
            return "RECHAZADO";
        case ticketStatus.STATUS_APPROVED:
            return "APROBADO";
        default:
            return "";
    }
}

export const ticketStores = [
    {"name":"7-Eleven", image: ticket7Eleven, showTwoFiles: false},
    {"name":"Al Super", image: ticketAlSuper, showTwoFiles: false},
    {"name":"Bodega Aurrera", showTwoFiles: false},
    {"name":"Calimax", image: ticketCalimax, showTwoFiles: false},
    {"name":"Casa Ley", image: ticketCasaLey, showTwoFiles: false},
    {"name":"Chedraui", image: ticketChedraui, showTwoFiles: false},
    {"name":"Chedraui Selecto", image: ticketChedraui, showTwoFiles: false},
    {"name":"City Market", image: ticketCityMarket, showTwoFiles: false},
    {"name":"Cornershop", image: ticketCornershop, showTwoFiles: true},
    {"name":"Costco", image: ticketCostco, showTwoFiles: false},
    {"name":"Círculo K", image: ticketCirculoK, showTwoFiles: false},
    {"name":"Fresko La Comer", image: ticketLaComer, showTwoFiles: false},
    {"name":"Go Mart", image: ticketGoMart, showTwoFiles: false},
    {"name":"HEB", image: ticketHeb, showTwoFiles: false},
    {"name":"La comer", image: ticketLaComer, showTwoFiles: false},
    {"name":"OXXO", image: ticketOxxo, showTwoFiles: false},
    {"name":"Rappi", image: ticketRappi, showTwoFiles: true},
    {"name":"S- mart" , showTwoFiles: false},
    {"name":"Sam´s Club", image: ticketSams, showTwoFiles: false},
    {"name":"Soriana", image: ticketSoriana, showTwoFiles: false},
    {"name":"Soriana Hiper", image: ticketSoriana, showTwoFiles: false},
    {"name":"Soriana Mercado", image: ticketSoriana, showTwoFiles: false},
    {"name":"Soriana Súper", image: ticketSoriana, showTwoFiles: false},
    {"name":"Sumesa", image: ticketSumesa, showTwoFiles: false},
    {"name":"Supercito", showTwoFiles: false},
    {"name":"Súper Chedraui", image: ticketChedraui, showTwoFiles: false},
    {"name":"Walmart", image: ticketWalmart, showTwoFiles: false},
    {"name":"Walmart.com", image: ticketWalmart, showTwoFiles: false},
    {"name":"Walmart Express", image: ticketWalmart, showTwoFiles: false},
    {"name":"Walmart Supercenter", image: ticketWalmart, showTwoFiles: false},
];

