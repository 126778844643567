import React from 'react'
import GeneralContainer from '../../../components/layout/GeneralContainer/GeneralContainer';
import {paths} from "../../../services/routes/notLoggedRoutes";
import loginButton from '../../../assets/images/login-and-register/boton_inciar_sesion.png';
import {Link} from "react-router-dom";
import { useEffect } from 'react';
import './Thankyou.scss';

const Thankyou = () => {
    useEffect(() => {
        try {
            window.gtag('event', 'conversion', {
                'allow_custom_scripts': true,
                'send_to': 'DC-9238052/oreo/inter007+standard'
            });   
        } catch (error) {
            
        }        
    }, []);

    return (
        <GeneralContainer 
            className={"Thankyou"}
            backButtonRoute={paths.userHome}
        >
            <div className='Thankyou__container'>
                <div className="Thankyou__content">
                    <div className='Thankyou__content__title'>
                        GRACIAS TU REGISTRO
                        FUE EXITOSO.
                    </div>

                    <p className='Thankyou__content__description'>
                        Recuerda registrar tus tickets de compra
                        con productos Oreo&reg; participantes
                        para ganar increíbles premios.
                    </p>

                    <Link to={paths.login} className="oreo-button oreo-button--with-mt">
                        <img src={loginButton} alt="Login button" />
                    </Link>
                </div>
            </div>
        </GeneralContainer>
    );
}

export default Thankyou