import dailyImage from '../scenes/user/Ranking/assets/imagen_ranking_diario_web.png';
import dailyImageMob from '../scenes/user/Ranking/assets/imagen_ranking_diario_mobile.png';
import weeklyImage from '../scenes/user/Ranking/assets/imagen_ranking_semanal_web.png';
import weeklyImageMob from '../scenes/user/Ranking/assets/imagen_ranking_semanal_mobile.png';
import generalImage from '../scenes/user/Ranking/assets/imagen_ranking_general_web.png';
import generalImageMob from '../scenes/user/Ranking/assets/imagen_ranking_general_mobile.png';
import dailyWinners from '../scenes/user/Ranking/assets/iamgen_ganadores_diarios_web.png';
import dailyWinnersMob from '../scenes/user/Ranking/assets/imagen_ganadores_diarios_mobile.png';
import weeklyWinners from '../scenes/user/Ranking/assets/imagen_ganadores_semanales_web.png';
import weeklyWinnersMob from '../scenes/user/Ranking/assets/imagen_ganadores_semanales_mobile.png';
import moment from "moment";

export const rankingTypes = {
    daily: 'daily',
    weekly: 'weekly',
    general: 'general',
    dailyWinners: 'dailyWinners',
    weeklyWinners: 'weeklyWinners',
    publicGeneral: 'publicGeneral',
}

const nameFromUser = user=>`${user?.name||''} ${user?.patLastName||''} ${user?.matLastName||''}`;
const winnersSGroups = ["winner_read", "winner_read_user", "user_read_id", "user_read_name", "user_read_pat_last_name", "user_read_mat_last_name"];

export const rankingDefinition = {
    [rankingTypes.daily]: {
        name: 'Ranking diario',
        image: {
            web:dailyImage,
            mobile:dailyImageMob,
        },
        nameAccessor: nameFromUser,
        infoAccessor: (user)=>`${user.dailyPoints} PTS`,
        showPlaces: true,
    },
    [rankingTypes.weekly]: {
        name: 'Ranking diario',
        image: {
            web:weeklyImage,
            mobile:weeklyImageMob,
        },
        nameAccessor: nameFromUser,
        infoAccessor: (user)=>`${user.weeklyPoints} PTS`,
        showPlaces: true,
    },
    [rankingTypes.general]: {
        name: 'Ranking diario',
        image: {
            web:generalImage,
            mobile:generalImageMob,
        },
        nameAccessor: nameFromUser,
        infoAccessor: (user)=>`${user.points} PTS`,
        showPlaces: true,
    },
    [rankingTypes.dailyWinners]: {
        name: 'Ganadores diarios',
        image: {
            web:dailyWinners,
            mobile:dailyWinnersMob,
        },
        nameAccessor: winner=>nameFromUser(winner.user),
        infoAccessor: winner=>moment(winner.endDate).format('DD/MM/YYYY'),
        endpoint: 'winners',
        method: 'get',
        requestParams:{ rankingType: rankingTypes.daily, sGroups:winnersSGroups },
        showPlaces: false,
    },
    [rankingTypes.weeklyWinners]: {
        name: 'Ganadores semanales',
        image: {
            web:weeklyWinners,
            mobile:weeklyWinnersMob,
        },
        nameAccessor: winner=>nameFromUser(winner.user),
        infoAccessor: winner=>moment(winner.endDate).format('DD/MM/YYYY'),
        endpoint: 'winners',
        method: 'get',
        requestParams:{ rankingType: rankingTypes.weekly, sGroups:winnersSGroups },
        showPlaces: false,
    },
    [rankingTypes.publicGeneral]: {
        name: 'Ranking general público',
        image: {
            web:generalImage,
            mobile:generalImageMob,
        },
        nameAccessor: nameFromUser,
        infoAccessor: (user)=>`${user.points} PTS`,
        showPlaces: true,
    },
}
