import React, { useContext, useCallback } from 'react'
import GeneralContainer from '../../../components/layout/GeneralContainer/GeneralContainer';
import Menu from '../Menu/Menu';
import TideReactTable from "../../../components/TideReactTable/TideReactTable";
import useTideTable from "../../../hooks/useTideTable";
import {gameTableColumns, gamesListSGroups} from "../../../services/modelUtils/gameUtils";
import { ApiContext } from '../../../services/api/api-config';
import './GamesList.scss';

const requestFilters = {sGroups: gamesListSGroups};

const GamesList = () => {
    const api = useContext(ApiContext);
    const tideTable = useTideTable({entity: 'games', columns: gameTableColumns, requestFilters});

    const handleExportGamesToExcel = useCallback(() => {
        window.location.href = api.games.getExportCsvUrl();
    }, [api]);

    return (
        <GeneralContainer
            className='GeneralContainer--display-block GamesList' 
            backButton={true} 
            hideBackOnMobile={true} 
            menu={false}
        >
            <Menu/>

                <h1 className='center'>Juegos</h1>

            <div className='table-container'>
                <button className='btn btn-export' onClick={handleExportGamesToExcel}>Exportar a Excel</button>

                <TideReactTable {...tideTable.tableProps} />
            </div>
        </GeneralContainer>
    );
}

export default GamesList