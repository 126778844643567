import React from 'react';
import './TicketSelectionModal.scss';
import {createPortal} from "react-dom";
import loadIcon from '../../assets/icono_cargar.png';
import ticketIcon from '../../assets/icono_ticket.png';
import OreoButton from "../../../../../components/layout/OreoButton/OreoButton";
import {ticketStores} from "../../../../../services/modelUtils/ticketUtils";
import useBoolean from "../../../../../hooks/useBoolean";
import RappiAdvice from "../RappiAdvice/RappiAdvice";
import classNames from "classnames";
import { useContext } from 'react';
import { ApiContext } from '../../../../../services/api/api-config';
import { getNotifier } from '../../../../../services/notifier';

const TicketSelectionModal = ({ onClose, onSave }) => {
    const api = useContext(ApiContext);
    const [file, setFile] = React.useState(null);
    const [secondFile, setSecondFile] = React.useState();
    const [store, setStore] = React.useState('');
    const [showingExample,,,toggleExample] = useBoolean();
    const [ticketNumber, setTicketNumber] = React.useState('');
    const [showingRappi, startShowingRappi, stopShowingRappi] = useBoolean();

    const maxNameSize = 20;

    let fileText = file?.name || 'SUBIR FOTO';
    if(fileText.length > maxNameSize){
        fileText = fileText.substring(0,maxNameSize) + '...';
    }

    let secondFileText = secondFile?.name || 'SUBIR SEGUNDA FOTO';
    if(secondFileText.length > maxNameSize){
        secondFileText = secondFileText.substring(0,maxNameSize) + '...';
    }

    const ticketImage = !store? null : ticketStores.find(ts=>ts.name === store).image;

    const selectedStore = ticketStores.find(ts=>ts.name === store);
    const needsSecondFile = selectedStore && selectedStore.showTwoFiles;
    const canSave = !!(file && store && ticketNumber && (!needsSecondFile || (needsSecondFile && secondFile)));

    const handleStoreChange = (e) => {
        const newStore = e.target.value;
        setStore(newStore);
        if( newStore === 'Rappi' ){
            startShowingRappi();
        }
    }

    const handleSave = async () =>{
        // Validate if this ticket numer exists
        try {
            const existingTicket = await api.tickets.validate({ params:{ticketNumber: ticketNumber}, method:'POST' });

            if( existingTicket?.valid !== true ){
                return getNotifier().error('El número de ticket ya está registrado.');
            }
        } catch (error) {
            return getNotifier().error(error.detail);
        }
        
        onClose();
        onSave({ file, secondFile, store, ticketNumber });
    }

    return createPortal(
        <div className={classNames("TicketSelectionModal", showingRappi&&'showing-advice')}>
            <div className='overlay' onClick={onClose} />
            <div className={"TicketSelectionModal__content"}>
                {showingRappi? <RappiAdvice onClose={stopShowingRappi} /> : <>
                <div className={"TicketSelectionModal__content__header"}>
                    <h1 className='title'>Sube tu ticket</h1>
                </div>
                <div className='form'>
                    <select className='input select' value={store||''} onChange={handleStoreChange}>
                        <option value='' disabled>SELECCIONA UNA TIENDA</option>
                        {ticketStores.map(store=>(
                            <option key={store.name} value={store.name}>{store.name}</option>
                        ))}
                    </select>

                    {ticketImage &&
                        <div>
                            <button className='ticket-btn' onClick={toggleExample}>
                                <img src={ticketIcon} className='ticket-icon' alt={'ticket'}/>
                                EJEMPLO DE TICKET
                            </button>
                            {showingExample &&<div className='ticket-container'>
                                <img className='ticket-example' alt={'ticket'} src={ticketImage}/>
                            </div>}
                        </div>
                    }

                    <button className='input file-btn'>
                        <span>{fileText}</span>
                        <img src={loadIcon} className='load-icon' alt={'Subir'} />
                        <input
                            key={Math.random()}
                            type='file'
                            accept="image/png, image/jpeg"
                            onChange={e=>setFile(e.target.files?.[0])}
                            multiple={false}
                            className='file-in'
                        />
                    </button>

                    {selectedStore && selectedStore.showTwoFiles &&
                        <button className='input file-btn file-btn--mt'>
                            <span>{secondFileText}</span>
                            <img src={loadIcon} className='load-icon' alt={'Subir'} />
                            <input
                                key={Math.random()}
                                type='file'
                                accept="image/png, image/jpeg"
                                onChange={e=>setSecondFile(e.target.files?.[0])}
                                multiple={false}
                                className='file-in'
                            />
                        </button>}

                    <input
                        className='input text-input'
                        type='text'
                        placeholder='Número de ticket o folio'
                        value={ticketNumber}
                        onChange={e=>setTicketNumber(e.target.value)}
                    />
                </div>

                <div className='ticket-disclamer'>
                    Recuerda que el ticket debe ser legible, el numero de ticket o folio, producto y SKU deberá estar visible
                </div>

                <OreoButton className='action-button' onClick={handleSave} disabled={!canSave}>
                    GUARDAR
                </OreoButton>

                <div className='close-button' onClick={onClose}></div>
                </>}
            </div>
        </div>
    , document.body);
};

export default TicketSelectionModal;
