import React, {useContext, useEffect} from 'react';
import './RankingList.scss';
import {useParams} from "react-router-dom";
import GeneralContainer from "../../../../components/layout/GeneralContainer/GeneralContainer";
import {paths} from "../../../../services/routes/appRoutes";
import leftCorner from "../../../../assets/images/corners/pleca_izquierda_ticket_web.png";
import rightCorner from "../../../../assets/images/corners/pleca_derecha_ranking_web.png";
import footerMobile from "../../../../assets/images/corners/pleca_premios_mobile.png";
import { rankingDefinition } from "../../../../services/rankingUtils";
import oreo from '../../../../assets/images/galleta_oreo_mini.png';
import {isMobile} from "../../../../services/layoutUtils";
import {ApiContext} from "../../../../services/api/api-config";
import {useSelector} from "react-redux";

const RankingList = () => {

    const api = useContext(ApiContext);
    const rankingType = useParams().type;
    const rankingParams = rankingDefinition[rankingType];
    const device = isMobile() ? 'mobile':'web';

    const loadingId = 'RankingList.'+rankingType;
    const customProp = 'ranking_'+rankingType;

    useEffect(()=>{

        const endpoint = rankingParams.endpoint || 'rankings';
        const method = rankingParams.method || rankingType;
        const params = rankingParams.requestParams || {};

        api[endpoint][method]({ customProp, params, loadingId });
    },[api, rankingType, rankingParams, customProp, loadingId]);

    const loading = useSelector(s=>!!s.loadingIds[loadingId]);
    const items = useSelector(s=>s.api[customProp])||[];


    return (
        <GeneralContainer className={"RankingList"} backButtonRoute={paths.ranking} unicorn footer>

            <div className='ranking-container'>
                <div className='side'>
                    <div className='cover-container'>
                        <img className={'ranking-cover'}
                             alt={rankingParams?.name}
                             src={rankingParams?.image[device]} />
                    </div>
                </div>
                <div className='side left'>
                    <div className='ranking-list'>
                        {!loading && items.length === 0 && <div className='empty-state'>
                            Aún no hay información disponible,<br/>
                            regresa pronto para conocer la lista.
                        </div>}
                        {items.map(( user, index) =>
                            <div className='ranking-row' key={user.id}>
                                <div className='td first-cell'>
                                    <img className='mini-oreo' alt='Oreo' src={oreo} />
                                    <span>{rankingParams?.showPlaces? index+1+'°':''} {rankingParams?.nameAccessor(user)}</span>
                                </div>
                                <div className='td second-cell'>
                                    {rankingParams?.infoAccessor(user)}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className='footer'>
                <img src={leftCorner} className={'left corner web'} alt={'corner'} />
                <img src={rightCorner} className={'right corner web'} alt={'corner'}/>
                <img src={footerMobile} className={'corner mobile'} alt={'corner'}/>
            </div>
        </GeneralContainer>
    );
};

export default RankingList;
