import {gamesDefinition, badIngredients} from './gamesDefinition';
import {isMobile} from "../../../../services/layoutUtils";
import {v4 as uuid} from "uuid";

let _GameConfig = {
    gameTime: 30000,
    pointsPerItem: 3,
    pointsPerBadItem: -1,
    goodIngredientsCount: 25,
    badIngredientsToColumnRatio: 0.19,
    ingredientTimeFrameDeviation: 0.1,
    bowlSizeRatio: 1,//Percentage of the column width
    itemSize: 1.4,//Percentage of the column width

    columnSize: 140,
    maxColumnDeviation: 0.5,//Percentage of column size
    itemSpeed: 0.2,//Pixels per millisecond, when user has zero points
    maxItemSpeed: 0.8,//Pixels per millisecond, when user has {pointsForMaxSpeed} points
    pointsForMaxSpeed: 12000,//Points at which the user gets the max speed

    //Collision boxes are defined as percentages of the item size
    bowlCollisionBox:{
        x: 0.15, y: 0.7,
        width: 0.7, height: 0.2
    },
    itemCollisionBox:{
        x: 0.1, y: 0.1,
        width: 0.8, height: 0.8
    },
    showCollisionBoxes: 0
}

if(isMobile()) {
    _GameConfig.columnSize = 60;
    _GameConfig.bowlSizeRatio = 1.1;
    _GameConfig.itemSize = 0.8;
}

// if(window.localStorage.debugGameConfig)
//     _GameConfig =  {..._GameConfig, ...JSON.parse(window.localStorage.debugGameConfig)};

export const GameConfig=_GameConfig;

export const getGameItemSpeed = (gameConfig, userPoints)=>{
    if( userPoints > gameConfig.pointsForMaxSpeed ){
        return gameConfig.maxItemSpeed;
    }
    const range = gameConfig.maxItemSpeed - gameConfig.itemSpeed;
    const pointsForMaxSpeed = gameConfig.pointsForMaxSpeed;
    const speed = gameConfig.itemSpeed + (range * (userPoints / pointsForMaxSpeed));
    return speed;
}

export const getRandomGame = () => {
    const randomIndex = Math.floor(Math.random() * gamesDefinition.length);
    const game = gamesDefinition[randomIndex];
    game.badIngredients = getRandomBadIngredients(5);
    return game;
}

export const getRandomBadIngredients = ( count ) => {
    if(count > badIngredients.length )
        count = badIngredients.length;

    const ingredientsPool = [...badIngredients];
    const result = [];
    for( let i = 0; i < count; i++ ) {
        result.push( ingredientsPool.splice( Math.floor( Math.random() * ingredientsPool.length ), 1 )[0] );
    }
    return result;
}
export const loadImage = ( path ) => {
    const image = new Image();
    image.src = path;
    return new Promise((resolve, reject) => {
        image.onload = () => {
            resolve(image);
        }
        image.onerror = (err) => {
            reject(err);
        }
    });
}

export const loadImages = ( paths ) => {
    return Promise.all( paths.map( path => loadImage(path) ) );
}

export const generateGame = ({gameDefinition, gameSize} ) => {
    //Initial values
    const {width, height} = gameSize;
    const {ingredients, badIngredients} = gameDefinition;

    // --- Computed values ---

    //Transit time of a single ingredient through the stage
    const transitTime = height / GameConfig.itemSpeed;
    //We divide the stage in columns, in each column an ingredient can appear
    //We get the number of columns based on the constant GameConfig.columnSize and the width of the stage
    const columns = Math.floor(width / GameConfig.columnSize);
    //We get the exact size of each column
    const columnWidth = width / columns;
    //Bowl width is relative to the column width
    const bowlWidth = columnWidth * GameConfig.bowlSizeRatio;
    //We get the number of bad ingredients that are going to appear
    //It is based on the constant GameConfig.badIngredientsToColumnRatio, the number of columns and the number of good ingredients
    //So for every good ingredient, we get `columns * GameConfig.badIngredientsToColumnRatio` bad ingredients
    //This means that bigger screens will have more bad ingredients to fill out the stage
    const badIngredientsBetweenGood = Math.floor(columns * GameConfig.badIngredientsToColumnRatio) || 1;
    //The goodIngredientTimeFrame is the time between the appearance of two good ingredients
    //Each specific ingredient will appear at goodIngredientTimeFrame +- GameConfig.ingredientTimeFrameDeviation/2
    const goodIngredientTimeFrame = (GameConfig.gameTime - transitTime) / GameConfig.goodIngredientsCount;
    //This deviation is used to prevent the ingredients to appear at predictable times
    const goodIngredientsMaxTimeDeviation = goodIngredientTimeFrame * GameConfig.ingredientTimeFrameDeviation;
    const maxColumnDeviationPixels = columnWidth * GameConfig.maxColumnDeviation;

    //Generate good ingredients time and position
    const goodIngredientsAppearance = [];
    for( let i = 0; i < GameConfig.goodIngredientsCount; i++ ) {
        //The start of the interval in which the ingredient should appear
        const baseTime = goodIngredientTimeFrame * i;
        //The column in which the ingredient should appear
        const column = Math.floor(Math.random() * columns);
        let xPosition = column * columnWidth + (Math.random() * maxColumnDeviationPixels - (maxColumnDeviationPixels / 2));
        if( xPosition < 0 )
            xPosition = 0;
        const ingredientConfig = {
            good: true,
            //The exact time in which the ingredient should appear
            time: baseTime + (Math.random() * goodIngredientsMaxTimeDeviation - (goodIngredientsMaxTimeDeviation / 2)),
            //The exact position in which the ingredient should appear
            x: xPosition,
            //The ingredient type
            ingredient: ingredients[Math.floor(Math.random() * ingredients.length)],
            //We save the column to use in the badIngredients array algorithm
            column,
            uuid: uuid()
        }
        goodIngredientsAppearance.push(ingredientConfig);
    }

    //Generate bad ingredients time and position
    const badIngredientsAppearance = [];
    for( let i = 0; i < goodIngredientsAppearance.length; i++ ) {
        const leftIngredient = goodIngredientsAppearance[i];
        const rightIngredient = goodIngredientsAppearance[i + 1];
        const baseTime = leftIngredient.time;
        const wholeTimeFrame = rightIngredient ? rightIngredient.time - leftIngredient.time : goodIngredientTimeFrame;
        const badIngredientTimeFrame = wholeTimeFrame / badIngredientsBetweenGood;
        const badIngredientMaxTimeDeviation = badIngredientTimeFrame * GameConfig.ingredientTimeFrameDeviation;

        //To prevent the collision of bad and good ingredients
        const availableColumns = [];
        for( let j = 0; j < columns; j++ ){
            if( j !== leftIngredient.column && j !== rightIngredient?.column )
                availableColumns.push(j);
        }

        for( let j = 0; j < badIngredientsBetweenGood; j++ ) {

            const column = availableColumns[Math.floor(Math.random() * availableColumns.length)];
            const ingredientConfig = {
                good: false,
                //The exact time in which the ingredient should appear
                time: baseTime// At this time the last good ingredient appeared
                    + (badIngredientTimeFrame * j)// The time between the last good ingredient and the current bad ingredient
                    - (badIngredientTimeFrame/2)//Move the ingrediente to the center of its time frame
                    + (Math.random() * badIngredientMaxTimeDeviation - (badIngredientMaxTimeDeviation / 2)),//deviate
                //The exact position in which the ingredient should appear
                x: column * columnWidth
                    + (Math.random() * maxColumnDeviationPixels - (maxColumnDeviationPixels / 2)),//deviate
                //The ingredient type
                ingredient: badIngredients[Math.floor(Math.random() * badIngredients.length)],
                column,
                uuid: uuid()
            };
            if(ingredientConfig.x < 0 )
                ingredientConfig.x = 0;
            badIngredientsAppearance.push(ingredientConfig);
        }
    }

    const allIngredients = [...goodIngredientsAppearance, ...badIngredientsAppearance];
    allIngredients.sort((a, b) => a.time - b.time);
    allIngredients.forEach((ingredient, index) => {
        ingredient.index = index;
    });

    return {
        ingredients: allIngredients,
        columnWidth,
        bowlWidth,
    }

}

/**
 * Test if two rectangles collide
 * @param rect1
 * @param rect2
 * @returns {boolean}
 */
export const rectCollision = ( rect1, rect2 ) => {
    return rect1.x < rect2.x + rect2.width &&
        rect1.x + rect1.width > rect2.x &&
        rect1.y < rect2.y + rect2.height &&
        rect1.y + rect1.height > rect2.y;
}

/**
 * Returns a score from 0 to 3, used to display this number of oreos in the game over screen
 * @param points
 * @returns {number}
 */
export const pointsToOreos = ( points ) => {
    const maxPoints = GameConfig.goodIngredientsCount * GameConfig.pointsPerItem;
    const pointPerOreo = maxPoints / 3;
    return Math.ceil(points / pointPerOreo);
}
