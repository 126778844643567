import React, {useContext, useEffect} from 'react';
import './TicketUpload.scss';
import GeneralContainer from "../../../components/layout/GeneralContainer/GeneralContainer";
import productsOffImg from "./assets/Boton_gris_productos_web.png";
import productsImg from "./assets/Boton_azul_productos_web.png";
import ticketImg from "./assets/boton_web_sube_ticket.png";
import arrow from "./assets/Flecha.png";
import {paths} from "../../../services/routes/appRoutes";
import leftCorner from "../../../assets/images/corners/pleca_izquierda_ticket_web.png";
import rightCorner from "../../../assets/images/corners/pleca_derecha_ticket_web.png";
import footerMobile from "../../../assets/images/corners/pleca_inferior_ticket_mobile.png";
import useBoolean from "../../../hooks/useBoolean";
import ProductSelectionModal from "./components/ProductSelectionModal/ProductSelectionModal";
import OreoButton from "../../../components/layout/OreoButton/OreoButton";
import Compress from 'compress.js';
import {ApiContext} from "../../../services/api/api-config";
import {useNavigate} from "react-router-dom";
import TicketSelectionModal from "./components/TicketSelectionModal/TicketSelectionModal";
import {useSelector} from "react-redux";
import { getNotifier } from '../../../services/notifier';

const TicketUpload = () => {

    const api = useContext(ApiContext);
    const navigate = useNavigate();
    const [ticketInfo, setTicketInfo] = React.useState(null);
    const [compressedImage, setCompressedImage] = React.useState(null);
    const [compressedSecondImage, setCompressedSecondImage] = React.useState();
    const [selectingTicket,openSelectingTicket,closeSelectingTicket] = useBoolean();
    const [selectingProducts,openSelectingProducts,closeSelectingProducts] = useBoolean();
    const [showingProducts, openShowingProducts, closeShowingProducts] = useBoolean();
    const [selectedProducts, setSelectedProducts] = React.useState([]);

    const closeProducts = () => {
        closeSelectingProducts();
        closeShowingProducts();
    }

    const canContinue = !!(compressedImage && selectedProducts?.length);

    const handleProductSelection = (selectedProducts)=>{
        setSelectedProducts(selectedProducts);
        closeSelectingProducts();
    }

    const totalLives = selectedProducts.reduce((acc,sp)=>acc+(sp.product.numLives * sp.amount),0);

    useEffect(()=>{
        setCompressedImage(null);
        if(!ticketInfo) return;
        const {file, secondFile} = ticketInfo;
        const compress = new Compress();

        compress.compress([file], {quality: 0.75 }).then((data) => {
            const file = Compress.convertBase64ToFile(data[0].data, 'image/jpg');
            setCompressedImage(file);
        });

        if(secondFile)
            compress.compress([secondFile], {quality: 0.75 }).then((data) => {
                const secondFile = Compress.convertBase64ToFile(data[0].data, 'image/jpg');
                setCompressedSecondImage(secondFile);
            });        
    },[ticketInfo]);

    const loadingId = 'TicketUpload.uploading';
    const uploadTicket = ()=>{
        const ticketProducts = selectedProducts.map(sp=>({
            product: sp.product.id,
            amount: sp.amount
        }));
        const {store, ticketNumber} = ticketInfo;

        const params = {ticketProducts, store, ticketNumber};
        let files = {appFile: compressedImage};

        if(compressedSecondImage)
            files = {appFile: compressedImage, secondTicket: compressedSecondImage};

        api.tickets.create({params, files, loadingId})
            .then(ticket => getNotifier().success(`Con tus productos comprados tienes ${ticket.numOfLives} de intentos para jugar`))
            .then(()=>api.me.get())
            .then(()=>navigate(paths.userHome))
            .catch(error => getNotifier().error(error.detail));
    }

    const loading = useSelector(state=>state.loadingIds[loadingId]);

    return (
        <GeneralContainer className={"TicketUpload"} unicorn backButtonRoute={paths.userHome} footer>
            <div className='upload-container'>
                <h1 className='title'>Sube tu ticket</h1>
                <p className='subtitle'>Recuerda que debes entregar tus<br/>
                    tickets para validar tu premio</p>
                <div className='ticket-path'>
                    <button className='action-button ticket' onClick={openSelectingTicket}>
                        <img src={ticketImg} alt={'ticket'} className='action-icon' />
                    </button>
                    <div className='arrow-container'>
                        <img src={arrow} alt={'arrow'} className='arrow' />
                    </div>
                    <button className='action-button' onClick={openSelectingProducts} disabled={!ticketInfo}>
                        <img src={ticketInfo? productsImg : productsOffImg} alt={'ticket'} className='action-icon' />
                    </button>
                </div>
                <div className='selected'>
                    <span>Con tus productos obtienes {totalLives} intentos</span>
                </div>
                <p className='format'>FORMATO VÁLIDO JPG Y PNG, PESO MÁXIMO 5MB,<br/>
                    ES IMPORTANTE QUE SE APRECIE EL TICKET COMPLETO Y<br/>
                    LA FOTO SEA LEGIBLE PARA CONSIDERARSE VÁLIDO</p>
                <OreoButton className='validate' disabled={!canContinue || loading} onClick={uploadTicket}>
                    <span className='btn-text'>VALIDAR</span>
                </OreoButton>

                <div className='products-btn-container'>
                    <button className='products-btn' onClick={openShowingProducts}>
                        PRODUCTOS PARTICIPANTES
                    </button>
                </div>

            </div>
            <div className='footer'>
                <img src={leftCorner} className={'left corner web'} alt={'corner'} />
                <img src={rightCorner} className={'right corner web'} alt={'corner'}/>
                <img src={footerMobile} className={'corner mobile'} alt={'corner'}/>
            </div>
            { (selectingProducts || showingProducts) &&
                <ProductSelectionModal
                    interactive={!showingProducts}
                    onClose={closeProducts}
                    onSelect={handleProductSelection}
                /> }

            {selectingTicket && <TicketSelectionModal onClose={closeSelectingTicket} onSave={setTicketInfo} />}
        </GeneralContainer>
    );
};

export default TicketUpload;
