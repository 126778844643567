import Logout from "../../scenes/user/Logout/Logout";
import Game from "../../scenes/user/Game/Game";
import UserHome from "../../scenes/user/UserHome/UserHome";
import avatarSelection from "../../scenes/user/AvatarSelection/AvatarSelection";
import TicketUpload from "../../scenes/user/TicketUpload/TicketUpload";
import TicketHistory from "../../scenes/user/TicketHistory/TicketHistory";
import ShareScreen from "../../scenes/user/ShareScreen/ShareScreen";
import RankingMenu from "../../scenes/user/Ranking/RankingMenu";
import RankingList from "../../scenes/user/Ranking/RankingList/RankingList";
import Contact from "../../scenes/public/Contact/Contact";
import PrivacyPolicy from "../../scenes/public/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "../../scenes/public/TermsAndConditions/TermsAndConditions";
import TermsOfUse from "../../scenes/public/TermsOfUse/TermsOfUse";
import Prizes from "../../scenes/public/Prizes/Prizes";
import Recipes from "../../scenes/public/Recipes/Recipes";
import Dynamic from "../../scenes/user/Dynamic/Dynamic";
import Winners from "../../scenes/public/Winners/Winners";

export const paths={
    userHome: '/home',
    avatarSelection: '/avatar',
    game: '/game',
    ticketUpload: '/ticket',
    ticketHistory: '/history',
    share: '/share',
    ranking: '/ranking',
    rankingList: '/ranking/:type',
    logout: '/logout',
    contact:'/contact',
    privacy:'/privacy',
    terms:'/terms',
    prizes:'/prizes',
    recipes:'/recipes',
    dynamic: '/dynamic',
    termsAndConditions: '/terms-and-conditions',
    winners: '/winners',
};

const getAppRoutes =( securityManager )=>{

    let routes = [];

    //If there's no avatar, redirect to the selection page at startup
    if( !securityManager.me.avatar ) {
        routes.push({path: paths.avatarSelection, Component: avatarSelection});
        routes.push({path: paths.userHome, Component: UserHome});
    }
    else{
        routes.push({path: paths.userHome, Component: UserHome});
        routes.push({path: paths.avatarSelection, Component: avatarSelection});
    }
    routes.push({path:paths.game, Component: Game});
    routes.push({path:paths.ticketUpload, Component: TicketUpload});
    routes.push({path:paths.ticketHistory, Component: TicketHistory});
    routes.push({path:paths.share, Component: ShareScreen});
    routes.push({path:paths.ranking, Component: RankingMenu});
    routes.push({path:paths.rankingList, Component: RankingList});
    routes.push({path:paths.contact, Component: Contact});
    routes.push({path:paths.logout, Component: Logout});
    routes.push({path:paths.privacy, Component: PrivacyPolicy});
    routes.push({path:paths.terms, Component: TermsOfUse});
    routes.push({path:paths.prizes, Component: Prizes});
    routes.push({path:paths.recipes, Component: Recipes});
    routes.push({path:paths.dynamic, Component: Dynamic});
    routes.push({path:paths.termsAndConditions, Component: TermsAndConditions});
    routes.push({path:paths.winners, Component: Winners});

    return routes;
};


export default getAppRoutes;
