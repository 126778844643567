import React, {useCallback} from 'react';
import './TextArea.scss';
import classNames from "classnames";
import _ from 'lodash';


const TextArea = ( {value, onChange, placeholder, type, className, disabled, inputProps={}, ...props }) => {

        const handleChange = useCallback((e)=>{
            onChange?.(e.target.value);
        },[onChange]);

        return (
            <div className={classNames( "TextArea", value&&"with-content", className)} {...props}>
                <textarea
                    className={classNames('input-el', inputProps.className)}
                    value={typeof value === 'undefined' || _.isNull(value) ? '':value}
                    onChange={handleChange}
                    disabled={disabled}
                    placeholder={placeholder}
                    {...inputProps}
                />
            </div>

     );
}

export default TextArea;
