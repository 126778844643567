import React, {useEffect, useState} from 'react';
import './Countdown.scss';
import logo from '../../../assets/images/logo_factor_oreo.png';
import {getTimeLeft} from "../../../services/countdownUtils";


const Countdown = () => {

    const [timeLeft, setTimeLeft] = useState( getTimeLeft() );
    useEffect(()=>{
        const interval = setInterval(()=>{
            setTimeLeft( getTimeLeft() );
        }, 1000);
        return ()=>clearInterval(interval);
    },[]);

    return (
        <div className={"Countdown"}>
            <div className='header-img' />

            <div className='content'>
                <p className='subtitle'>FALTAN:</p>
                <div className='count-container'>
                    <div className='count-item'>
                        <div>
                            <span className='count-number'>{Math.floor(timeLeft.days/10)}</span>
                            <span className='count-number'>{timeLeft.days%10}</span>
                        </div>
                        <p className='time-name'>DÍAS</p>
                    </div>
                    <div className='count-item'>
                        <div>
                            <span className='count-number'>{Math.floor(timeLeft.hours/10)}</span>
                            <span className='count-number'>{timeLeft.hours%10}</span>
                        </div>
                        <p className='time-name'>HORAS</p>
                    </div>
                    <div className='count-item'>
                        <div>
                            <span className='count-number'>{Math.floor(timeLeft.minutes/10)}</span>
                            <span className='count-number'>{timeLeft.minutes%10}</span>
                        </div>
                        <p className='time-name'>MINUTOS</p>
                    </div>
                    <div className='count-item'>
                        <div>
                            <span className='count-number'>{Math.floor(timeLeft.seconds/10)}</span>
                            <span className='count-number'>{timeLeft.seconds%10}</span>
                        </div>
                        <p className='time-name'>SEGUNDOS</p>
                    </div>
                </div>
                <h1 className='title'>
                    <img className='logo' alt='Factor Oreo' src={logo} />
                </h1>
                <p className='description'>
                    Guarda tus tickets de compra desde el 1° de agosto para participar en SUMA MÁS CON FACTOR OREO A PARTIR DEL 19 DE AGOSTO
                </p>

            </div>

        </div>
    );
};

export default Countdown;
