import {loadImages, GameConfig, generateGame, rectCollision, getGameItemSpeed} from "./gameUtils";
import {bowlParts, catchAnimation, soundEffects} from "./gamesDefinition";
import Sona from "sona";
import _ from "lodash";

class GameControl {

    constructor({
                    canvas,
                    gameDefinition,
                    onGameReady,
                    onTimeChange,
                    onGameOver,
                    onPointsChange,
                    soundEnabled=true,
                    userPoints=0,
    }) {
        //Set properties
        this.canvas = canvas;
        this.gameDefinition = gameDefinition;
        this.onTimeChange = onTimeChange;
        this.onPointsChange = onPointsChange;
        this.onGameOver = onGameOver;
        this.soundEnabled = soundEnabled;
        this.userPoints = userPoints;

        this.assets = {};
        this.bowlPosition = {x:0, y:0};
        this.running = false;
        this.soundReady = false;
        this.gameTime = GameConfig.gameTime;
        this.itemSpeed = getGameItemSpeed(GameConfig, userPoints)||GameConfig.itemSpeed;
        //Save what happened in the game
        this.itemAuditLog = [];
        this.correctItems = 0;
        this.incorrectItems = 0;
        //External state
        this.points = 0;
        this.secondsRemaining = Math.floor(this.gameTime/1000);

        //Bind methods
        this.draw = this.draw.bind(this);
        this.movementListener = this.movementListener.bind(this);
        this.run = this.run.bind(this);

        //Initialize game
        this.initializeCanvas();
        this.generatedGame = generateGame({gameDefinition, gameSize: {width: canvas.width, height: canvas.height}});

        this.pendingItems = [...this.generatedGame.ingredients];
        this.inStageItems = [];
        this.animations = {};
        this.runningAnimations = [];
        this.loadAssets().then(()=>{
            this.bowlPosition = this.getInitialBowlPosition();
            onGameReady?.();
        });

    }

    initializeCanvas() {
        const canvasRect = this.canvas.getBoundingClientRect();
        this.canvas.width = canvasRect.width;
        this.canvas.height = canvasRect.height;
        this.ctx = this.canvas.getContext('2d');
        this.canvas.addEventListener('mousemove', this.movementListener);
        this.canvas.addEventListener('touchmove', this.movementListener);
    }

    getInitialBowlPosition() {
        const {width, height} = this.assets.bowlParts[0];
        const calcHeight = this.generatedGame.bowlWidth / width * height;
        return {
            x: this.canvas.width / 2 - width / 2,
            y: this.canvas.height - calcHeight,
            width: this.generatedGame.bowlWidth,
            height: calcHeight
        }
    }

    async loadAssets() {

        this.assets.okSound = new Audio(soundEffects.ok);
        this.assets.errorSound = new Audio(soundEffects.error);

        this.assets.bowlParts = await loadImages(bowlParts);
        const ingredients = await loadImages(this.gameDefinition.ingredients.map(ingredient => ingredient.image));
        this.assets.ingredients = {};
        this.gameDefinition.ingredients.forEach((ingredient, index) => {
            this.assets.ingredients[ingredient.name] = ingredients[index];
        });
        const badIngredients = await loadImages(this.gameDefinition.badIngredients.map(ingredient => ingredient.image));
        this.gameDefinition.badIngredients.forEach((ingredient, index) => {
            this.assets.ingredients[ingredient.name] = badIngredients[index];
        });

        const catchAnimationImages = await loadImages(catchAnimation.frames);
        this.animations.catchAnimation = {...catchAnimation, images: catchAnimationImages};

        const soundFiles = _.map(soundEffects, (url, id) =>({ url, id}));
        this.sounds = new Sona(soundFiles);
        return new Promise(resolve => {
            this.sounds.load(()=>{
                this.soundReady = true;
                resolve();
            });
        });
    }

    playAudio(audioId, loop=false) {
        if(!this.soundEnabled || !this.soundReady)
            return;
        this.sounds.play(audioId, loop);
    }
    stopAudio(audioId) {
        if(!this.soundReady)
            return;
        this.sounds.stop(audioId);
    }

    movementListener(e) {
        let newX = 0;
        if( e.type === 'touchmove'){
            const touchX = e.touches[0].clientX;
            newX = touchX - this.canvas.getBoundingClientRect().left;
        }
        else {
            newX = e.offsetX;
        }
        newX -= this.bowlPosition.width / 2
        if(newX < 0)
            this.bowlPosition.x = 0;
        else if(newX > this.canvas.width - this.bowlPosition.width)
            this.bowlPosition.x = this.canvas.width - this.bowlPosition.width;
        else
            this.bowlPosition.x = newX;
    }

    //in-draw method
    //Advance game time, fire time change event and check if game is over
    gameTimeControl( drawUtils ){
        const secondsRemaining = Math.floor((this.gameTime - drawUtils.elapsedTime) / 1000);

        //Update time remaining
        if(secondsRemaining !== this.secondsRemaining) {
            this.secondsRemaining = secondsRemaining;
            this.onTimeChange?.(secondsRemaining);
        }
        //Check if game time is over
        if(secondsRemaining <= 0) {
            this.stop();
            this.onGameOver?.( this.getGameResult() );
        }
    }

    addItemToStage(item) {

        const asset = this.assets.ingredients[item.ingredient.name];
        const inStageItem = {
            ...item,
            asset,
            y: -asset.height,
        };
        //Prevent going over the right edge
        const maxX = this.canvas.width - (asset.width * GameConfig.itemSize);
        if(inStageItem.x > maxX){
            inStageItem.x = maxX;
        }
        this.inStageItems.push(inStageItem);
        const index = this.pendingItems.indexOf(item);
        if(index !== -1)
            this.pendingItems.splice(index, 1);
    }

    removeItemFromStage(item) {
        const index = this.inStageItems.indexOf(item);
        if(index !== -1)
            this.inStageItems.splice(index, 1);
    }

    drawIngredients({drawUtils} ){
        //Check if it's time to add new items to stage
        const added = 0;
        for( let i=0; i<this.pendingItems.length; i++ ) {
            if( drawUtils.elapsedTime > this.pendingItems[i].time )
                this.addItemToStage(this.pendingItems[i]);
            else
                break;
        }
        if(added)
            this.pendingItems.splice(0, added);
        const renderItems = [...this.inStageItems];
        renderItems.forEach(item => {
            this.ctx.drawImage(item.asset, item.x, item.y, item.asset.width * GameConfig.itemSize, item.asset.height * GameConfig.itemSize);
            item.y += drawUtils.deltaTime * this.itemSpeed;
            if(item.y > drawUtils.height+item.asset.height) {
                this.removeItemFromStage(item);
                this.saveItemAuditLog({item, action: 'miss', drawUtils});
            }
        });
    }

    addAnimationToStage({animation, x, y, drawUtils}) {
        this.runningAnimations.push({
            ...animation,
            x,
            y,
            initialTime: drawUtils.elapsedTime,
        });
    }

    drawAnimations({drawUtils}) {
        const renderAnimations = [...this.runningAnimations];
        renderAnimations.forEach(animation => {
            const x = animation.x - animation.width / 2;
            const y = animation.y - animation.height / 2;
            const frameDuration = 1000 / animation.fps;
            const frame = Math.floor((drawUtils.elapsedTime - animation.initialTime) / frameDuration);
            if(frame < animation.frames.length) {
                this.ctx.drawImage(animation.images[frame], x, y, animation.width, animation.height);
            }
            else {
                this.runningAnimations.splice(this.runningAnimations.indexOf(animation), 1);
            }
        } );
    }

    drawCollisionBoxes() {
        let {x: xBox ,y: yBox, width: widthBox, height: heightBox} = GameConfig.itemCollisionBox;
        this.ctx.strokeStyle = 'red';
        this.ctx.lineWidth = 4;
        this.inStageItems.forEach(item => {
            const {width: itemWidth, height: itemHeight} = item.asset;
            this.ctx.strokeRect(
                item.x+ xBox*itemWidth,
                item.y + yBox*itemHeight,
                itemWidth*widthBox*GameConfig.itemSize,
                itemHeight*heightBox*GameConfig.itemSize
            );
        });
        const {x: xBowlBox ,y: yBowlBox, width: widthBowlBox, height: heightBowlBox} = GameConfig.bowlCollisionBox;
        const {width: bowlWidth, height: bowlHeight} = this.bowlPosition;
        this.ctx.strokeStyle = 'green';
        this.ctx.strokeRect(
            this.bowlPosition.x+ xBowlBox*bowlWidth,
            this.bowlPosition.y + yBowlBox*bowlHeight,
            bowlWidth*widthBowlBox,
            bowlHeight*heightBowlBox
        );
    }

    testCollisions({drawUtils}) {
        const {x: xBowlBox ,y: yBowlBox, width: widthBowlBox, height: heightBowlBox} = GameConfig.bowlCollisionBox;
        const {width: bowlWidth, height: bowlHeight} = this.bowlPosition;
        const bowlRect = {
            x: this.bowlPosition.x+ xBowlBox*bowlWidth,
            y: this.bowlPosition.y + yBowlBox*bowlHeight,
            width: bowlWidth*widthBowlBox,
            height: bowlHeight*heightBowlBox
        };
        const inStageItems = [...this.inStageItems];
        inStageItems.forEach(item => {
            const {width: itemWidth, height: itemHeight} = item.asset;
            const itemRect = {
                x: item.x,
                y: item.y,
                width: itemWidth*GameConfig.itemSize,
                height: itemHeight*GameConfig.itemSize
            };
            if(rectCollision(bowlRect, itemRect)) {
                this.removeItemFromStage(item);

                if(item.good) {
                    this.correctItems++;
                    this.points += GameConfig.pointsPerItem;
                    this.playAudio('ok');
                    this.addAnimationToStage({
                        animation: this.animations.catchAnimation,
                        x: this.bowlPosition.x + bowlWidth/2,
                        y: this.bowlPosition.y,
                        drawUtils: drawUtils,
                    });
                }
                else {
                    this.incorrectItems++;
                    this.points+= GameConfig.pointsPerBadItem;
                    this.playAudio('error');
                }
                if(this.points < 0){
                    this.points = 0;
                }
                this.saveItemAuditLog({item, action: 'catch', drawUtils});
                this.onPointsChange?.(this.points);
            }
        });
    }

    draw(){

        // -------- Define --------
        const now = new Date().getTime();
        let deltaTime = 0;
        if(this.lastDrawTime)
            deltaTime = now - this.lastDrawTime;
        this.lastDrawTime = new Date().getTime();

        const drawUtils = {
            width: this.canvas.width,
            height: this.canvas.height,
            elapsedTime: now - this.startTime,
            deltaTime,
        }

        this.gameTimeControl(drawUtils);

        // -------- Draw --------
        this.ctx.clearRect(0, 0, drawUtils.width, drawUtils.height);//clear stage

        //Draw bowl back
        this.ctx.drawImage(this.assets.bowlParts[0],
            this.bowlPosition.x,
            this.bowlPosition.y,
            this.bowlPosition.width,
            this.bowlPosition.height);
        //Draw in stage ingredients and add new ones to stage
        this.drawIngredients({drawUtils});
        this.testCollisions({drawUtils});
        //Draw bowl front
        this.ctx.drawImage(this.assets.bowlParts[1],
            this.bowlPosition.x,
            this.bowlPosition.y,
            this.bowlPosition.width,
            this.bowlPosition.height);
        this.drawAnimations({drawUtils});

        if(GameConfig.showCollisionBoxes)
            this.drawCollisionBoxes();
        if(this.running)
            window.requestAnimationFrame(this.draw);
    }

    playMusic() {
        this.playAudio('backgroundMusic', true);
    }

    stopMusic() {
        this.stopAudio('backgroundMusic');
    }

    enableSound() {
        this.soundEnabled = true;
        this.playMusic();
    }

    disableSound() {
        this.soundEnabled = false;
        this.stopMusic();
    }

    run() {
        this.playMusic();
        this.startTime = new Date().getTime();
        this.running = true;
        this.draw();
    }

    stop() {
        this.running = false;
        this.stopMusic();
        this.canvas.removeEventListener('mousemove', this.movementListener);
        this.canvas.removeEventListener('touchmove', this.movementListener);
    }

    saveItemAuditLog({item, action, drawUtils}) {
        this.itemAuditLog.push({
            in: item.time,
            out: drawUtils.elapsedTime,
            action,
            item: item.ingredient.name,
            correct: item.good,
            points: this.points,
            uuid: item.uuid,
            index: item.index,
            userX: this.bowlPosition.x,
            userY: this.bowlPosition.y,
        });
    }

    getGameResult() {
        return {
            points: this.points,
            correctItems: this.correctItems,
            incorrectItems: this.incorrectItems,
            auditLog:JSON.stringify({
                userPoints: this.userPoints,
                startTime: this.startTime,
                endTime: new Date().getTime(),
                itemAuditLog: this.itemAuditLog,
                bowl: this.bowlPosition,
                board: {
                    width: this.canvas.width,
                    height: this.canvas.height,
                },
                agent: window.navigator.userAgent,
                screen: {
                    width: window.screen.width,
                    height: window.screen.height,
                    innerWidth: window.innerWidth,
                    innerHeight: window.innerHeight,
                }
            }),
        };
    }

}

export default GameControl;
