import React, {useCallback, useState} from 'react'
import GeneralContainer from '../../../components/layout/GeneralContainer/GeneralContainer';
import whatsappImg from '../../../assets/images/contact/icono_whatsapp.png';
import bottomRightImage from '../../../assets/images/contact/pleca_derecha_galleta_web.png';
import bottomLeftImage from '../../../assets/images/contact/cohete_contacto_web.png';
import './Contact.scss';
import useFormState from "../../../hooks/useFormState";
import TextInput from "../../../components/forms/TextInput/TextInput";
import TextArea from "../../../components/forms/TextArea/TextArea";
import ReCAPTCHA from "react-google-recaptcha";
import {prepareContactForm} from "../../../services/modelUtils/contactUtils";
import {getNotifier} from "../../../services/notifier";
import {useContext} from "react";
import {ApiContext} from "../../../services/api/api-config";

const googleCaptchaKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_API_KEY;
const Contact = () => {
    const api = useContext(ApiContext);
    const {form, handleSimpleChange, setForm} = useFormState({});
    const [captchaCode, setCaptchaCode] = useState(null);
    const recaptchaRef = React.createRef();

    const submitForm = useCallback((e) => {
        e.preventDefault();
        let contact = {};
        try {
            contact = prepareContactForm(form)
        } catch (e) {
            return getNotifier().error(e.message);
        }

        if(!captchaCode){
            return getNotifier().error('Debes de validar el captcha para continuar');
        }

        contact.captchaCode = captchaCode;

        api.contact.send(contact)
            .then(() => {
                getNotifier().success(('Tu mensaje se ha enviado! Te responderemos a la brevedad.'));
                recaptchaRef.current.reset();
                setForm({})
            })
            .catch(e => {
                return getNotifier().error(e.detail);
            });

    }, [api, captchaCode, form, setForm, recaptchaRef]);

    return (
        <GeneralContainer className='GeneralContainer--display-block Contact' backButton={true} hideBackOnMobile={true} footer>
            <div className='container'>

                <h1>CONTACTO</h1>
                
                <p className='instructions'>
                    Envíanos un whatsapp a <br/>
                    <img src={whatsappImg} alt="whatsapp"/> 
                        <a href="https://wa.me/525510147424">55 1014 7424</a> / <a href="https://wa.me/525513033142">55 1303 3142</a>
                    <br/>
                    <br/>
                    ó
                    <br/>
                    Contesta el formulario:
                </p>
                <form onSubmit={submitForm}>
                    <TextInput
                        placeholder="NOMBRE"
                        label="name"
                        onChange={handleSimpleChange('name')}
                        value={form.name}
                    />
                    <TextInput
                        label="email"
                        placeholder="CORREO ELECTRÓNICO"
                        onChange={handleSimpleChange('email')}
                        value={form.email || ''}
                    />
                    <TextInput
                        label="phone"
                        type="tel"
                        placeholder="NÚMERO DE CELULAR"
                        onChange={handleSimpleChange('phone')}
                        value={form.phone || ''}
                    />
                    <TextArea
                        label="message"
                        type="text"
                        placeholder="MENSAJE"
                        onChange={handleSimpleChange('message')}
                        value={form.message || ''}
                        resize={false}
                    />
                    <div className="captcha-container">
                        <ReCAPTCHA ref={recaptchaRef} sitekey={googleCaptchaKey} className='captcha' onChange={setCaptchaCode}/>
                    </div>

                    <button className='oreo-button register-button'>
                        ENVIAR
                    </button>
                </form>

            </div>
            <img className='bottom-left-image' src={bottomLeftImage} alt=""/>
            <img className='bottom-right-image' src={bottomRightImage} alt=""/>
        </GeneralContainer>
    );
}

export default Contact;
