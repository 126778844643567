import moment from 'moment';
import React from 'react';
import semver from 'semver-lite';
import pckg from '../../pckg';
import qs from 'qs';

export const querySerialize = (obj) => qs.stringify(obj, { arrayFormat: 'brackets' });

const config = {
    host: 'https://factororeo.com',
    commonPath: 'api',
    credentials: 'include',
    saveTokenToLocalStorage: true,
    getDataFromResponse: handleResponse,
    getMetaDataFromResponse: (r) => r.meta,
    strictMode: false,
    handleUnknownMethods: true,
    endpoints: [
        'me',
        {
            name: 'states',
            customMethods: {
                get: function ({customProp, params}) {
                    return this.apiCall('/apa/states', customProp, params, {
                        method: 'GET',
                        useCommonPath: false
                    })
                },
            },
        },
        {
            name: 'rankingsGeneral',
            customMethods: {
                get: function ({customProp, params}) {
                    return this.apiCall('/apa/rankings/general', customProp, params, {
                        method: 'GET',
                        useCommonPath: false
                    })
                },
            },            
        },
        {
            name: 'users',
            customMethods: {
                register: function (user) {
                    return this.apiCall('/apa/register', 'user_register', user, {
                        method: 'POST',
                        useCommonPath: false
                    })
                },
                recoverPassword: function (email) {
                    let params = {email}
                    return this.apiCall('/apa/recover_password_request', 'recover_password_request', params, {
                        method: 'POST',
                        useCommonPath: false
                    })
                },
                resetPassword: function ({customProp = "_", params, ...config}) {
                    return this.apiCall('/apa/reset_password', customProp, params, {
                        method: "POST",
                        useCommonPath: false, ...config
                    })
                },
                getExportExcelUrl: function( filters )
                {
                    const reportConfig = {
                        name: `Usuarios ${moment().format("YYYY-MM-DD HH:mm")}.xlsx`,
                        headers: {
                            'id': 'ID',
                            'createdDate': 'Fecha de Registro',
                            'name': 'Nombre',
                            'patLastName': 'Apellido Paterno',
                            'matLastName': 'Apellido Materno',
                            'birthday': 'Fecha de Nacimiento',
                            'ageRange': 'Rango de edad',
                            'gender': 'Género',
                            'state.name': 'Estado',
                            'city': 'Ciudad',
                            'phone': 'Teléfono',
                            'email': 'Email',
                            'dailyPoints': 'Puntos Diarios',
                            'weeklyPoints': 'Puntos Semanales',
                            'numOfLives': 'Vidas',                            
                        }
                    };

                    const query = querySerialize({...filters, token: this.token , reportConfig: JSON.stringify(reportConfig)});
                    return `${this.host}/api/users.xlsx?${query}`;
                }
            },
        },
        'user',
        {
            name: 'error',
            preventDefaultMethods: true,
            customMethods: {
                send: function (error, stack, user, additionalInfo) {
                    let params = {
                        stack,
                        error: error && error.message,
                        user: user && {id: user.id, username: user.username},
                        ...additionalInfo
                    };
                    return this.apiCall('/apa/frontend_error', "error_sent", params, {
                        method: "POST",
                        queueable: true,
                        useCommonPath: false
                    })
                },
            }
        },
        {
            name: 'contact',
            preventDefaultMethods: true,
            customMethods: {
                send: function (contactData) {
                    return this.apiCall('/apa/contacts', "contact_sent", contactData, {
                        method: "POST",
                        queueable: true,
                        useCommonPath: false
                    })
                }
            }
        },
        {
            name:'appFiles',
            customMethods:{
                getUrl: function({id}){ return `${this.host}/api/file/${id}?token=${this.token}`},
                getDownloadUrl: function({id}){ return `${this.host}/api/file/download/${id}?token=${this.token}`},
            }
        },
        {
            name: 'ticketProducts',
            customMethods: {
                getExportExcelUrl: function( filters )
                {
                    const reportConfig = {
                        name: `ticketProducts ${moment().format("YYYY-MM-DD HH:mm")}.xlsx`,
                        headers: {
                            'id': 'ID',
                            'ticket.createdDate': 'Fecha del ticket',
                            'ticket.user.id': 'Id de usuario',
                            'ticket.id': 'Id de ticket',
                            'ticket.user.email': 'Email',
                            'ticket.ticketNumber': 'Número de ticket',
                            'product.name': 'Producto',
                            'amount': 'Monto',                            
                        }
                    };

                    const query = querySerialize({...filters, token: this.token , reportConfig: JSON.stringify(reportConfig)});
                    return `${this.host}/api/ticket_products.xlsx?${query}`;
                }
            }
        },
        {
            name: 'games',
            customMethods: {
                getExportExcelUrl: function( filters )
                {
                    const reportConfig = {
                        name: `games ${moment().format("YYYY-MM-DD HH:mm")}.xlsx`,
                        headers: {
                            'id': 'ID',
                            'createdDate': 'Fecha de registro',
                            'createdBy.id': 'Id de usuario',
                            'createdBy.email': 'Email',
                            'correctItems': 'Correctos',
                            'incorrectItems': 'Incorrectos',
                            'points': 'Puntos',                            
                        }
                    };

                    const query = querySerialize({...filters, token: this.token , reportConfig: JSON.stringify(reportConfig)});
                    return `${this.host}/api/games.xlsx?${query}`;
                },
                getExportCsvUrl: function( ){
                    return `${this.host}/api/games_csv`;
                }
            },

        }
    ],
    login: {
        createBody: (username, password, captcha) => {
            let credentials = new FormData();
            credentials.append("_username", username);
            credentials.append("_password", password);
            credentials.append("captcha", captcha);
            credentials.append("client_data", window?.navigator?.userAgent || 'no-data');
            return credentials;
        },
    },
    logout: {
        path: '/apa/logout',
        useCommonPath: false
    },
};


function handleResponse(response, headers) {

    try {
        let server = headers.get("X-App-Version");
        let force = !!server && server[server.length - 1] === 'f';
        if (force) server = server.slice(0, server.length - 1);

        if (semver.validate(pckg.version) && semver.validate(pckg.version) && semver.gt(server, pckg.version)) {
            if (!this.newerVersion)
                console.log("Newer version detected " + server);
            if (!this.newerVersion && window.swRegistration) {
                window.swRegistration.update()
                    .then(() => {
                        //This will broadcast the refresh message to all active tabs of the app through the sw
                        if (force)
                            window.navigator.serviceWorker.controller.postMessage('force_refresh');
                    });
            }
            this.newerVersion = pckg.version;
            if (force && !window.swRegistration)
                window.location.reload();
        }
    } catch (e) {
        console.error('Error reading versions: ' + e);
    }

    return response.data;
}

if (process.env.REACT_APP_BUILD === 'dev')
    config.host = localStorage.host || 'https://back.dev.factororeo.com';

export default config;

export const ApiContext = React.createContext(null);
