import React, { useContext, useCallback } from 'react'
import GeneralContainer from '../../../components/layout/GeneralContainer/GeneralContainer';
import Menu from '../Menu/Menu';
import TideReactTable from "../../../components/TideReactTable/TideReactTable";
import useTideTable from "../../../hooks/useTideTable";
import {productTicketsTableColumns, productTicketsListSGroups} from "../../../services/modelUtils/ticketProductUtils";
import './TicketProductsList.scss';
import { ApiContext } from '../../../services/api/api-config';

const requestFilters = {sGroups: productTicketsListSGroups};

const TicketProductsList = () => {
    const api = useContext(ApiContext);
    const tideTable = useTideTable({entity: 'ticketProducts', columns: productTicketsTableColumns, requestFilters});

    const handleExportProductTicketsToExcel = useCallback(() => {
        window.location.href = api.ticketProducts.getExportExcelUrl({pagination: false, sGroups: productTicketsListSGroups});
    }, [api]);

    return (
        <GeneralContainer
            className='GeneralContainer--display-block TicketProductsList' 
            backButton={true} 
            hideBackOnMobile={true} 
            menu={false}
        >
            <Menu/>

                <h1 className='center'>Ticket</h1>

            <div className='table-container'>
                <button className='btn btn-export' onClick={handleExportProductTicketsToExcel}>Exportar a Excel</button>

                <TideReactTable {...tideTable.tableProps} />
            </div>
        </GeneralContainer>
    );
}

export default TicketProductsList