import React, { useState } from 'react';
import './UserHome.scss';
import GeneralContainer from "../../../components/layout/GeneralContainer/GeneralContainer";
import playBtn from '../../../assets/images/buttons/boton_jugar_web.png';
import playBtnOff from '../../../assets/images/buttons/boton_jugar_gris_web.png';
import historyIcon from "./assets/icono_historial_web.png"
import editIcon from "./assets/icono_lapiz_perfil_web.png"
import rankingIcon from "./assets/icono_ranking_web.png"
import ticketIcon from "./assets/icono_sube_ticket_web.png"
import friendsIcon from "./assets/icono_amigos_web.png"
import oreo from "../../../assets/images/galleta_oreo_perfil_web.png";
import smallHeart from './assets/corazon_perfil.png';
import leftCorner from './../../../assets/images/corners/pleca_inferior_izquierda_perfil_web.png';
import rightCorner from './../../../assets/images/corners/pleca_derecha_avatar_web.png';
import footerMobile from '../../../assets/images/corners/pleca_mobile_galleta_malteada.png';
import {Link, useNavigate} from "react-router-dom";
import {paths} from "../../../services/routes/appRoutes";
import {getUserAvatar} from "../../../services/avatarUtils";
import {useSelector} from "react-redux";
import {getUserRankName} from "../../../services/modelUtils/userUtils";
import { isPromotionFinishedDate } from '../../../services/promotionFinishUtils';
import PromotionFinishedModal from '../../../components/PromotionFinishedModal/PromotionFinishedModal';

const UserHome = () => {

    const me = useSelector(s=>s.api.me);
    const avatar = getUserAvatar(me);
    const canPlay = (me.numOfLives > 0 && !isPromotionFinishedDate());
    const navigate = useNavigate();
    const [showPromotionFinishedModal, setShowPromotionFinishedModal] = useState(isPromotionFinishedDate());

    const play = ()=>{
        navigate(paths.game);
    }

    return (
        <>
            <GeneralContainer className={"UserHome"} unicorn footer forceHamburger>
                <div className='home-container'>
                    <div className='home-col user-container'>
                        <img className='avatar-img' alt={'avatar'} src={avatar.big} />
                        <div className='user-info'>
                            <img className='oreo' alt='oreo' src={oreo} />

                            <div className='top-data'>
                                <span className='name'>{me.name} </span>
                                <span className='user-title'>"{getUserRankName(me)}"</span>
                            </div>
                            <div className={'bot-data'}>
                                <span className='points'>{me.points||0} OREO® PUNTOS </span>
                                <span className='lives'>
                                    <img src={smallHeart} alt={'Corazon'} className='small-heart' /> {me.numOfLives||0}
                                </span>
                            </div>

                            <Link to={paths.avatarSelection}>
                                <button className='edit-btn'>
                                    <img className='edit-img' alt={'Pencil'} src={editIcon}/>
                                </button>
                            </Link>
                        </div>
                    </div>

                    <div className='play-container mobile'>
                        <button className='play-button' disabled={!canPlay} onClick={play}>
                            <img src={canPlay? playBtn: playBtnOff} className={'play-img'} alt={'Jugar'} />
                        </button>
                    </div>

                    <div className='home-col routes-container'>

                        <div className='route-col'>
                            <Link to={paths.ticketUpload} className='route-block'>
                                <img className='route-icon' src={ticketIcon} alt={'ticket'} />
                                <span className='route-name'>
                                    Sube<br/> tu ticket
                                </span>
                            </Link>
                            <Link to={paths.share} className='route-block friends-block'>
                                <img className='route-icon' src={friendsIcon} alt={'Amigos'} />
                                <span className='route-name'>
                                    Invita a un amigo/familiar<br/> y gana puntos extras
                                </span>
                            </Link>
                        </div>
                        <div className='route-col'>
                            <Link to={paths.ticketHistory} className='route-block'>
                                <img className='route-icon' src={historyIcon} alt={'Historial'} />
                                <span className='route-name'>
                                    Historial<br/> de tickets
                                </span>
                            </Link>
                            <Link to={paths.ranking} className='route-block'>
                                <img className='route-icon' src={rankingIcon} alt={'Ranking'} />
                                <span className='route-name'>
                                    Ranking
                                </span>
                            </Link>
                        </div>

                    </div>
                </div>
                <div className='play-container web'>
                    <button className='play-button' disabled={!canPlay} onClick={play}>
                        <img src={canPlay? playBtn: playBtnOff} className={'play-img'} alt={'Jugar'} />
                    </button>
                </div>
                <div className='footer'>
                    <img src={leftCorner} className={'left corner web'} alt={'corner'} />
                    <img src={rightCorner} className={'right corner web'} alt={'corner'}/>
                    <img src={footerMobile} className={'corner mobile'} alt={'corner'}/>
                </div>
            </GeneralContainer>

            {showPromotionFinishedModal && <PromotionFinishedModal onClose={() => setShowPromotionFinishedModal(false)}/>}
        </>
    );
};

export default UserHome;
