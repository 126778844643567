import React from 'react';
import './Prizes.scss';
import GeneralContainer from "../../../components/layout/GeneralContainer/GeneralContainer";
import leftCorner from "../../../assets/images/corners/pleca_izquierda_ticket_web.png";
import rightCorner from "../../../assets/images/corners/pleca_derecha_ranking_web.png";
import footerMobile from "../../../assets/images/corners/pleca_premios_mobile.png";
import prizeCinema from './assets/cine_premios_web.png';
import prizePlay from './assets/play_premios_web.png';
import prizeRecipe from './assets/recetario_premios_web.png';
import prizeTrip from './assets/viaje_premios_web.png';
import { paths } from '../../../services/routes/notLoggedRoutes';
import { Helmet } from 'react-helmet';

const Prizes = () => {

    return (
        <GeneralContainer 
            className={"Prizes"} 
            footer
            backButtonRoute={paths.home}
            unicorn
        >
            <Helmet>
                <title>Premios | Factor Oreo</title>
                <meta name="keywords" content="oreo, gana un viaje, consola de videojuegos, boleto de cine, ganadores diarios, ganadores semanales, galletas oreo, asistente virtual" />
                <meta name="description" content="Suma más con Factor Oreo y comparte con tu familia." />
            </Helmet>

            <div className='prizes-container'>

                <h1 className='title mobile'>PREMIOS</h1>

                <div className={'prize-list'}>
                    <div className='prize-block prize-0'>
                        <div className='prize-image-container'>
                            <img src={prizeRecipe} alt='Recetas' className='prize-img'/>
                        </div>
                        <div className='prize-info'>
                            <p className='prize-title'>PREMIOS INSTANTÁNEOS</p>
                            <div className='prize-description'>
                                <div className='first-prize-desc'>
                                    <span>AL REGISTRARTE GANARÁS WALLPAPERS</span>
                                </div>
                                <div className='divider'/>
                                <div className='first-prize-desc'>
                                    <span>AL JUGAR POR PRIMERA VEZ GANARÁS UN RECETARIO DIGITAL</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='prize-block prize-1'>
                        <div className='prize-image-container'>
                            <img src={prizeCinema} alt='Cine' className='prize-img'/>
                        </div>
                        <div className='prize-info'>
                            <p className='prize-title'>PREMIOS DIARIOS</p>
                            <div className='prize-description'>
                                PODRÁS GANAR BOLETOS DE CINE O TARJETAS DE REGALO
                            </div>
                        </div>
                    </div>

                    <div className='prize-block prize-2'>
                        <div className='prize-image-container'>
                            <img src={prizePlay} alt='Play consola' className='prize-img'/>
                        </div>
                        <div className='prize-info'>
                            <p className='prize-title'>PREMIOS SEMANALES</p>
                            <div className='prize-description'>
                                PODRÁS GANAR UNA CONSOLA DE VIDEOJUEGOS O UN ASISTENTE VIRTUAL
                            </div>
                        </div>
                    </div>

                    <div className='prize-block prize-3'>
                        <div className='prize-image-container'>
                            <img src={prizeTrip} alt='Cine' className='prize-img'/>
                        </div>
                        <div className='prize-info'>
                            <p className='prize-title'>GRAN PREMIO</p>
                            <div className='prize-description'>
                                PODRÁS GANAR UN VIAJE
                            </div>
                        </div>
                    </div>
                </div>

                <h1 className='title web'>PREMIOS</h1>

            </div>

            <div className='footer'>
                <img src={leftCorner} className={'left corner web'} alt={'corner'} />
                <img src={rightCorner} className={'right corner web'} alt={'corner'}/>
                <img src={footerMobile} className={'corner mobile'} alt={'corner'}/>
            </div>

        </GeneralContainer>
    );
};

export default Prizes;
