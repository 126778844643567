import React from 'react';
import './PrivacyPolicy.scss';
import GeneralContainer from "../../../components/layout/GeneralContainer/GeneralContainer";

const PrivacyPolicy = () => {

    return (
        <GeneralContainer className={"PrivacyPolicy"} footer>

            <div className='container'>
                <h1 className='title'><strong>AVISO DE PRIVACIDAD DEL SITIO.</strong></h1>
                <p><br/></p>
                <p><br/></p>
                
                
                <p>El presente Aviso de Privacidad se pone a disposici&oacute;n del usuario del sitio identificado como <u><a href="http://www.factororeo.com/">www.factororeo.com</a></u> (en adelante el Sitio Web) en su car&aacute;cter de titular de algunos datos personales que pueden ser solicitados por el Sitio Web, en cumplimiento a lo dispuesto por la Ley Federal de Protecci&oacute;n de Datos Personales en Posesi&oacute;n de los Particulares (en lo sucesivo la Ley) y dem&aacute;s disposiciones legales aplicables.</p>
                <p><br/></p>
                <p>Agencia Mostacho, S.A. de C.V. es una sociedad mercantil legalmente constituida de conformidad con las leyes de la Rep&uacute;blica Mexicana, y es responsable del tratamiento de los datos personales obtenidos mediante &eacute;ste sitio, quien, con el compromiso de observar los principios de licitud, consentimiento, informaci&oacute;n, calidad, finalidad, lealtad, proporcionalidad y responsabilidad en el tratamiento de datos personales y los derechos de privacidad y autodeterminaci&oacute;n informativa, hace constar en el presente aviso de privacidad lo siguiente:</p>
                <p><br/></p>
                <p>Los datos personales del usuario del Sitio Web que tratar&aacute; Agencia Mostacho, S.A. de C.V. incluyendo la obtenci&oacute;n, uso, divulgaci&oacute;n o almacenamiento de tales datos por cualquier medio, su acceso, manejo, aprovechamiento, transferencia o disposici&oacute;n, son aquellos que el usuario ha proporcionado o proporcione por ser necesarios para la prestaci&oacute;n del servicio ofrecido mediante el Sitio Web.</p>
                <p><br/></p>
                <p>A continuaci&oacute;n, se enlistan algunos de los datos personales a que se refiere este apartado:</p>
                <p><br/></p>
                <ol>
                    <li>
                        <p>Nombre completo.</p>
                    </li>
                    <li>
                        <p>Apellido Paterno y Apellido Materno.</p>
                    </li>
                    <li>
                        <p>Fecha de nacimiento (Edad): D&iacute;a/Mes/A&ntilde;o</p>
                    </li>
                    <li>
                        <p>Tel&eacute;fono a diez d&iacute;gitos.</p>
                    </li>
                    <li>
                        <p>Ciudad.</p>
                    </li>
                    <li>
                        <p>Estado de la Rep&uacute;blica.</p>
                    </li>
                    <li>
                        <p>Correo electr&oacute;nico.</p>
                    </li>
                </ol>
                <p><br/></p>
                <p>La lista anterior debe entenderse como enunciativa y no limitativa de aquellos datos de car&aacute;cter personal que ser&aacute;n tratados por Agencia Mostacho, S.A. de C.V. en el entendido que se trata de datos personales de la misma naturaleza, es decir, que no son datos personales sensibles. Esto es Agencia Mostacho, S.A. de C.V. no solicita datos que revelen el origen racial o &eacute;tnico, estado de salud, informaci&oacute;n gen&eacute;tica, creencias religiosas, filos&oacute;ficas y morales, afiliaci&oacute;n sindical, opiniones pol&iacute;ticas o preferencia sexual.</p>
                <p><br/></p>
                <p>Los datos personales del usuario ser&aacute;n tratados por Agencia Mostacho, S.A. de C.V. y/o las empresas afiliadas, asociadas o subsidiarias de la misma, encargados que act&uacute;en en nombre de Agencia Mostacho, S.A. de C.V. y terceros, quienes deber&aacute;n cumplir con el presente Aviso de Privacidad.</p>
                <p><br/></p>
                <p>Las finalidades del tratamiento de los datos personales de los usuarios otorgados Agencia Mostacho, S.A. de C.V. a trav&eacute;s del sitio web, &uacute;nica y exclusivamente ser&aacute;n para la participaci&oacute;n, selecci&oacute;n, administraci&oacute;n, coordinaci&oacute;n y log&iacute;stica relativa a la entrega de Premios e Incentivos, y todo acto relaci&oacute;n directa o indirecta con la presente din&aacute;mica/promoci&oacute;n.</p>
                <p><br/></p>
                <p>Al leer el presente aviso de privacidad y no presentar objeci&oacute;n alguna en un t&eacute;rmino de 5 d&iacute;as a partir de la fecha en que se proporcionaron los datos personales a trav&eacute;s del Sitio Web, el usuario otorga t&aacute;citamente a Agencia Mostacho, S.A. de C.V. su consentimiento para la transferencia nacional e internacional de sus datos personales, siempre que el receptor de los datos asuma las mismas obligaciones asumidas por Agencia Mostacho, S.A. de C.V.</p>
                <p><br/></p>
                <p>Agencia Mostacho, S.A. de C.V. establecer&aacute; y mantendr&aacute; medidas de seguridad, administrativas, t&eacute;cnicas y f&iacute;sicas que permitan proteger los datos personales contra da&ntilde;o, p&eacute;rdida, alteraci&oacute;n, destrucci&oacute;n o el uso, acceso o tratamiento no autorizado. Estas medidas no ser&aacute;n menores a aquellas que Agencia Mostacho, S.A. de C.V. aplique para el manejo de su propia informaci&oacute;n.</p>
                <p><br/></p>
                <p>El usuario del Sitio Web, como titular de sus datos personales, podr&aacute; ejercer los derechos de acceso, cancelaci&oacute;n, rectificaci&oacute;n y oposici&oacute;n, respecto de sus datos. El ejercicio de estos derechos se iniciar&aacute; a trav&eacute;s de la presentaci&oacute;n de una solicitud a trav&eacute;s del correo <u><a href="mailto:hola@factororeo.com">hola@factororeo.com</a></u> o por escrito dirigida al desarrollador Agencia Mostacho, S.A. de C.V. La solicitud deber&aacute; ser presentada por el usuario del Sitio Web o su representante legal, en un escrito libre que deber&aacute; contener: i) el nombre completo del usuario y domicilio u otro medio para comunicarle la respuesta, ii) documentos que acrediten su identidad o la del representante legal, iii) la descripci&oacute;n clara y precisa de los datos personales respecto de lo que busca ejercer sus derechos, iv) cualquier otro elemento que facilite la localizaci&oacute;n de los datos personales del usuario.</p>
                <p><br/></p>
                <p>Agencia Mostacho, S.A. de C.V. comunicar&aacute; al usuario en t&eacute;rminos de la ley de la materia. Se dar&aacute; cumplimiento a una solicitud de acceso, poniendo a disposici&oacute;n del usuario del Sitio Web o su representante, previo acreditamiento de su identidad, los documentos donde obren los datos personales requeridos, ya sea mediante copias fotost&aacute;ticas o correo electr&oacute;nico. La entrega de los datos ser&aacute; gratuita siempre y cuando no se repita la solicitud de acceso en un periodo menor a 12 meses. El usuario del Sitio Web &uacute;nicamente cubrir&aacute; los costos de reproducci&oacute;n en copias u otros formatos.</p>
                <p><br/></p>
                <p>El usuario del Sitio Web podr&aacute; rectificar sus datos personales cuando estos sean inexactos o incompletos, indicando en la solicitud de rectificaci&oacute;n las modificaciones que deban realizarse y aportando a Agencia Mostacho, S.A. de C.V. la documentaci&oacute;n que sustente su petici&oacute;n. En caso de ser procedente la solicitud, Agencia Mostacho, S.A. de C.V. informar&aacute; de los cambios de que se trate a los encargados del tratamiento y a terceros, en caso de que haya habido transferencias de datos en los t&eacute;rminos del presente Aviso de Privacidad.</p>
                <p><br/></p>
                <p>El derecho de cancelaci&oacute;n consiste en la supresi&oacute;n del dato y puede ir precedido por un periodo de bloqueo en el que los datos no podr&aacute;n ser objeto de tratamiento. No proceder&aacute; la cancelaci&oacute;n de los datos personales en los casos previstos por la Ley.</p>
                <p><br/></p>
                <p>El usuario del Sitio Web tendr&aacute; derecho en todo momento y por causa leg&iacute;tima a oponerse al tratamiento de sus datos. De resultar procedente la solicitud, Agencia Mostacho, S.A. de C.V. no har&aacute; m&aacute;s uso de sus datos personales.</p>
                <p><br/></p>
                <p>Agencia Mostacho, S.A. de C.V. podr&aacute; negar el acceso a los datos personales, o realizar la rectificaci&oacute;n o cancelaci&oacute;n o conceder la oposici&oacute;n cuando el solicitante no sea el titular de los datos o el representante legal no est&eacute; debidamente acreditado para ello; cuando en su base de datos no se encuentren los datos personales de usuario; cuando se lesionen derechos de un tercero; cuando exista un impedimento legal o una resoluci&oacute;n de una autoridad competente que restrinja el acceso a los datos personales o no permita su rectificaci&oacute;n, cancelaci&oacute;n u oposici&oacute;n y cuando la rectificaci&oacute;n, cancelaci&oacute;n u oposici&oacute;n haya sido previamente realizada.</p>
                <p><br/></p>
                <p>En caso de que se efect&uacute;en cambios al presente Aviso de Privacidad, Agencia Mostacho, S.A. de C.V. los har&aacute; del conocimiento de los usuarios del Sitio Web, a trav&eacute;s del mismo. Si el usuario del Sitio Web no hace manifestaci&oacute;n alguna respecto de la modificaci&oacute;n en el plazo de 5 d&iacute;as h&aacute;biles, se entender&aacute; que la misma ha sido aceptada en forma t&aacute;cita.</p>
                <p><br/></p>
                <p>El consentimiento para el tratamiento de datos personales podr&aacute; ser revocado mediante aviso por escrito, que el usuario del Sitio Web proporcione al Departamento de Datos Personales de Agencia Mostacho, S.A. de C.V. en el cual incluya las razones por las que revoca el consentimiento.</p>
                <p><br/></p>
                <p>Agencia Mostacho, S.A. de C.V. dar&aacute; tr&aacute;mite a las solicitudes de acceso, rectificaci&oacute;n, cancelaci&oacute;n y oposici&oacute;n, mediante escrito dirigido a dicho departamento, cuya oficina se ubica en Arkansas 35, colonia N&aacute;poles, Alcald&iacute;a Benito Ju&aacute;rez, C.P. 03810 en Ciudad de M&eacute;xico y cumpliendo con los requisitos establecidos en el presente Aviso de Privacidad.</p>
                <p><br/></p>
                <p>Los Datos Personales del Titular pueden ser transferidos y tratados con sociedades filiales, sociedades asociadas, y dem&aacute;s sociedades del mismo grupo comercial, clientes y proveedores de&nbsp;Agencia Mostacho, S.A. de C.V.</p>
                <p><br/></p>
                <p>Asimismo, sus Datos personales son compartidos dentro y fuera del pa&iacute;s con las siguientes personas, empresas, organizaciones y autoridades distintas a&nbsp;Agencia Mostacho, S.A. de C.V. con el siguiente fin:</p>
                <p><br/></p>
                <p><br/></p>
                <p>Empresa Pa&iacute;s Finalidad</p>
                <p><br/></p>
                <p>MONDELEZ MEXICO S.&nbsp;DE R.L. DE C.V.Estados Unidos Mexicanos *Promoci&oacute;n, divulgaci&oacute;n, y&nbsp;</p>
                <p>difusi&oacute;n de los productos&nbsp;</p>
                <p>y servicios de sus Marcas.</p>
                <p><br/></p>
                <p>&nbsp;*Env&iacute;o de mensajes electr&oacute;nicos</p>
                <p>&nbsp;*Uso mercadol&oacute;gico</p>
                <p>&nbsp;*Env&iacute;o de comunicaci&oacute;n&nbsp;</p>
                <p>v&iacute;a telefon&iacute;a m&oacute;vil</p>
                <p>&nbsp;</p>
                <p><br/></p>
                <p>6.Si no manifiesta su oposici&oacute;n para que los Datos Personales (con excepci&oacute;n de los Datos Personales Patrimoniales, que s&oacute;lo ser&aacute;n trasmitidos con el previo consentimiento por escrito del Titular) sean transferidos, se entender&aacute; que a la aceptaci&oacute;n del presente Aviso de Privacidad el Titular ha otorgado a&nbsp;Agencia Mostacho, S.A. de C.V. su consentimiento expreso para su transmisi&oacute;n.</p>
                <p><br/></p>
                <p>Al leer el presente Aviso de Privacidad y no hacer manifestaci&oacute;n alguna en el plazo de 5 d&iacute;as h&aacute;biles, el usuario del Sitio Web manifiesta su conocimiento y conformidad con su contenido. Asimismo, quedar&aacute; manifiesto en forma t&aacute;cita su consentimiento para que Agencia Mostacho, S.A. de C.V. lleve a cabo el tratamiento de sus datos personales en los t&eacute;rminos que en el mismo se se&ntilde;alan.</p>
                <p><br/></p>
                <p><br/></p>
                <p><br/></p>

            </div>

        </GeneralContainer>
    );
};

export default PrivacyPolicy;
