import moment from "moment";

export const gameTableColumns = [
    {
        Header: 'ID',
        accessor: 'id',
    },
    {
        Header: 'Fecha de registro',
        accessor: function (ticket) {

            if (!ticket.createdDate) {
                return 'Sin fecha';
            }

            return moment(ticket.createdDate).format('DD/MM/YYYY HH:mm');
        },
    },
    {
        Header: 'Id de usuario',
        accessor: 'createdBy.id',
    },
    {
        Header: 'Email',
        accessor: 'createdBy.email',
    },
    {
        Header: 'Correctos',
        accessor: 'correctItems',
    },
    {
        Header: 'Incorrectos',
        accessor: 'incorrectItems',
    },
    {
        Header: 'Puntos',
        accessor: 'points',
    }    
];

export const gamesListSGroups = [
    "game_read",
    "read_created_date",
    "read_created_by",
    "user_read_id",
    "user_read_email"
]
