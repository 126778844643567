import { copyPropertyOrThrow } from "../formUtils";
import {emailIsValid} from "../validations";

export const getUserFullName = (user)=>{
    return user?.name+" "+user?.matLastName+" "+user?.patLastName;
}
export const prepareUserFromRegister = ( form )=>{
    let user = {}

    copyPropertyOrThrow( form, user, "name", "Por favor llena el campo \"nombre\"");
    copyPropertyOrThrow( form, user, "patLastName", "Por favor llena el campo \"apellido paterno\"");
    copyPropertyOrThrow( form, user, "matLastName", "Por favor llena el campo \"apellido materno\"");
    copyPropertyOrThrow( form, user, "state", "Por favor llena el campo \"estado\"");
    copyPropertyOrThrow( form, user, "city", "Por favor llena el campo \"ciudad\"");
    copyPropertyOrThrow( form, user, "phone", "Por favor llena el campo \"teléfono\" " );
    copyPropertyOrThrow( form, user, "email", "Por favor llena el campo \"email\"");
    copyPropertyOrThrow( form, user, "password","Por favor llena el caompo \"contraseña\"");
    copyPropertyOrThrow( form, user, "confirmPass","Por favor llena el campo \"confirmar contraseña\" " );

    user.birthday= `${form.year?.value}-${form.month?.value?.toString().padStart(2, '0')}-${form.day?.value?.toString().padStart(2, '0')}`;

    if(form.password.length<4){
        throw new Error('El password debe ser al menos de 4 caracteres');
    }

    if(!emailIsValid(form.email.trim())){
        throw new Error('Por favor ingresa un correo válido');
    }

    if(!form.year || !form.month || !form.day){
        throw new Error('Por favor ingresa la fecha de nacimiento');
    }

    if(form.gender){
       user.gender = form.gender.value;
    }

    if(form.password !== form.confirmPass){
        throw new Error('Las contraseñas no coinciden');
    }

    user.state = form.state.id;
    user.username = user.email;
    user.phone = user.phone?.toString().replace(/\D/g,'');
    user.email = user.email.trim();

    return user;
}

const ranks = [
    'Oreo® Pro',
    'Oreo® Máster',
    'Oreo® Avanzado',
    'Oreo® Principiante',
];

export const getUserRankName = (user)=>{
    if(!user){
        return ranks[3];
    }
    if(user.rank < 0.25 )
        return ranks[0];
    if(user.rank < 0.55 )
        return ranks[1];
    if(user.rank < 0.75 )
        return ranks[2];
    return ranks[3];
}


export const userTableColumns = [
    {
        Header: 'ID',
        accessor: 'id',
    },
    {
        Header: 'Email',
        accessor: 'email',
    },
    {
        Header: 'Puntos',
        accessor: function(user){
            return user.points?user.points:0;
        }
    },
    {
        Header: 'Vidas',
        accessor: function(user) {
           return user.numOfLives?user.numOfLives:0;
        }
    }
];
export const usersListSGroups = [
    "user_read"
];

export const userDetailsSGroups = [
    "user_read", 
    "state_read", 
    "user_read_state", 
    "user_read_created_date",
    "user_read_age_range"
];