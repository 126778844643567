import React from 'react';
import './Pagination.scss';

const Pagination = ({reactTable}) => {

    const pages = reactTable.pageCount;
    const pageIndex = reactTable.state.pageIndex;

    const existsPrev = pageIndex > 0;
    const existsNext = pageIndex < (pages-1);


    return (
        <div className={'TideReactTable-Pagination'}>

            <button className='pagination-btn pag-ctrl' disabled={!existsPrev}
                    onClick={()=>reactTable.gotoPage(0)}>
                &#x3c;&#x3c;
            </button>


            <button className='pagination-btn pag-ctrl' disabled={!existsPrev}
                    onClick={()=>reactTable.gotoPage(pageIndex-1)}>
                &#x3c;
            </button>

            {existsPrev &&
            <button className='pagination-btn' onClick={()=>reactTable.gotoPage(pageIndex-1)}>
                {pageIndex}</button>}

            <button className='pagination-btn active'>
                {pageIndex+1}</button>
            {existsNext &&
            <button className='pagination-btn' onClick={()=>reactTable.gotoPage(pageIndex+1)}>
                {pageIndex+2}</button>}

            {pages > (pageIndex+2) &&
            <button className='pagination-btn' onClick={()=>reactTable.gotoPage(pages-1)}>
                {reactTable.pageCount}</button>}

            <button className='pagination-btn pag-ctrl'  disabled={!existsNext}
                    onClick={()=>reactTable.gotoPage(pageIndex+1)}>

                &#x3e;
            </button>
            <button className='pagination-btn pag-ctrl'  disabled={!existsNext}
                    onClick={()=>reactTable.gotoPage(pages-1)}>
                &#x3e;&#x3e;
            </button>
        </div>
    );
};

export default Pagination;
