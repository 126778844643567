import React from 'react';
import './TermsOfUse.scss';
import GeneralContainer from "../../../components/layout/GeneralContainer/GeneralContainer";

const TermsOfUse = () => {

    return (
        <GeneralContainer className={"TermsOfUse"} footer backButtonRoute={'/'}>
            <div className='container'>
                <h1 className='title'>T&Eacute;RMINOS DE USO</h1>
                
                <p><br/></p>

                <p>ESTOS T&Eacute;RMINOS DE USO (LOS &quot;T&Eacute;RMINOS&quot;) SON UN CONTRATO LEGAL ENTRE USTED Y MONDELEZ GLOBAL LLC. LOS T&Eacute;RMINOS EXPLICAN C&Oacute;MO SE LE PERMITE UTILIZAR ESTE SITIO WEB. AL UTILIZAR ESTE SITIO, USTED ACEPTA TODOS LOS T&Eacute;RMINOS; SI NO EST&Aacute; DE ACUERDO CON ALGUNO DE ESTOS T&Eacute;RMINOS, NO ACCEDA NI UTILICE ESTE SITIO, NING&Uacute;N SERVICIO DISPONIBLE A TRAV&Eacute;S DE ESTE SITIO O CUALQUIER INFORMACI&Oacute;N CONTENIDA EN ESTE SITIO.</p>
                <p><br/></p>
                <p>Al acceder o utilizar este Sitio, usted acepta todos los T&eacute;rminos y nuestra Pol&iacute;tica de privacidad (como se define a continuaci&oacute;n). Si no est&aacute; de acuerdo con todos los T&eacute;rminos y la Pol&iacute;tica de privacidad, no acceda ni utilice este Sitio, ning&uacute;n servicio disponible a trav&eacute;s de este sitio ni ninguna informaci&oacute;n contenida en este Sitio.</p>
                <p><br/></p>
                <p>Cambios</p>
                <p>La Compa&ntilde;&iacute;a puede realizar cambios en el contenido previsto en el Sitio en cualquier momento. La Compa&ntilde;&iacute;a puede cambiar, actualizar, agregar o eliminar disposiciones de estos T&eacute;rminos, en cualquier momento mediante la publicaci&oacute;n de los T&eacute;rminos actualizados en este Sitio o, si se ha registrado para una cuenta en el Sitio, podemos enviarle un correo electr&oacute;nico a la direcci&oacute;n de correo electr&oacute;nico direcci&oacute;n registrada para su cuenta. Al usar este Sitio despu&eacute;s de que la Compa&ntilde;&iacute;a haya actualizado los T&eacute;rminos, usted acepta todos los T&eacute;rminos actualizados; si no est&aacute; de acuerdo con alguno de los T&eacute;rminos actualizados, debe dejar de usar el Sitio.</p>
                <p><br/></p>
                <p>Su uso del sitio</p>
                <p>Al usar este Sitio, usted declara, reconoce y acepta que tiene al menos 18 a&ntilde;os de edad, o si es menor de 18 a&ntilde;os pero tiene al menos 13 a&ntilde;os (un &quot;Menor&quot;), que est&aacute; usando el Sitio con el consentimiento de sus padres o tutores legales y que ha recibido el permiso de sus padres o tutores legales para usar el Sitio y aceptar sus T&eacute;rminos. Si usted es padre o tutor legal de un Menor, por el presente acepta vincular al Menor a estos T&eacute;rminos e indemnizar completamente y eximir de responsabilidad a la Compa&ntilde;&iacute;a si el Menor incumple cualquiera de estos T&eacute;rminos. Si no tiene al menos 13 a&ntilde;os, no puede usar el Sitio en ning&uacute;n momento ni de ninguna manera ni enviar informaci&oacute;n a la Compa&ntilde;&iacute;a o al Sitio.</p>
                <p><br/></p>
                <p>Uso del sitio</p>
                <p>Simplemente puede ver el Sitio sin necesidad de registrarse con la Compa&ntilde;&iacute;a para simplemente visitar y ver el Sitio.</p>
                <p><br/></p>
                <p>Si desea registrarse para obtener una cuenta con la Compa&ntilde;&iacute;a, debe enviar la siguiente informaci&oacute;n a trav&eacute;s de la p&aacute;gina de registro de cuenta en el Sitio: su nombre y apellido, direcci&oacute;n de correo electr&oacute;nico ytipo de negocio, si corresponde. Tambi&eacute;n tendr&aacute; la posibilidad de proporcionar informaci&oacute;n opcional adicional, como su direcci&oacute;n, que no es necesaria para registrarse en una cuenta, pero que puede ser &uacute;til para que la Compa&ntilde;&iacute;a le brinde una experiencia m&aacute;s personalizada al usar el Sitio o sus servicios, o en la mejora de nuestros productos. Una vez que haya enviado la informaci&oacute;n de registro de su cuenta, el administrador de la Empresa tendr&aacute; derecho a aprobar o rechazar el registro solicitado, a su exclusivo criterio. Crear&aacute; su propia contrase&ntilde;a cuando complete el formulario de env&iacute;o (&quot;Contrase&ntilde;a del sitio&quot;).</p>
                <p><br/></p>
                <p>Usted es responsable de mantener la confidencialidad de su Contrase&ntilde;a del sitio y es responsable de todas las actividades que se realicen con su Contrase&ntilde;a del sitio. Usted acepta no compartir su Contrase&ntilde;a del sitio, permitir que otros accedan o usen su Contrase&ntilde;a del sitio o hacer cualquier otra cosa que pueda poner en peligro la seguridad de su Contrase&ntilde;a del sitio. Usted acepta notificar a la Compa&ntilde;&iacute;a si pierde o le roban su Contrase&ntilde;a del sitio, si tiene conocimiento de cualquier uso no autorizado de su Contrase&ntilde;a del sitio en este Sitio o si tiene conocimiento de cualquier otra violaci&oacute;n de la seguridad en relaci&oacute;n con este Sitio.</p>
                <p><br/></p>
                <p>Toda la informaci&oacute;n que proporcione al registrarse para obtener una cuenta y de otra manera a trav&eacute;s del Sitio debe ser precisa, completa y actualizada. Si crea una cuenta, puede cambiar, corregir o eliminar cualquier informaci&oacute;n de su cuenta ya sea (i) iniciando sesi&oacute;n en su cuenta directamente y realizando los cambios deseados (si el Sitio proporciona dicha funci&oacute;n), o (ii) comunic&aacute;ndose con el Empresa utilizando la informaci&oacute;n de contacto al final de estos T&eacute;rminos solicitando que hagamos el cambio.</p>
                <p><br/></p>
                <p>Pol&iacute;tica de privacidad</p>
                <p>Revise la Pol&iacute;tica de privacidad de la Compa&ntilde;&iacute;a, que est&aacute; disponible en https://www.mondelezinternational.com/privacy-policy (la &quot;Pol&iacute;tica de privacidad&quot;), que explica c&oacute;mo usamos la informaci&oacute;n que env&iacute;a a la Compa&ntilde;&iacute;a.</p>
                <p><br/></p>
                <p>Usted reconoce que es responsable de cualquier informaci&oacute;n, opiniones, mensajes, comentarios, fotos, videos, gr&aacute;ficos, sonidos y otro contenido o material que env&iacute;e, cargue, publique o ponga a disposici&oacute;n de otro modo en o a trav&eacute;s del Sitio (cada uno de ellos un &quot;Env&iacute;o&quot; ). No puede cargar, publicar ni poner a disposici&oacute;n en este Sitio ning&uacute;n material protegido por derechos de autor, marca registrada o cualquier otro derecho de propiedad sin el permiso expreso del propietario de dicho derecho de autor, marca registrada u otro derecho de propiedad propiedad de un tercero, y la carga de determinar si alg&uacute;n material est&aacute; protegido por dicho derecho recae en usted. Usted ser&aacute; el &uacute;nico responsable de cualquier da&ntilde;o que resulte de cualquier infracci&oacute;n de derechos de autor, marcas registradas, derechos de propiedad, violaci&oacute;n de contrato, derechos de privacidad o publicidad.</p>
                <p><br/></p>

            </div>
        </GeneralContainer>
    );
};

export default TermsOfUse;
