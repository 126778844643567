import React, {useContext} from 'react';
import './AvatarSelection.scss';
import GeneralContainer from "../../../components/layout/GeneralContainer/GeneralContainer";
import {useSelector} from "react-redux";
import {avatars} from "../../../services/avatarUtils";
import selectImg from './assets/boton_selecciona_tu_avatar_web.png';
import topWave from './assets/pleca_superior_avatar_web.png';
import {paths} from "../../../services/routes/appRoutes";
import {ApiContext} from "../../../services/api/api-config";
import {useNavigate} from "react-router-dom";
import leftCorner from "../../../assets/images/corners/pleca_inferior_izquierda_perfil_web.png";
import rightCorner from "../../../assets/images/corners/pleca_derecha_avatar_web.png";
import footerMobile from "../../../assets/images/corners/pleca_mobile_galleta_malteada.png";

const AvatarSelection = () => {

    const api = useContext(ApiContext);
    const me = useSelector(s=>s.api.me);
    const navigate = useNavigate();
    const [selected, setSelected] = React.useState(()=>avatars.find(a=>a.id===me.avatar) || avatars[0]);

    const loadingId = 'AvatarSelection.saving';
    const saveAvatar = () => {
        api.users.update({id: me.id+'', params:{ avatar: selected.id }, loadingId})
            .then(()=>api.me.get())
            .then(()=>navigate(paths.userHome));
    }

    const loading = useSelector(s=>s.loadingIds[loadingId]);

    return (
        <GeneralContainer className={"AvatarSelection"} backButton backButtonRoute={paths.userHome}>
            <div className='avatar-container'>
                <div className='selected-block'>
                    <div className='avatar-house'>
                        <img className={'big-avatar'} alt='avatar' src={selected.big} />
                    </div>
                    <button className='select-btn' disabled={loading} onClick={saveAvatar}>
                        <img className='select-img' alt={'Selecciona tu avatar'} src={selectImg} />
                    </button>
                </div>
                <div className='options-block'>
                    <div className='avatar-list-wrap'>
                        <div className='avatar-list'>
                            {avatars.map(avatar=>(
                                <div className='avatar-item' key={avatar.id} onClick={()=>setSelected(avatar)}>
                                    <img className='avatar-opt-img' alt={'Avatar '+avatar.id} src={avatar.small}/>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <img className='top-wave' alt={'wave'} src={topWave} />
            <div className='footer'>
                <img src={leftCorner} className={'left corner web'} alt={'corner'} />
                <img src={rightCorner} className={'right corner web'} alt={'corner'}/>
                <img src={footerMobile} className={'corner mobile'} alt={'corner'}/>
            </div>
        </GeneralContainer>
    );
};

export default AvatarSelection;
