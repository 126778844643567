import React from 'react';
import './RappiAdvice.scss';
import rocketImage from "../../../../../assets/images/corners/cohete_menu_web.png";
import rocketImageMobile from "../../../../../assets/images/corners/cohete_mobile.png";

const RappiAdvice = ({ onClose }) => {

    return (
        <div className={"RappiAdvice"}>
            <h2 className='advice-title'>Instrucciones para registrar tus compras con Rappi</h2>
            <div className='the-advice'>
                <p>1) Sube la foto de tu ticket de compra y captura el número de orden, recuerda pedir el ticket a tu
                    repartidor.</p>
                <p className='example'>Ej. 242975453</p>
                <p>2) También puedes completar el registro con la captura de pantalla de la compra, ingresa la fecha, hora de
                    entrega y nombre del repartidor</p>
                <p className='example'>Ej. 050822- 1:19- Julio Daniel Murcia Rojas</p>
            </div>
            <div className='btn-container'>
                <button className='accept' onClick={onClose}>ACEPTAR</button>
            </div>

            <img src={rocketImage} className="rocket web" alt="Rocket" />
            <img src={rocketImageMobile} className="rocket mobile" alt="Rocket" />

        </div>
    );
};

export default RappiAdvice;
