import React from 'react';
import './RankingMenu.scss';
import GeneralContainer from "../../../components/layout/GeneralContainer/GeneralContainer";
import {paths} from "../../../services/routes/appRoutes";
import leftCorner from "../../../assets/images/corners/pleca_izquierda_ticket_web.png";
import rightCorner from "../../../assets/images/corners/pleca_derecha_ranking_web.png";
import footerMobile from "../../../assets/images/corners/pleca_premios_mobile.png";
import {Link} from "react-router-dom";
import dayRankingButton from './assets/boton_ranking_dia_web.png';
import dayRankingPrize from './assets/icono_palomitas.png';
import weekRankingButton from './assets/boton_ranking_semanal_web.png';
import weekRankingPrize from './assets/icono_consola.png';
import generalRankingButton from './assets/boton_ranking_general_web.png';
import generalRankingPrize from './assets/icono_viaje.png';
import winnerRankingButton from './assets/boton_ganadores_web.png';
import {rankingTypes} from "../../../services/rankingUtils";
import useBoolean from "../../../hooks/useBoolean";


const RankingMenu = () => {

    const [winnersOpen,,,toggleWinners] = useBoolean();

    return (
        <GeneralContainer className={"RankingMenu"} backButtonRoute={paths.userHome} unicorn footer>

            <div className='ranking-container'>
                <h1 className='title'>
                    <span className='up'>SELECCIONA</span><br/>
                    EL RANKING A CONSULTAR
                </h1>
                <div className='options'>
                    <div className='options-row'>

                        <div className='option'>
                            <Link to={paths.rankingList.replace(':type', rankingTypes.daily)}>
                                <img src={dayRankingButton} alt="Ranking de día" className='btn-img'/>
                            </Link>
                            <div className='prize-desc'>
                                <p className='text'>PODRÁS GANAR BOLETOS DE CINE<br/>
                                O TARJETAS DE REGALO</p>
                                <img className='prize-icon' alt='Tarjeta' src={dayRankingPrize}/>
                            </div>
                        </div>

                        <div className='option'>
                            <Link to={paths.rankingList.replace(':type', rankingTypes.weekly)}>
                                <img src={weekRankingButton} alt="Ranking de día" className='btn-img'/>
                            </Link>
                            <div className='prize-desc'>
                                <p className='text'>
                                    PODRÁS GANAR UNA CONSOLA<br/>
                                    DE VIDEOJUEGOS O UN ASISTENTE VIRTUAL
                                </p>
                                <img className='prize-icon' alt='Tarjeta' src={weekRankingPrize}/>
                            </div>
                        </div>

                    </div>
                    <div className='options-row'>

                        <div className='option'>
                            <Link to={paths.rankingList.replace(':type', rankingTypes.general)}>
                                <img src={generalRankingButton} alt="Ranking de día" className='btn-img'/>
                            </Link>
                            <div className='prize-desc'>
                                <p className='text'>PODRÁS GANAR UN VIAJE</p>
                                <img className='prize-icon' alt='Tarjeta' src={generalRankingPrize}/>
                            </div>
                        </div>

                        <div className='option'>
                            <button onClick={toggleWinners} className='winners-button'>
                                <img src={winnerRankingButton} alt="Ranking de día" className='btn-img'/>
                            </button>
                            {winnersOpen && <div className='winners-menu'>
                                <Link className={"winner-link"}  to={paths.rankingList.replace(':type', rankingTypes.dailyWinners)}>
                                    <span>GANADORES DIARIOS</span>
                                </Link>
                                <Link className={"winner-link"} to={paths.rankingList.replace(':type', rankingTypes.weeklyWinners)}>
                                    <span>GANADORES SEMANALES</span>
                                </Link>
                            </div>}
                        </div>

                    </div>

                </div>
            </div>

            <div className='footer'>
                <img src={leftCorner} className={'left corner web'} alt={'corner'} />
                <img src={rightCorner} className={'right corner web'} alt={'corner'}/>
                <img src={footerMobile} className={'corner mobile'} alt={'corner'}/>
            </div>
        </GeneralContainer>
    );
};

export default RankingMenu;
