import Login from "../../scenes/public/Login/Login";
import Register from "../../scenes/public/Register/Register";
import Home from "../../scenes/public/Home/Home";
import ForgotPass from "../../scenes/public/ForgotPass/ForgotPass";
import EmailSent from "../../scenes/public/EmailSent/EmailSent";
import Countdown from "../../scenes/public/Countdown/Countdown";
import LoginOrRegister from "../../scenes/public/LoginOrRegister/LoginOrRegister";
import ResetPassword from "../../scenes/public/ResetPassword/ResetPassword";
import Contact from "../../scenes/public/Contact/Contact";
import PrivacyPolicy from "../../scenes/public/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "../../scenes/public/TermsAndConditions/TermsAndConditions";
import Prizes from "../../scenes/public/Prizes/Prizes";
import Recipes from "../../scenes/public/Recipes/Recipes";
import Dynamic from "../../scenes/user/Dynamic/Dynamic";
import TermsOfUse from "../../scenes/public/TermsOfUse/TermsOfUse";
import Thankyou from "../../scenes/public/Thankyou/Thankyou";
import Winners from "../../scenes/public/Winners/Winners";

export const paths={
    countdown:'/countdown',
    forgotPass:'/forgot-pass',
    emailSent:'/email-sent',
    resetPassword:'/reset-password',
    home:'/',
    login:'/login',
    loginOrRegister:'/login-or-register',
    share:'/share/:code',
    register:'/register',
    contact:'/contact',
    privacy:'/privacy',
    terms:'/terms',
    prizes:'/prizes',
    recipes:'/recipes',
    dynamic: '/dynamic',
    termsAndConditions: '/terms-and-conditions',
    thankyou: '/thankyou',
    winners: '/winners'
};

const notLoggedRoutes=[
    {path:paths.home, Component: Home},
    {path:paths.loginOrRegister, Component: LoginOrRegister},
    {path:paths.login, Component: Login},
    {path:paths.register, Component: Register},
    {path:paths.share, Component: Register},
    {path:paths.forgotPass, Component: ForgotPass},
    {path:paths.emailSent, Component: EmailSent},
    {path:paths.resetPassword, Component: ResetPassword},
    {path:paths.countdown, Component: Countdown},
    {path:paths.contact, Component: Contact},
    {path:paths.privacy, Component: PrivacyPolicy},
    {path:paths.terms, Component: TermsOfUse},
    {path:paths.prizes, Component: Prizes},
    {path:paths.recipes, Component: Recipes},
    {path:paths.dynamic, Component: Dynamic},
    {path:paths.termsAndConditions, Component: TermsAndConditions},
    {path:paths.thankyou, Component: Thankyou},
    {path:paths.winners, Component: Winners},
];

export default notLoggedRoutes;
