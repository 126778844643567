import React, {useContext, useCallback} from 'react'
import {ApiContext} from "../../../services/api/api-config";
import useFormState from "../../../hooks/useFormState";
import GeneralContainer from '../../../components/layout/GeneralContainer/GeneralContainer';
import TextInput from '../../../components/forms/TextInput/TextInput';
import {getNotifier} from '../../../services/notifier';
import family from '../../../assets/images/reset-password/familia_contraseña_web.png';
import footerLeftImage from '../../../assets/images/reset-password/pleca_izquierda_oreo_web.png';
import footerRightImage from '../../../assets/images/forgot-pass/pleca-derecha_recuperar_web.png';
import './ResetPassword.scss';
import {useLocation, useNavigate} from "react-router-dom";
import {useSelector} from 'react-redux';
import {paths} from "../../../services/routes/notLoggedRoutes";

const ResetPassword = () => {
    const api = useContext(ApiContext);

    const {form, handleSimpleChange, setForm} = useFormState({});
    const queryString = useLocation().search;
    const loadingId = 'ResetPassword.updatePassword';
    const navigate = useNavigate();

    const updatePassword = useCallback((e) => {
        e.preventDefault();

        const token = new URLSearchParams(queryString).get("_token");

        if(!token) {
            return getNotifier().error("Debes acceder desde el enlace en el correo para cambiar tu contraseña.");
        }

        if (!form.password) {
            getNotifier().error("Por favor la nueva contraseña.");
            return;
        }

        if(form.password<4){
            getNotifier().error("La contraseña debe tener al menos 4 caracteres.");
            return;
        }

        if (form.password !== form.confirmPassword) {
            getNotifier().error("Las contraseñas no coinciden.");
            return;
        }


        api.users.resetPassword({params: {token: token, password: form.password}, loadingId})
            .then(() => {
                setForm({});
                getNotifier().success("Tu contraseña ha sido actualizada");
                navigate(paths.login);
            }).catch(() => {
            getNotifier().error("No pudimos actualizar tu contraseña, inténtalo más tarde o prueba solicitando nuevamente la recuperación de contraseña.");
        });

    }, [api, form, queryString, setForm, loadingId, navigate]);

    const loading = useSelector(s=>!!s.loadingIds[loadingId]);


    return (
        <GeneralContainer className='GeneralContainer--display-block ResetPassword' backButton={true} hideBackOnMobile={true}>

            <div className={"left-column"}>
                <img className='popcorns-image' src={family} alt=""/>
            </div>
            <div className={"right-column"}>
                <form onSubmit={updatePassword}>
                    <div className='ForgotPass__title'>NUEVA CONTRASEÑA</div>
                    <div className='input-block'>
                        <TextInput
                            placeholder="CONTRASEÑA"
                            label="CONTRASEÑA"
                            onChange={handleSimpleChange('password')}
                            value={form.password || ''}
                            type='password'
                        />
                        <TextInput
                            placeholder="CONFIRMAR CONTRASEÑA"
                            label="CONFIRMAR CONTRASEÑA"
                            onChange={handleSimpleChange('confirmPassword')}
                            value={form.confirmPassword || ''}
                            type='password'
                        />
                    </div>

                    <button className='oreo-button login-button' type='submit' disabled={loading}>ACEPTAR</button>
                </form>
            </div>

            <div className='clearfix'></div>

            <img className='footer-left-image' src={footerLeftImage} alt=""/>
            <img className='footer-right-image' src={footerRightImage} alt=""/>

        </GeneralContainer>
    );
}

export default ResetPassword;
