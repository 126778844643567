import React from 'react';
import './LoginOrRegister.scss';
import loginButton from '../../../assets/images/login-and-register/boton_inciar_sesion.png';
import GeneralContainer from '../../../components/layout/GeneralContainer/GeneralContainer';
import {paths} from "../../../services/routes/notLoggedRoutes";
import {Link} from "react-router-dom";
import { isPromotionFinishedDate } from '../../../services/promotionFinishUtils';

const LoginOrRegister = () => {
    return (
        <GeneralContainer className='GeneralContainer--display-block' footer>
            <div className="LoginOrRegister">
                {/*
                <div className={"main-image"}>
                    <img src={malteadaImage} alt="malteada"/>
                </div>
                */}
                <div className={"buttons-container"}>
                    <div className="enjoy-message">
                    SUMA MÁS CON FACTOR OREO® <br/> Y COMPARTE CON TU FAMILIA <br/>
                    </div>
                    {!isPromotionFinishedDate() && <Link className={'menu-item'} to={paths.register}>
                        <button className={"oreo-button register-button"}>REGÍSTRATE</button>
                    </Link>}
                    <Link className={'menu-item'} to={paths.login}>
                        <button className={"oreo-button"}>
                            <img src={loginButton} alt="Login button" className='btn-oreo-login' />
                        </button>
                    </Link>
                </div>

                <div className="footer-image"></div>
                {/*<img className='hotcakes' src={hotcakes} alt=""/>
                <img className='cookie' src={cookie} alt=""/>]*/}
            </div>
        </GeneralContainer>
    );
};

export default LoginOrRegister;
