import BowlBack from '../assets/game/Bowl_parte_superior.png';
import BowlFront from '../assets/game/Bowl_parte_inferior.png';

import GoodChocolate from '../assets/good-ingredients/Chocolate.png';
import GoodChocoBlanco from '../assets/good-ingredients/chocolate_blanco.png';
import GoodCrema from '../assets/good-ingredients/crema_batida.png';
import GoodFresa from '../assets/good-ingredients/fresa.png';
import GoodHarina from '../assets/good-ingredients/Harina.png';
import GoodHelado from '../assets/good-ingredients/Helado.png';
import GoodHuevo from '../assets/good-ingredients/huevo.png';
import GoodOreo from '../assets/good-ingredients/oreo.png';
import GoodPalomitas from '../assets/good-ingredients/Palomitas.png';
import GoodPhiladelphia from '../assets/good-ingredients/philadelphia.png';
import GoodLeche from '../assets/good-ingredients/Vaso-leche.png';

import BadAguacate from '../assets/bad-ingredients/aguacate.png';
import BadBici from '../assets/bad-ingredients/bici.png';
import BadGlobo from '../assets/bad-ingredients/globo.png';
import BadMaiz from '../assets/bad-ingredients/Maiz.png';
import BadMango from '../assets/bad-ingredients/Mango.png';
import BadManzana from '../assets/bad-ingredients/manzana.png';
import BadPatito from '../assets/bad-ingredients/patito.png';
import BadPelota from '../assets/bad-ingredients/pelota.png';
import BadReloj from '../assets/bad-ingredients/reloj.png';
import BadSandia from '../assets/bad-ingredients/sandia.png';

import RecipeBrownie from '../assets/recipes/main/brownies_web.png';
import RecipeCarlota from '../assets/recipes/main/carlota_web.png';
import RecipeCheese from '../assets/recipes/main/cheesecake_web.png';
import RecipeHotCake from '../assets/recipes/main/hotcakes_web.png';
import RecipeMalteada from '../assets/recipes/main/malteada_web.png';
import RecipePalomita from '../assets/recipes/main/palomitas_web.png';
import RecipePastel from '../assets/recipes/main/pastel_chocolate_web.png';
import RecipePay from '../assets/recipes/main/pay_creando_web.png';
import RecipeBolitas from '../assets/recipes/main/bolitas_creando_web.png';

import RecipeBrownieYum from '../assets/recipes/yum/brownies_yum_web.png';
import RecipeCarlotaYum from '../assets/recipes/yum/carlota_yum_web.png';
import RecipeCheeseYum from '../assets/recipes/yum/cheesecake_yum_web.png';
import RecipeHotCakeYum from '../assets/recipes/yum/hotcakes_yum_web.png';
import RecipeMalteadaYum from '../assets/recipes/yum/malteada_yum_web.png';
import RecipePalomitaYum from '../assets/recipes/yum/palomitas_yum_web.png';
import RecipePastelYum from '../assets/recipes/yum/pastel_chocolate_yum_web.png';
import RecipePayYum from '../assets/recipes/yum/pay_yum_web.png';
import RecipeBolitasYum from '../assets/recipes/yum/bolitas_yum_web.png';

import RecipeBrownieResult from '../assets/recipes/result/brownies_resultado_web.png';
import RecipeCarlotaResult from '../assets/recipes/result/carlota_resultado_web.png';
import RecipeCheeseResult from '../assets/recipes/result/cheesecake_resultado_web.png';
import RecipeHotCakeResult from '../assets/recipes/result/hotcakes_resultado_web.png';
import RecipeMalteadaResult from '../assets/recipes/result/malteada_resultado_web.png';
import RecipePalomitaResult from '../assets/recipes/result/palomitas_resultado_web.png';
import RecipePastelResult from '../assets/recipes/result/pastel_chocolate_resultado_web.png';
import RecipePayResult from '../assets/recipes/result/creampie_resultados_web.png';
import RecipeBolitasResult from '../assets/recipes/result/bolitas_oreo_resultado_web.png';

import backgroundMusic from '../assets/game/musica_fondo_20_segundos.mp3';
import okSound from '../assets/game/audio_ingrediente_correcto.mp3';
import errorSound from '../assets/game/audio_ingrediente_incorrecto.mp3';

import catchAnim1 from '../assets/catchAnimation/Ingrediente correcto_0001.png';
import catchAnim2 from '../assets/catchAnimation/Ingrediente correcto_0002.png';
import catchAnim3 from '../assets/catchAnimation/Ingrediente correcto_0003.png';
import catchAnim4 from '../assets/catchAnimation/Ingrediente correcto_0004.png';
import catchAnim5 from '../assets/catchAnimation/Ingrediente correcto_0005.png';
import catchAnim6 from '../assets/catchAnimation/Ingrediente correcto_0006.png';
import catchAnim7 from '../assets/catchAnimation/Ingrediente correcto_0007.png';
import catchAnim8 from '../assets/catchAnimation/Ingrediente correcto_0008.png';
import catchAnim9 from '../assets/catchAnimation/Ingrediente correcto_0009.png';
import catchAnim10 from '../assets/catchAnimation/Ingrediente correcto_0010.png';
import catchAnim11 from '../assets/catchAnimation/Ingrediente correcto_0011.png';
import catchAnim12 from '../assets/catchAnimation/Ingrediente correcto_0012.png';
import catchAnim13 from '../assets/catchAnimation/Ingrediente correcto_0013.png';
import catchAnim14 from '../assets/catchAnimation/Ingrediente correcto_0014.png';
import catchAnim15 from '../assets/catchAnimation/Ingrediente correcto_0015.png';
import catchAnim16 from '../assets/catchAnimation/Ingrediente correcto_0016.png';


export const bowlParts = [
    BowlBack,
    BowlFront
];

export const gamesDefinition = [
    {
        name: 'Brownies Oreo®',
        recipeImage: RecipeBrownie,
        resultImage: RecipeBrownieResult,
        recipeThumb:{
            web: RecipeBrownieYum,
        },
        ingredients: [
            {name: 'Harina', image: GoodHarina},
            {name: 'Oreo', image: GoodOreo},
            {name: 'Chocolate', image: GoodChocolate},
            {name: 'Huevo', image: GoodHuevo},
        ]
    },
    {
        name: 'Carlota de galleta Oreo®',
        recipeImage: RecipeCarlota,
        resultImage: RecipeCarlotaResult,
        recipeThumb:{
            web: RecipeCarlotaYum,
        },
        ingredients: [
            {name: 'Leche', image: GoodLeche},
            {name: 'Crema batida', image: GoodCrema},
            {name: 'Oreo', image: GoodOreo},
            {name: 'Philadelphia', image: GoodPhiladelphia},
        ]
    },
    {
        name: 'Cheesecake Oreo®',
        recipeImage: RecipeCheese,
        resultImage: RecipeCheeseResult,
        recipeThumb:{
            web: RecipeCheeseYum,
        },
        ingredients: [
            {name: 'Crema batida', image: GoodCrema},
            {name: 'Oreo', image: GoodOreo},
            {name: 'Philadelphia', image: GoodPhiladelphia},
            {name: 'Huevo', image: GoodHuevo},
        ]
    },
    {
        name: 'Bolitas de galleta Oreo®',
        recipeImage: RecipeBolitas,
        resultImage: RecipeBolitasResult,
        recipeThumb:{
            web: RecipeBolitasYum,
        },
        ingredients: [
            {name: 'Crema batida', image: GoodCrema},
            {name: 'Oreo', image: GoodOreo},
            {name: 'Chocolate Blanco', image: GoodChocoBlanco},
        ]
    },
    {
        name: 'Hot cakes Oreo®',
        recipeImage: RecipeHotCake,
        resultImage: RecipeHotCakeResult,
        recipeThumb:{
            web: RecipeHotCakeYum,
        },
        ingredients: [
            {name: 'Harina', image: GoodHarina},
            {name: 'Leche', image: GoodLeche},
            {name: 'Oreo', image: GoodOreo},
            {name: 'Huevo', image: GoodHuevo}
        ]
    },
    {
        name: 'Malteada Oreo®',
        recipeImage: RecipeMalteada,
        resultImage: RecipeMalteadaResult,
        recipeThumb:{
            web: RecipeMalteadaYum,
        },
        ingredients: [
            {name: 'Helado', image: GoodHelado},
            {name: 'Oreo', image: GoodOreo},
            {name: 'Leche', image: GoodLeche},
        ]
    },
    {
        name: 'Palomitas de Oreo®',
        recipeImage: RecipePalomita,
        resultImage: RecipePalomitaResult,
        recipeThumb:{
            web: RecipePalomitaYum,
        },
        ingredients: [
            {name: 'Palomitas', image: GoodPalomitas},
            {name: 'Oreo', image: GoodOreo},
            {name: 'Chocolate Blanco', image: GoodChocoBlanco},
        ]
    },
    {
        name: 'Pastel de chocolate Oreo®',
        recipeImage: RecipePastel,
        resultImage: RecipePastelResult,
        recipeThumb:{
            web: RecipePastelYum,
        },
        ingredients: [
            {name: 'Harina', image: GoodHarina},
            {name: 'Crema batida', image: GoodCrema},
            {name: 'Oreo', image: GoodOreo},
            {name: 'Chocolate', image: GoodChocolate},
            {name: 'Huevo', image: GoodHuevo}
        ]
    },
    {
        name: 'Pay de crema Oreo®',
        recipeImage: RecipePay,
        resultImage: RecipePayResult,
        recipeThumb:{
            web: RecipePayYum,
        },
        ingredients: [
            {name: 'Crema batida', image: GoodCrema},
            {name: 'Oreo', image: GoodOreo},
            {name: 'Philadelphia', image: GoodPhiladelphia},
            {name: 'Fresa', image: GoodFresa},
        ]
    },
];

export const badIngredients = [
    { name:'Aguacate', image: BadAguacate},
    { name:'Bici', image: BadBici},
    { name:'Globo', image: BadGlobo},
    { name:'Maíz', image: BadMaiz},
    { name:'Mango', image: BadMango},
    { name:'Manzana', image: BadManzana},
    { name:'Patito', image: BadPatito},
    { name:'Pelota', image: BadPelota},
    { name:'Reloj', image: BadReloj},
    { name:'Sandía', image: BadSandia},
];

export const soundEffects = {
    ok: okSound,
    error: errorSound,
    backgroundMusic,
}

export const catchAnimation = {
    width: 220,
    height: 142,
    fps: 40,
    frames: [
        catchAnim1,
        catchAnim2,
        catchAnim3,
        catchAnim4,
        catchAnim5,
        catchAnim6,
        catchAnim7,
        catchAnim8,
        catchAnim9,
        catchAnim10,
        catchAnim11,
        catchAnim12,
        catchAnim13,
        catchAnim14,
        catchAnim15,
        catchAnim16,
    ]
};
