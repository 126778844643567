import React, {useCallback} from 'react';
import './ShareScreen.scss';
import GeneralContainer from "../../../components/layout/GeneralContainer/GeneralContainer";
import {paths} from "../../../services/routes/appRoutes";
import BotLeft from "../../../assets/images/corners/pleca_izquierda_juego_web.png";
import BotRight from "../../../assets/images/corners/pleca_derecha_juego_web.png";
import Bottom from "../Game/assets/layout/pleca_juego_mobile.png";
import friends from './assets/iconos_amigos_web.png';
import {getNotifier} from "../../../services/notifier";
import {useSelector} from "react-redux";

const ShareScreen = () => {


    const me = useSelector( s=>s.api.me );
    const link = window.location.origin+'/share/' + me.shareCode;

    const share = useCallback(async ()=>{

        if(!navigator.share){
            await navigator.clipboard.writeText(link);
            getNotifier().success("Link copiado al portapapeles");
            return;
        }

        const shareData = {
            title: 'Factor Oreo',
            text: 'Únete y gana con Factor Oreo',
            url: link
        }
        try {
            await navigator.share(shareData);
        } catch (err) {
            console.log("No share");
        }
    },[link]);



    return (
        <GeneralContainer className={"ShareScreen"} backButtonRoute={paths.userHome} footer>

            <div className='share-container'>
                <div className='side icon-side'>
                    <img className='friends-icon' alt='Amigos' src={friends} />
                </div>
                <div className='side link-side'>
                    <p className='invitation-text mobile'>
                        ¡Invita a tus amigos a participar! Una vez que se valide el primer ticket de compra de tu amigo, se sumarán 20 puntos extra a tu perfil.
                    </p>
                    <h1 className='title'>LINK</h1>
                    <p className='link'>
                        <span className='mini-oreo' />&nbsp;{link}
                    </p><br/>
                    <button className='share-btn' onClick={share}>
                        COMPARTIR
                    </button>
                </div>
            </div>
            <p className='invitation-text web'>
                ¡Invita a tus amigos a participar! Una vez que se valide el primer ticket de compra de tu amigo, se sumarán 20 puntos extra a tu perfil.
            </p>

            <div className='game-footer'>
                <img alt={'corner'} className='corner web left' src={BotLeft}/>
                <img alt={'corner'} className='corner web right' src={BotRight}/>
                <img alt={'bottom'} className='bottom mobile' src={Bottom}/>
            </div>
        </GeneralContainer>
    );
};

export default ShareScreen;
