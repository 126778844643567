import moment from "moment";

export const productTicketsTableColumns = [
    {
        Header: 'ID',
        accessor: 'id',
    },
    {
        Header: 'Fecha del ticket',
        accessor: function (productTicket) {

            if (!productTicket.ticket.createdDate) {
                return 'Sin fecha';
            }

            return moment(productTicket.ticket.createdDate).format('DD/MM/YYYY HH:mm');
        },
    },
    {
        Header: 'Id de usuario',
        accessor: 'ticket.user.id',
    },
    {
        Header: 'Id de ticket',
        accessor: 'ticket.id',
    },
    {
        Header: 'Email',
        accessor: 'ticket.user.email',
    },
    {
        Header: 'Número de ticket',
        accessor: 'ticket.ticketNumber',
    },
    {
        Header: 'Producto',
        accessor: 'product.name',
    },
    {
        Header: 'Monto',
        accessor: 'amount',
    },
];

export const productTicketsListSGroups = [
    'ticket_product_read_id',
    'ticket_product_read_ticket',
        'ticket_read_id',
        'ticket_read_created_date',
        'ticket_read_ticket_number',
            'ticket_read_user',
            'user_read',
    'ticket_product_read_product',
        'product_read', 
    'ticket_product_read_amount'
];