import React from 'react'
import './GeneralContainer.scss';
import Menu from "../Menu/Menu";
import classNames from "classnames";
import BackButton from "../BackButton/BackButton";
import unicornImg from '../../../assets/images/corners/pleca_superior_unicornio.png';
import Footer from "../Footer/Footer";

const GeneralContainer = ({
                              className,
                              menu = true,
                              backButtonRoute = null,
                              hideBackOnMobile= false,
                              hideMenuOnMobile= false,
                              unicorn = false,
                              footer = false,
                              forceHamburger = false,
                              children
                          }) => {


    return (
        <div className={classNames('GeneralContainer', !!menu && 'with-menu', !!hideBackOnMobile && 'hide-back-on-mobile', !!hideMenuOnMobile && 'hide-menu-on-mobile', className)}>
            {!!menu && <Menu showMobile={!backButtonRoute} forceHamburger={forceHamburger} />}
            {!!backButtonRoute && <BackButton route={backButtonRoute}/>}
            {!!unicorn && <img className='unicorn' alt={'unicorn'} src={unicornImg}/>}
            {children}
            {!!footer && <Footer/>}
        </div>
    )
}

export default GeneralContainer;
