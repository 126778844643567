import React from 'react';
import './OreoButton.scss';
import classNames from "classnames";

const OreoButton = ({ children, className, rounded, ...props }) => {

    return (
        <button className={classNames("OreoButton", rounded&&'rounded', className)} {...props}>
            {children}
        </button>
    );
};

export default OreoButton;
