import React, {useCallback, useState} from 'react';
import './Home.scss';
import bannerParticipa from '../../../assets/images/home/Banner_home_web.png';
import doodleManos from '../../../assets/images/doodle_manos_mobile.png';
import doodleEmoji from '../../../assets/images/doodle_emoji_mobile.png';
import doodleOnda from '../../../assets/images/doodle_onda.png';
import cohete from '../../../assets/images/doddle_cohete.png';
import malteada from '../../../assets/images/home/imagen_malteada_home_web.png';
import malteadaMobile from '../../../assets/images/home/mobile/maleada_home_mobile.png'
import receta1 from '../../../assets/images/home/imagen_malteada_web.png';
import receta2 from '../../../assets/images/home/palomitas_oreo_web.png';
import receta3 from '../../../assets/images/home/imagen_hotcakes_web.png';
import receta4 from '../../../assets/images/home/imagen_carlota_web.png';
import footerMobile from '../../../assets/images/home/mobile/pleca_inferior_home_mobile.png';
import GeneralContainer from "../../../components/layout/GeneralContainer/GeneralContainer";
import {Link} from "react-router-dom";
import {paths} from "../../../services/routes/notLoggedRoutes";
import RecipePopup from "./components/RecipePopup";
import { Helmet } from 'react-helmet';
import { isPromotionFinishedDate } from '../../../services/promotionFinishUtils';
import classNames from 'classnames';
import PromotionFinishedModal from '../../../components/PromotionFinishedModal/PromotionFinishedModal';

const Home = () => {
    const [activeRecipe, setActiveRecipe] = useState(null);
    const [showPromotionFinishedModal, setShowPromotionFinishedModal] = useState(isPromotionFinishedDate());

    const setRecipe = useCallback((e) => {
        setActiveRecipe(e.currentTarget.dataset.recipe);
    }, [setActiveRecipe]);

    const closePopup = useCallback(() => {
       setActiveRecipe(null);
    }, [setActiveRecipe]);

    console.log( isPromotionFinishedDate() );

    return (
        <>
            <GeneralContainer className={classNames("Home", showPromotionFinishedModal?'pt-0':'')} footer >
                <Helmet>
                    <title>Factor Oreo</title>
                    <meta name="keywords" content="oreo, galletas oreo, comparte en familia, participa, juega con oreo, gana con oreo, recetas con galletas oreo, familia, suma con factor oreo, producto oreo" data-react-helmet="true" />
                    <meta name="description" content="Compra Oreo, regístrate y juega para ganar premios" data-react-helmet="true" />
                </Helmet>

                <h1 className='hidden'>Suma más con Factor Oreo</h1>
                
                <div className='main-column left'>
                    <img className='malteada-mobile' src={malteadaMobile} alt=""/>
                    <img src={cohete} alt={'cohete'} className='cohete'/>
                    <div className='main-content'>
                        <div className='video-container'>
                            {!isPromotionFinishedDate() &&
                                <iframe className={'video-iframe'}
                                    src="https://www.youtube.com/embed/tjMhsBx-CHE?rel=0&showinfo=0&loop=1&playlist=tjMhsBx-CHE&controls=1"
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen/>}
                        </div>
                        <div className='promotion'>
                            <Link to={paths.loginOrRegister}>
                                <img src={bannerParticipa} alt={"Banner Participa"} className='banner'/>
                            </Link>
                        </div>
                        <div className='recipes'>
                            <div className='recipes-header'>
                                <img src={doodleOnda} alt='onda' className='doodle-onda left'/>
                                <h2 className='recipe-title'>
                                    <img src={doodleEmoji} alt={'emoji'} className='emoji left'/>
                                    SUMA FACTOR OREO®
                                    <img src={doodleManos} alt={'emoji'} className='emoji right'/>
                                </h2>
                                <img src={doodleOnda} alt='onda' className='doodle-onda right'/>
                            </div>
                            <div className='recipe-list'>
                                <button className='recipe-item' onClick={setRecipe} data-recipe='recipe1'>
                                    <img src={receta1} alt='Receta 1' className='recipe-img'/>
                                </button>
                                <button className='recipe-item' onClick={setRecipe} data-recipe='recipe2'>
                                    <img src={receta2} alt='Receta 2' className='recipe-img'/>
                                </button>
                                <button className='recipe-item' onClick={setRecipe} data-recipe='recipe3'>
                                    <img src={receta3} alt='Receta 3' className='recipe-img'/>
                                </button>
                                <button className='recipe-item' onClick={setRecipe}  data-recipe='recipe4'>
                                    <img src={receta4} alt='Receta 4' className='recipe-img'/>
                                </button>
                            </div>
                        </div>
                    </div>
                    <img className='footer-mobile' src={footerMobile} alt=""/>
                </div>
                <div className='main-column right'>
                    <img src={malteada} alt={'malteada'} className='milkshake'/>
                </div>
                {activeRecipe &&
                    <RecipePopup onClose={closePopup} recipe={activeRecipe}/>
                }
            </GeneralContainer>

            {showPromotionFinishedModal && <PromotionFinishedModal onClose={() => setShowPromotionFinishedModal(false)}/>}
        </>
    );
};

export default Home;
