import React, {useContext, useEffect} from 'react';
import GeneralContainer from "../../../components/layout/GeneralContainer/GeneralContainer";
import {ApiContext} from "../../../services/api/api-config";

const Logout = () => {

    const api = useContext(ApiContext);

    useEffect(()=>{
        api.logout();
    },[api]);

    return (
        <GeneralContainer className={"Logout"}>
            Cerrando sesión...
        </GeneralContainer>
    );
};

export default Logout;
