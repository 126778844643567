import "./BackButton.scss"
import backButtonImage from "../../../assets/images/buttons/boton_regresar_web.png"
import {Link} from "react-router-dom";

const BackButton = ({ route }) => {

    return (
        <Link to={route}>
            <button className="BackButton">
                <img src={backButtonImage} alt=""/>
            </button>
        </Link>
    );
}

export default BackButton;
