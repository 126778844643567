import React from 'react';
import pckg from '../../../pckg.json';
import './Footer.scss';
import logo from './assets/logo_mondelez.png';
import {Link} from "react-router-dom";
import {paths} from "../../../services/routes/notLoggedRoutes";

const Footer = () => {

    return (
        <div className={"Footer"}>
            <div className='footer-container'>
                <div className='logo-container'>
                    <img className='logo-mdlz' alt="Mondelez" src={logo}/>
                    <span className='copyright'>Ⓒ2022 Mondelez México. All Rights Reserved</span>
                </div>
                <div className='footer-menu'>
                    <Link to={paths.contact} className={'menu-item'}>
                        Contacto
                    </Link>
                    <Link to={paths.termsAndConditions} className={'menu-item'}>
                        Términos y condiciones
                    </Link>
                    <Link to={paths.terms} className={'menu-item'}>
                        Términos de uso
                    </Link>
                    <Link to={paths.privacy} className={'menu-item'}>
                        Aviso de privacidad
                    </Link>
                </div>
                <span className={'version'}>{pckg.version}</span>
            </div>
        </div>
    );
};

export default Footer;
