import { copyPropertyOrThrow } from "../formUtils";
import {emailIsValid} from "../validations";

export const prepareContactForm = ( form )=>{
    let contactForm = {}

    copyPropertyOrThrow( form, contactForm, "name", "Por favor llena el campo \"nombre\"");
    copyPropertyOrThrow( form, contactForm, "phone", "Por favor llena el campo \"teléfono\" " );
    copyPropertyOrThrow( form, contactForm, "email", "Por favor llena el campo \"email\"");
    copyPropertyOrThrow( form, contactForm, "message","Por favor llena el campo \"mensaje\"");

    contactForm.email = contactForm.email.trim();
    if(!emailIsValid(contactForm.email)){
        throw new Error('Por favor ingresa un correo válido');
    }

    return contactForm;
}

