import React from 'react';
import './Menu.scss';
import menuLogo from '../../../assets/images/logo_factor_oreo.png';
import doodleEstrella from '../../../assets/images/doodle_estrella.png';
import {Link} from "react-router-dom";
import {paths} from "../../../services/routes/notLoggedRoutes";
import {paths as loggedPaths} from "../../../services/routes/appRoutes";
import mobileToggleButtonImage from '../../../assets/images/buttons/Boton-menu-mobile.png';
import useBoolean from "../../../hooks/useBoolean";
import {useSelector} from "react-redux";
import classNames from "classnames";
import rocketImage from '../../../assets/images/corners/cohete_menu_web.png';
import rocketImageMobile from '../../../assets/images/corners/cohete_mobile.png';

const Menu = ({ showMobile=true, forceHamburger=false }) => {

    const me = useSelector(s=>s.api.me);
    const [menuPopupIsOpen, openPopupMenu, closePopupMenu,] = useBoolean(false);

    return (
        <div className={classNames('Menu', forceHamburger&&'force-hamburger')}>
            <Link to={paths.home}>
                <img src={menuLogo} alt={"Logo Factor Oreo"} className='menu-logo'/>
            </Link>
            <div className="desktop-menu">
                <Link className={'menu-item'} to={paths.home}>{me ? 'Mi perfil' : 'Inicio'}</Link>
                <Link className={'menu-item'} to={paths.recipes}>Recetas</Link>
                <Link className={'menu-item'} to={paths.loginOrRegister}>Promoción</Link>
                <Link className={'menu-item'} to={paths.dynamic}>Dinámica</Link>
                <Link className={'menu-item'} to={paths.prizes}>Premios</Link>
                <Link className={'menu-item'} to={paths.contact}>Contacto</Link>
                <img src={doodleEstrella} alt={"doodleEstrella"} className='menu-final'/>
            </div>
            {showMobile && <div className="mobile-toggle" onClick={openPopupMenu}>
                <img src={mobileToggleButtonImage} alt=""/>
            </div>}
            {menuPopupIsOpen &&
                <div className="popup-menu-container">
                    <div className="menu-options">
                        <div className="close" onClick={closePopupMenu}>X</div>
                        <Link className={'menu-item'} to={paths.home}>{me ? 'MI PERFIL' : 'INICIO'}</Link>
                        <Link className={'menu-item'} to={paths.recipes}>RECETAS</Link>
                        <Link className={'menu-item'} to={paths.loginOrRegister}>PROMOCIÓN</Link>
                        <Link className={'menu-item'} to={paths.dynamic}>DINÁMICA</Link>
                        <Link className={'menu-item'} to={paths.prizes}>PREMIOS</Link>
                        <Link className={'menu-item'} to={paths.contact}>CONTACTO</Link>
                        {me && <Link className={'menu-item'} to={loggedPaths.ticketUpload}>SUBIR TICKET</Link>}
                        {me && <Link className={'menu-item'} to={loggedPaths.ranking}>RANKING</Link>}
                        <Link className={'menu-item'} to={paths.terms}>TÉRMINOS DE USO</Link>
                        <Link className={'menu-item'} to={paths.termsAndConditions}>TÉRMINOS Y CONDICIONES</Link>
                        {!!me && <Link className={'menu-item'} to={loggedPaths.logout}>CERRAR SESIÓN</Link>}
                        <div className="roket">
                            <img src="" alt=""/>
                        </div>

                        <img src={rocketImage} className="menu-options__rocket-web" alt="Rocket" />
                        <img src={rocketImageMobile} className="menu-options__rocket-mobile" alt="Rocket" />
                    </div>
                </div>
            }
        </div>
    );
};

export default Menu;
