import React, {useContext, useCallback} from 'react'
import {ApiContext} from "../../../services/api/api-config";
import useFormState from "../../../hooks/useFormState";
import GeneralContainer from '../../../components/layout/GeneralContainer/GeneralContainer';
import TextInput from '../../../components/forms/TextInput/TextInput';
import {getNotifier} from '../../../services/notifier';
import popcorns from '../../../assets/images/forgot-pass/palomitas_recuperar_contraseña_web.png';
import footerMobile from '../../../assets/images/forgot-pass/mobile/pleca_correo_mobile.png';
import './ForgotPass.scss';
import {emailIsValid} from "../../../services/validations";
import {useNavigate} from "react-router-dom";
import {paths} from "../../../services/routes/notLoggedRoutes";

const ForgotPass = () => {
    const api = useContext(ApiContext);

    const {form, handleSimpleChange} = useFormState({});
    const navigate = useNavigate();

    const recoverPassword = useCallback((e) => {
        e.preventDefault();
        if (!form.email) {
            getNotifier().error("Por favor ingrese el correo electrónico.");
            return;
        }
        if(!emailIsValid(form.email.trim())){
            getNotifier().error("Por favor ingrese un correo válido.");
            return;
        }

        api.users.recoverPassword(form.email.trim()).then(()=>{
            navigate(paths.emailSent);
        }).catch(()=>{
            getNotifier().error("Ha ocurrido un error al intentar recuperar la contraseña.");
        });
    }, [api, form, navigate]);
    //const loading = useSelector(({loadingIds}) => loadingIds[LOGIN_LOADING_ID] || loadingIds['Login.me']);



    return (
        <GeneralContainer className='GeneralContainer--display-block ForgotPass' backButton={true} hideBackOnMobile={true}>

                <div className={"left-column"}>
                    <img className='popcorns-image' src={popcorns} alt=""/>
                </div>
                <div className={"right-column"}>
                    <form onSubmit={recoverPassword}>
                        <h1 className='ForgotPass__title'>RECUPERAR CONTRASEÑA</h1>
                        <div className='input-block'>
                            <TextInput
                                placeholder="CORREO"
                                label="CORREO"
                                onChange={handleSimpleChange('email')}
                                value={form.email|| ''}
                            />
                        </div>

                        <button
                            className='oreo-button login-button'
                            type='submit'
                        >
                            ENVIAR
                        </button>
                    </form>
                </div>

            <div className='clearfix'></div>

            <img className='footer-mobile' src={footerMobile} alt=""/>

        </GeneralContainer>
    );
}

export default ForgotPass;
