import React, {useMemo, useState, useCallback, useContext} from 'react'
import GeneralContainer from '../../../components/layout/GeneralContainer/GeneralContainer';
import './UsersList.scss';
import TideReactTable from "../../../components/TideReactTable/TideReactTable";
import Menu from "../Menu/Menu";
import useTideTable from "../../../hooks/useTideTable";
import {userTableColumns, usersListSGroups, userDetailsSGroups} from "../../../services/modelUtils/userUtils";
import UserDetail from '../components/UserDetail/UserDetail';
import { ApiContext } from '../../../services/api/api-config';

const UsersList = () => {
    const api = useContext(ApiContext);
    const [selectedUser, setSelectedUser] = useState();
    const requestFilters = useMemo(() => ({
        sGroups: usersListSGroups
    }), []);

    const tideTable = useTideTable({entity: 'users', columns: userTableColumns, requestFilters});

    const closeUser = useCallback(() => {
        setSelectedUser(null);
    }, [setSelectedUser]);

    const handleExportUsersToExcel = useCallback(() => {
        window.location.href = api.users.getExportExcelUrl({pagination: false, sGroups: userDetailsSGroups});
    }, [api]);

    return (
        <GeneralContainer className='GeneralContainer--display-block UsersList' backButton={true} hideBackOnMobile={true} menu={false}>
            <Menu/>
            <h1 className='center'>Usuarios</h1>

            <div className='table-container'>
                <button className='btn btn-export' onClick={handleExportUsersToExcel}>Exportar a Excel</button>

                <TideReactTable {...tideTable.tableProps} onRowClick={setSelectedUser} />
            </div>

            {selectedUser &&
                <UserDetail user={selectedUser} onClose={closeUser}/>}
        </GeneralContainer>

    );
}

export default UsersList;
