import React, {useContext, useEffect, useLayoutEffect} from 'react';
import './GameOver.scss';
import OreoButton from "../../../../../components/layout/OreoButton/OreoButton";
import confetti from 'canvas-confetti';
import {pointsToOreos} from "../../controller/gameUtils";
import classNames from "classnames";
import {ApiContext} from "../../../../../services/api/api-config";
import {paths} from "../../../../../services/routes/appRoutes";
import {Link} from "react-router-dom";
import logoWalmart from './assets/logo_walmart.png';
import logoRappi from './assets/logo_Rappi.png';
import logoCorner from './assets/logo_cornershop.png';
import {useSelector} from "react-redux";


const GameOver = ({ game, result, onPlayAgain }) => {

    const api = useContext(ApiContext);
    const me = useSelector(s=>s.api.me);

    useEffect(()=>{

        api.games.create({ params: result })
            .then( ()=>api.me.get() )
            .catch( err=>{
                const msg = err.detail || err.message || 'Error al guardar el juego';
                alert(msg);
            });
    },[api, result]);

    const confettiCanvasRef = React.useRef(null);
    useLayoutEffect(()=>{
        const confettor = confetti.create(confettiCanvasRef.current, {resize: true});
        confettor({
            particleCount: 150,
            spread: 180,
            angle: 90,
            gravity: 0.8,
            drift: 1.6,
            decay: 0.9,
            scalar: 2,
        });
    },[]);

    const oreoScore = pointsToOreos(result.points);

    return (
        <div className={"GameOver"}>

            <canvas className='confetti' ref={confettiCanvasRef} />

            <div className='center'>
                <div className='recipe-container'>
                    <div className='recipe-layout'>
                    <img alt={game.name} className='recipe-image' src={game.resultImage} />
                    </div>
                </div>
                <div className='result-container'>
                    <div className='cookies-container'>
                        <div className={classNames('cookie first', oreoScore>=1&&'active')} />
                        <div className={classNames('cookie second', oreoScore>=2&&'active')} />
                        <div className={classNames('cookie third', oreoScore>=3&&'active')} />
                    </div>
                    <p className='congrats'>FELICIDADES</p>
                    <p className='points'>{result.points} OREO® PUNTOS</p>
                    <div className='btn-container'>
                        <OreoButton className='btn first' rounded disabled={me.numOfLives <= 0} onClick={onPlayAgain}>
                            JUGAR DE NUEVO
                        </OreoButton><br/>
                        <Link to={paths.userHome}>
                            <OreoButton className='btn' rounded>VOLVER AL INICIO</OreoButton>
                        </Link>
                    </div>
                </div>

                <div className='buy-block'>
                    <p className='buy-text'>Compra Oreo® en:</p>
                    <div className='buy-line' />
                    <div className='buy-links'>
                        <a href='https://super.walmart.com.mx/productos?Ntt=oreo' target='_blank' rel="noreferrer">
                            <img className='buy-img' alt='Walmart' src={logoWalmart} />
                        </a>
                        <a href='https://rappi.app.link/U2RZ9Tv4Yrb' target='_blank' rel="noreferrer">
                            <img className='buy-img' alt='Rappi' src={logoRappi} />
                        </a>
                        <a href='https://cornershop.onelink.me/2599719182/wi887icp' target='_blank' rel="noreferrer">
                            <img className='buy-img' alt='CornerShop' src={logoCorner} />
                        </a>
                    </div>
                    <div className='buy-line' />
                </div>

            </div>

        </div>
    );
};

export default GameOver;
