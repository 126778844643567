import React, {useEffect} from 'react';
import './Instructions.scss';
import {getUserAvatar} from "../../../../../services/avatarUtils";
import classNames from "classnames";
import bowlImg from '../../assets/instructions/bowl_instrucciones_web.png';
import cursorImg from '../../assets/instructions/puntero_instrucciones_web.png';
import arrowImg from '../../assets/instructions/indicador_instrucciones_web.png';
import {isMobile} from "../../../../../services/layoutUtils";
import {useSelector} from "react-redux";

const startTime = 15;
const changeStepTimeWeb = 10;
const changeStepTimeMobile = 5;

const Instructions = ({ game, onContinue }) => {

    const me = useSelector(s=>s.api.me);
    const avatar = getUserAvatar(me);
    const [time, setTime] = React.useState(startTime);
    const [step, setStep] = React.useState(0);

    useEffect(() => {
        const changeStepTime = isMobile() ? changeStepTimeMobile : changeStepTimeWeb;
        const intervalId = setInterval(() => {
            setTime(time => {
                const stepTime = (startTime - time)%changeStepTime;
                if (time <= 0) {
                    onContinue();
                    return 0;
                }
                if ( stepTime >= (changeStepTime-1) ){
                    //On web, we go from step 0 to step 2, on mobile we go from step 0 to step 1 to step 2
                    setStep(step =>{
                        if(step===0){
                            return isMobile()?1:2
                        }
                        return 2;
                    });
                }
                return time - 1;
            });
        }, 1000);
        return () => clearInterval(intervalId);
    }, [onContinue]);

    const mobile = isMobile();
    const showStep0 = step===0;
    const showStep1 = (step===0 && !mobile) || step===1;
    const showStep2 = step===2;

    return (
        <div className={classNames("Instructions", `actualStep${step}`)}>
            <img alt={"Avatar"} className={'avatar'} src={avatar.small} />
            <div className='start-countdown'>
                <p>Comenzando</p>
                <p><span>el juego en:</span> <span className='time'>{time} seg</span></p>
            </div>
            <div className={'game-frame'}>
                <div className={'instructions-content'}>
                    {showStep0 &&
                        <div className={'recipe-image-cont step step0'}>
                            <img alt={'oreo'} className='recipe-image' src={game.recipeImage}/>
                            <p className='recipe-name'>
                                <span>Creando:</span><br/>
                                <span>{game.name}</span>
                            </p>
                        </div>}
                    {showStep1 &&
                        <div className='recipe-desc step step1'>
                            <p className='title'>
                                <span>ATRAPA CON EL BOWL LOS</span> <br/>
                                <span className='big'>INGREDIENTES:</span>
                            </p>
                            <div className='ingredients'>
                                {game.ingredients.map((ingredient, index) => (
                                    <img key={ingredient.name} alt={ingredient.name} className='ingredient-image'
                                         src={ingredient.image}/>
                                ))}
                            </div>
                            <div className='separator'/>
                            <p className='final-desc'>
                                EVITA OTROS INGREDIENTES<br/>
                                Y OBJETOS O PERDERÁS PUNTOS
                            </p>
                            <div className='bad-ingredients'>
                                {game.badIngredients.map((ingredient, index) => (
                                    <img key={ingredient.name} alt={ingredient.name} className='ingredient-image bad'
                                         src={ingredient.image}/>
                                ))}
                            </div>
                        </div>}
                    {showStep2 && <div className='controls step step2'>
                        <div className='control-animation'>
                            <div className='cursor-block'>
                                <img className='arrow left' src={arrowImg} alt='<'/>
                                <img className='cursor' src={cursorImg} alt='cursor'/>
                                <img className='arrow right' src={arrowImg} alt='>'/>
                            </div>
                            <img className='bowl' src={bowlImg} alt='bowl'/>
                        </div>
                        <p className='control-desc web'>
                            Atrapa los ingredientes correctos con el bowl moviendo el puntero del ratón de
                            izquierda a derecha dentro del área del tablero.
                        </p>
                        <p className='control-desc mobile'>
                            Atrapa los ingredientes correctos con el bowl moviéndolo con tu dedo de
                            izquierda a derecha dentro del área del tablero.
                        </p>
                    </div>}
                </div>
            </div>
        </div>
    );
};

export default Instructions;
