import React, {useCallback, useState} from 'react';
import './Recipes.scss';
import GeneralContainer from "../../../components/layout/GeneralContainer/GeneralContainer";
import doodleEmoji from "../../../assets/images/doodle_emoji_mobile.png";
import doodleManos from "../../../assets/images/doodle_manos_mobile.png";
import receta1 from "../../../assets/images/home/imagen_malteada_web.png";
import receta2 from "../../../assets/images/home/palomitas_oreo_web.png";
import receta3 from "../../../assets/images/home/imagen_hotcakes_web.png";
import receta4 from "../../../assets/images/home/imagen_carlota_web.png";
import RecipePopup from "../Home/components/RecipePopup";
import leftCorner from "../../../assets/images/corners/pleca_inferior_izquierda_perfil_web.png";
import rightCorner from "../../../assets/images/corners/pleca_derecha_avatar_web.png";
import footerMobile from "../../../assets/images/corners/pleca_mobile_galleta_malteada.png";
import { Helmet } from "react-helmet";

const Recipes = () => {


    const [activeRecipe, setActiveRecipe] = useState(null);

    const setRecipe = useCallback((e) => {
        setActiveRecipe(e.currentTarget.dataset.recipe);
    }, [setActiveRecipe]);

    const closePopup = useCallback(() => {
        setActiveRecipe(null);
    }, [setActiveRecipe]);

    return (
        <GeneralContainer className={"Recipes"} footer>
            <Helmet>
                <title>Recetas | Factor Oreo</title>
                <meta name="keywords" content="oreo, recetas con galletas oreo, prepara postres con oreo, cocina con galletas oreo, malteada de oreo, palomitas de oreo, hotcakes de oreo, carlota de galleta oreo, galletas oreo, producto oreo, comparte en familia" />
            </Helmet>

            <div className='recipes-container'>
                <div className='recipes-header'>
                    <h1 className='recipe-title'>
                        <img src={doodleEmoji} alt={'emoji'} className='emoji left'/>
                        SUMA FACTOR OREO®
                        <img src={doodleManos} alt={'emoji'} className='emoji right'/>
                    </h1>
                </div>
                <div className='recipe-list'>
                    <button className='recipe-item' onClick={setRecipe} data-recipe='recipe1'>
                        <img src={receta1} alt='Malteada de Oreo' className='recipe-img'/>
                    </button>
                    <button className='recipe-item' onClick={setRecipe} data-recipe='recipe2'>
                        <img src={receta2} alt='Palomitas de Oreo' className='recipe-img'/>
                    </button>
                    <button className='recipe-item' onClick={setRecipe} data-recipe='recipe3'>
                        <img src={receta3} alt='Hotcakes de Oreo' className='recipe-img'/>
                    </button>
                    <button className='recipe-item' onClick={setRecipe}  data-recipe='recipe4'>
                        <img src={receta4} alt='Carlota de galleta Oreo' className='recipe-img'/>
                    </button>
                </div>
            </div>

            {activeRecipe &&
                <RecipePopup onClose={closePopup} recipe={activeRecipe}/>
            }

            <div className='footer'>
                <img src={leftCorner} className={'left corner web'} alt={'corner'} />
                <img src={rightCorner} className={'right corner web'} alt={'corner'}/>
                <img src={footerMobile} className={'corner mobile'} alt={'corner'}/>
            </div>

        </GeneralContainer>
    );
};

export default Recipes;
