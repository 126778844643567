import classNames from "classnames";
import {Link, useLocation} from "react-router-dom";
import {paths} from "../../../services/routes/adminRoutes";
import './Menu.scss'
import pckg from "../../../pckg.json";

const Menu = () => {
    const location = useLocation();

    const isActiveRoute = (path) => {
        if (!path) return '';
        const pathToRegex = path.replace(/\/:[^/]*/g, "[^/]*") + "($|/)";
        const regex = new RegExp(pathToRegex);
        return location.pathname.match(regex) ? 'active' : '';
    };

    return (
        <div className='AdminMenu'>
            <Link className={classNames('menu-item', isActiveRoute(paths.tickets))} to={paths.tickets}>Tickets</Link>
            <Link className={classNames('menu-item', isActiveRoute(paths.users))} to={paths.users}>Usuarios</Link>
            <Link className={classNames('menu-item', isActiveRoute(paths.games))} to={paths.games}>Juegos</Link>
            <Link className={classNames('menu-item', isActiveRoute(paths.ticketProducts))} to={paths.ticketProducts}>Productos</Link>
            <Link className='menu-item' to={paths.logout}>Cerrar sesión</Link>
            <span className={'version'}>{pckg.version}</span>
        </div>
    )
}

export default Menu;
