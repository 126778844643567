import React from 'react'
import GeneralContainer from '../../../components/layout/GeneralContainer/GeneralContainer';
import {paths} from "../../../services/routes/appRoutes";
import paqueteGalletas from '../../../assets/images/dynamic/paquete_galletas_web.png';
import paqueteGalletasMobile from '../../../assets/images/dynamic/mobile/dinamica_mobile.png';
import buyImage from '../../../assets/images/dynamic/caja_oreo_dinamica_web.png';
import ticketImage from '../../../assets/images/dynamic/ticket_dinamica_web.png';
import playImage from '../../../assets/images/dynamic/play_dinamica_web.png';
import backButtonImage from "../../../assets/images/buttons/boton_regresar_web.png"
import { Link } from 'react-router-dom';
import './Dynamic.scss';
import { Helmet } from 'react-helmet';

const Dynamic = () => {
    return (
        <GeneralContainer 
            className={"Dynamic"}
            unicorn
            backButtonRoute={paths.userHome}
        >
            <Helmet>
                <title>Dinámica | Factor Oreo</title>
                <meta name="keywords" content="oreo, compra producto oreo, registra tickets de compra, juega con oreo, gana con oreo, producto oreo, galletas oreo, suma con factor oreo" />
            </Helmet>

            <div className='main-column left'>
                <img src={paqueteGalletasMobile} alt={'Paquete de galletas'} className='Dynamic__galletas-mobile'/>

                <h1 className='title'>
                    <span className='up'>DINÁMICA</span>
                </h1>

                <div className="Dynamic__process">
                    <div className='process_img'>
                        <img src={buyImage} className="buy-image" alt="Caja de galletas" />
                    </div>
                    <div className='process_img'>
                        <img src={ticketImage} className="buy-image" alt="Ticket" />
                    </div>
                    <div className='process_img'>
                        <img src={playImage} className="buy-image" alt="Play" />
                    </div>

                    <div className='Dynamic__process__title process_title'>
                        COMPRA
                    </div>
                    <div className='Dynamic__process__title process_title'>
                        REGISTRA
                    </div>
                    <div className='Dynamic__process__title process_title'>
                        ¡Y JUEGA<br/>
                        PARA GANAR!
                    </div>
                </div>

                <div className='Dynamic__terms'>
                    NO OLVIDES VISITAR NUESTROS <Link to={paths.termsAndConditions}>TÉRMINOS Y CONDICIONES.</Link>
                </div>
            
                <div className='Dynamic__footer'>
                    <Link to={paths.userHome}>
                        <button className="Dynamic__home-button">
                            <img src={backButtonImage} alt=""/>
                        </button>
                    </Link>
                </div>
            </div>

            <div className='main-column right'>
                <img src={paqueteGalletas} alt={'Paquete de galletas'} className='Dynamic__galletas'/>
            </div>
        </GeneralContainer>
    );
}

export default Dynamic