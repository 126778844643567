import React, { useCallback} from 'react'
import GeneralContainer from '../../../components/layout/GeneralContainer/GeneralContainer';
import brownie from '../../../assets/images/email-sent/brownies_correo_web.png';
import footerLeftImage from '../../../assets/images/email-sent/pleca_izquierda_correo_web.png';
import footerRightImage from '../../../assets/images/email-sent/pleca-derecha_recuperar_web.png';
import footerMobile from '../../../assets/images/email-sent/mobile/pleca_correo_mobile.png';
import './EmailSent.scss';
import {useNavigate} from "react-router-dom";
import {paths} from "../../../services/routes/notLoggedRoutes";

const EmailSent = () => {
    const navigate = useNavigate();

    const goHome = useCallback(()=>{
        navigate(paths.home) ;
    }, [navigate])

    return (
        <GeneralContainer className='GeneralContainer--display-block EmailSent' backButton={true} hideBackOnMobile={true}>

            <div className={"left-column"}>
                <img className='brownie-image' src={brownie} alt=""/>
            </div>
            <div className={"right-column"}>
                <div className='sent-email-message'>Correo enviado para recuperar contraseña</div>

                <button className='oreo-button login-button' onClick={goHome}>Regresar</button>
            </div>

            <div className='clearfix'></div>

            <img className='footer-left-image' src={footerLeftImage} alt=""/>
            <img className='footer-right-image' src={footerRightImage} alt=""/>
            <img className='footer-mobile' src={footerMobile} alt=""/>

        </GeneralContainer>
    );
}

export default EmailSent;
