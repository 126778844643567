import React, {useContext, useEffect} from 'react';
import './TicketHistory.scss';
import GeneralContainer from "../../../components/layout/GeneralContainer/GeneralContainer";
import {paths} from "../../../services/routes/appRoutes";
import leftCorner from "../../../assets/images/corners/pleca_izquierda_ticket_web.png";
import rightCorner from "../../../assets/images/corners/pleca_derecha_ticket_web.png";
import footerMobile from "../../../assets/images/corners/pleca_inferior_ticket_mobile.png";
import historyIcon from './assets/icono_historial_ticket_web.png';
import oreo from '../../../assets/images/galleta_oreo_mini.png';
import {ApiContext} from "../../../services/api/api-config";
import moment from 'moment';
import {ticketStatus, ticketStatusTrans} from "../../../services/modelUtils/ticketUtils";
import { useState } from 'react';

const customProp = 'tickets_history';

const TicketHistory = () => {
    const api = useContext(ApiContext);
    const [tickets, setTickets] = useState();

    useEffect(()=>{
        api.tickets.get({ params:{pagination: false, 'order[createdDate]':'DESC '}, customProp })
            .then(setTickets)
    },[api]);
    
    return (
        <GeneralContainer className={"TicketHistory"} unicorn backButtonRoute={paths.userHome} footer>

            <div className='history-container'>
                <div className='side icon-side'>
                    <img className='history-icon' alt='Folder' src={historyIcon} />
                    <h1>
                        Historial<br/>
                        de tickets
                    </h1>
                </div>
                <div className='side table-side'>
                    <div className='table-frame'>
                        <div className='table'>
                            <div className='table-header'>
                                <span className='th'>TICKET</span>
                                <span className='th'>STATUS</span>
                                <span className='th'>VIDAS</span>
                            </div>
                            <div className='table-body'>
                                {!tickets?.length && <p className='empty-text'>Aún no has subido ningún ticket</p>}

                                {tickets?.map((ticket, i) => (
                                    <div className='table-row' key={ticket.id}>
                                    <span className='td'>
                                        <img className='mini-oreo' alt='Oreo' src={oreo}/>
                                        {ticket.id}. {moment(ticket.createdDate).format('DD/MM/YYYY')}
                                    </span>
                                        <span className='td'>{ticketStatusTrans(ticket.status)}</span>
                                        <span className='td'>{ticket.status===ticketStatus.STATUS_REJECTED ? 0 : ticket.numOfLives}</span>
                                    </div>
                                ))}

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='footer'>
                <img src={leftCorner} className={'left corner web'} alt={'corner'} />
                <img src={rightCorner} className={'right corner web'} alt={'corner'}/>
                <img src={footerMobile} className={'corner mobile'} alt={'corner'}/>
            </div>

        </GeneralContainer>
    );
};

export default TicketHistory;
