import React, {useContext, useEffect, useState} from 'react';
import './ProductSelectionModal.scss';
import {createPortal} from "react-dom";
import {ApiContext} from "../../../../../services/api/api-config";
import {useSelector} from "react-redux";
import OreoButton from "../../../../../components/layout/OreoButton/OreoButton";
import _ from 'lodash';
import plusIcon from '../../../../../assets/images/galleta_oreo_perfil_web.png';
import greenPlusIcon from '../../assets/boton_plus.png';
import redLessIcon from '../../assets/boton_less.png';
import classNames from "classnames";

const ProductSelectionModal = ({onClose, onSelect, interactive = true}) => {

    // Load products
    const api = useContext(ApiContext);
    useEffect(() => {
        api.products.get({params: {pagination: false}});
    }, [api]);
    const productList = useSelector(s => s.api.products) || [];

    const [selectedProduct, setSelectedProduct] = useState(null);

    //Product selection
    const [selectedProducts, setSelectedProducts] = useState([]);
    const handleProductSelection = (product) => {
        if (!interactive) {
            return;
        }
        addProduct(product);
        setSelectedProduct(product);
    };

    const addProduct = (product) => {
        const selected = _.findIndex(selectedProducts, sp => sp.product.id === product.id);
        let newSelectedProducts = [...selectedProducts];
        let newSelected = {product, amount: 1};
        if (selected > -1) {
            newSelected.amount = selectedProducts[selected].amount + 1;
            newSelectedProducts[selected] = newSelected;
        } else {
            newSelectedProducts.push(newSelected);
        }
        setSelectedProducts(newSelectedProducts);
    }

    const removeProduct = (product) => {
        const selected = _.findIndex(selectedProducts, sp => sp.product.id === product.id);
        let newSelectedProducts = [...selectedProducts];
        let newSelected = {product};
        if (selected > -1) {
            if (selectedProducts[selected].amount >= 1) {
                const newAmount = selectedProducts[selected].amount - 1;
                if(newAmount>0){
                    newSelected.amount = newAmount;
                    newSelectedProducts[selected] = newSelected;
                }else{
                    newSelectedProducts.splice(selected, 1);
                }
            }
        }
        setSelectedProducts(newSelectedProducts);
    }


    const getSelectedProduct = (product) => {
        const selected = _.findIndex(selectedProducts, sp => sp.product.id === product.id);
        if (selected > -1) {
            return selectedProducts[selected];
        }
        return null;
    }

    const handleOkButton = () => {
        if (!interactive) {
            onClose();
        } else {
            onSelect(selectedProducts)
        }
    }

    return createPortal(
        <div className={"ProductSelectionModal"}>
            <div className='overlay' onClick={onClose}/>
            <div className='content'>
                <p className='title'>{interactive ?
                    "Selecciona los productos que compraste" :
                    "Productos participantes"
                }</p>
                <div className='product-list'>
                    {_.chunk(productList, Math.ceil(productList.length / 2)).map((column, index) => (
                        <div className='product-col' key={index}>
                            {column.map((product) => {
                                const selected = getSelectedProduct(product);
                                return (
                                    <React.Fragment key={product.id}>
                                        <div
                                            className={classNames('product', (selectedProduct?.id === product.id) && 'active')}
                                            onClick={() => handleProductSelection(product)}
                                            key={product.id}>
                                    <span className='initial'>
                                        <img className='plus-icon' alt='sumar' src={plusIcon}/>{' '}
                                        <span className='name-cont'>
                                            <span className='name'>{product.name}</span>
                                        </span>
                                    </span>
                                            {!!selected &&
                                                <span className='count'>X{selected.amount}</span>}
                                            <span className='lives'>{product.numLives}&nbsp;VIDAS</span>
                                        </div>
                                        {(selectedProduct?.id === product.id) &&
                                            <div className='tools'>
                                                <span>AGREGAR O ELIMINAR EL PRODUCTO</span>
                                                <img onClick={() => addProduct(product)} src={greenPlusIcon} alt=""/>
                                                <img onClick={() => removeProduct(product)} src={redLessIcon} alt=""/>
                                            </div>
                                        }
                                    </React.Fragment>
                                )
                            })}
                        </div>
                    ))}
                </div>
                <div className='buttons'>
                    <OreoButton onClick={handleOkButton}>
                        ACEPTAR
                    </OreoButton>
                </div>
            </div>
        </div>
        , document.body);
};

export default ProductSelectionModal;
