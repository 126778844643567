import classNames from "classnames";
import './RecipePopup.scss'

const RecipePopup = ({onClose, recipe}) => {
    return (<div className='recipe-popup'>
        <div className={classNames('recipe-container', recipe)}>
            <div onClick={onClose}  className='close'>X</div>
        </div>
    </div>)
}

export default RecipePopup;