import TicketsList from "../../scenes/admin/TicketsList/TicketsList";
import Logout from "../../scenes/user/Logout/Logout";
import UsersList from "../../scenes/admin/UsersList/UsersList";
import GamesList from "../../scenes/admin/GamesList/GamesList";
import TicketProductsList from "../../scenes/admin/TicketProductsList/TicketProductsList";

export const paths={
    tickets:'/tickets',
    users:'/users',
    games:'/games',
    ticketProducts:'/ticket',
    logout: '/logout',
};

const adminRoutes=[
    {path:paths.tickets, Component: TicketsList},
    {path:paths.users, Component: UsersList},
    {path:paths.games, Component: GamesList},
    {path:paths.ticketProducts, Component: TicketProductsList},
    {path:paths.logout, Component: Logout},
];

export default adminRoutes;
