import React from 'react'

import './EndPromo.scss';

const EndPromo = () => {
    return (
        <div className='EndPromo' />
    );
}

export default EndPromo;