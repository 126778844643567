
export const releaseDate = 1660885200000;//'2022-08-19 00:00'

export const isGameTime = () => releaseDate < Date.now();

export const getTimeLeft = () => {

    const now = new Date().getTime();
    const diff = releaseDate - now;

    return {
        days: Math.floor(diff / (1000 * 60 * 60 * 24)),
        hours: Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((diff % (1000 * 60)) / 1000)
    }
}
