import moment from 'moment';
import React from 'react'
import './UserDetail.scss';

const UserDetail = ({user, onClose}) => {
    return (
        <div className='UserDetail'>
            <div className='user-info'>
                <div className='close' onClick={onClose}>X</div>

                <h1 className='center'>Detalles de usuario</h1>

                <table className='info-table'>
                    <tbody>
                        <tr>
                            <td>ID</td>
                            <td>{user.id}</td>
                        </tr>
                        <tr>
                            <td>Nombre</td>
                            <td>{user.name}</td>
                        </tr>
                        <tr>
                            <td>Apellido Paterno</td>
                            <td>{user.patLastName}</td>
                        </tr>
                        <tr>
                            <td>Apellido Materno</td>
                            <td>{user.matLastName}</td>
                        </tr>                        
                        <tr>
                            <td>Phone</td>
                            <td>{user.phone}</td>
                        </tr>
                        <tr>
                            <td>Email</td>
                            <td>{user.email}</td>
                        </tr>
                        <tr>
                            <td>Fecha de cumpleaños</td>
                            <td>{ moment(user.birthday).format('DD/MM/YYYY') }</td>
                        </tr>
                        <tr>
                            <td>Vidas</td>
                            <td>{user.numOfLives}</td>
                        </tr>
                        <tr>
                            <td>Puntos</td>
                            <td>{user.points}</td>
                        </tr>
                        <tr>
                            <td>Puntos semanales</td>
                            <td>{user.weeklyPoints}</td>
                        </tr>
                        <tr>
                            <td>Puntos referido</td>
                            <td>{user.pointsAddedToReferrer}</td>
                        </tr>
                        <tr>
                            <td>Código</td>
                            <td>{user.shareCode}</td>
                        </tr>                        
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default UserDetail