import _ from "lodash";

/**
 * This copies 'property' from one object to another. Checking that everything exists or setting null un toObj.property
 * if it doesn't exist
 *
 * @param fromObj
 * @param toObj
 * @param property
 * @param mapToProperty string Change the property name in the receiver to this
 */
 export const copyPropertyOrNull=(fromObj, toObj, property, mapToProperty)=>{
  if(!toObj || !property)
      throw new Error('Error copiando campos.');

  if(!fromObj || typeof fromObj[property]==='undefined' || fromObj[property]==='')
      toObj[mapToProperty||property]=null;

  toObj[mapToProperty||property]=fromObj[property];
};


/**
* This tries to copy
* @param fromObj
* @param toObj
* @param property
*/
export const copyPropertyIdOrNull=(fromObj, toObj, property)=>{
  if(!toObj || !property)
      throw new Error('Error copiando campos.');

  if(!fromObj || !fromObj[property])
      toObj[property]=null;
  else if(typeof fromObj[property] === 'object')
      toObj[property]=fromObj[property].id;
  else
      toObj[property]=fromObj[property];
};

/**
* This copies 'property' from one object to another. Checking that everything exists or setting null un toObj.property
* if it doesn't exist
*
* @param fromObj
* @param toObj
* @param property
* @param message string The message to throw if failed
*/
export const copyPropertyOrThrow=(fromObj, toObj, property, message)=>{
  if(!toObj || !property)
      throw new Error('Error copiando campos.');

  if(!fromObj || typeof fromObj[property]==='undefined' || _.isNull(fromObj[property]) || fromObj[property]==='')
      throw new Error(message || `Campo ${property} inválido`);

  toObj[property]=fromObj[property];
};

/**
* This copies 'property' from one object to another. Checking that everything exists or setting null un toObj.property
* if it doesn't exist
*
* @param fromObj
* @param toObj
* @param property
* @param message string The message to throw if failed
*/
export const copyPropertyToOrThrow=(fromObj, toObj, property, message, mapToProperty)=>{
    if(!toObj || !property)
        throw new Error('Error copiando campos.*');
  
    if(!fromObj || typeof fromObj[property]==='undefined' || _.isNull(fromObj[property]))
        throw new Error(message || `Campo ${property} inválido`);

    toObj[mapToProperty]=fromObj[property];
  };


/**
* This tries to copy
* @param fromObj
* @param toObj
* @param property
* @param message
*/
export const copyPropertyIdOrThrow=(fromObj, toObj, property, message)=>{
  if(!toObj || !property)
      throw new Error('Error copiando campos.');

  if(!fromObj || !fromObj[property])
      throw new Error(message||`Campo ${property} inválido`);

  else if(typeof fromObj[property] === 'object')
      toObj[property]=fromObj[property].id;
  else
      toObj[property]=fromObj[property];
};

/**
* This tries to copy
* @param fromObj
* @param toObj
* @param property
*/
export const copyPropertyValueOrThrow=(fromObj, toObj, property)=>{
    if(!toObj || !property)
        throw new Error('Error copiando campos.');
  
    if(!fromObj || !fromObj[property]?.value)
        throw new Error(`Campo ${property} inválido`);
    
    toObj[property]=fromObj[property].value;
};

/**
* This tries to copy
* @param fromObj
* @param toObj
* @param property
*/
export const copyPropertyValueOrNull=(fromObj, toObj, property)=>{
    if(!toObj || !property)
        throw new Error('Error copiando campos.');
    
    if(!fromObj || !fromObj[property]?.value)
        toObj[property]=null;
    
    toObj[property]=fromObj[property].value;
};