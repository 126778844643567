import React, {useContext, useEffect, useCallback} from 'react'
import './TicketDetail.scss';
import {getUserFullName} from "../../../../services/modelUtils/userUtils";
import moment from "moment";
import {ApiContext} from "../../../../services/api/api-config";
import {getNotifier} from "../../../../services/notifier";
import {ticketsListSGroups} from "../../../../services/modelUtils/ticketUtils";
import ReactImageMagnify from "react-image-magnify";

const TicketDetail = ({ticket, onClose}) => {
    const api = useContext(ApiContext);
    const photoUrl = api.appFiles.getUrl(ticket.appFile);
    const secondPhotoUrl = ticket.secondTicket ? api.appFiles.getUrl(ticket.secondTicket) : null;

    const approveTicket = useCallback(() => {
        api.tickets.update({
            id: ticket.id,
            params: {status: 'APPROVED', sGroups: ticketsListSGroups},
            sGroups: ticketsListSGroups
        }).then(() => {
            getNotifier().success("Ticket aprobado");
            onClose();
        }).catch(() => {
            getNotifier().error("Error al aprobar el ticket");
        })
    }, [api, ticket, onClose]);

    const rejectTicket = useCallback(() => {
        api.tickets.update({
            id: ticket.id,
            params: {status: 'REJECTED', sGroups: ticketsListSGroups},
            sGroups: ticketsListSGroups
        }).then(() => {
            getNotifier().success("Ticket rechazado");
            onClose();
        }).catch(() => {
            getNotifier().error("Error al rechazar el ticket");
        });
    }, [api, ticket, onClose]);


    const onEsc = useCallback((event) => {
        if (event.key === "Escape") {
            onClose();
        }
    }, [onClose]);

    useEffect(() => {
        document.addEventListener("keydown", onEsc, false);

        return () => {
            document.removeEventListener("keydown", onEsc, false);
        };
    }, [onEsc]);

    return (
        <div className='TicketDetail'>
            <div className='ticket-info'>
                <div className='close' onClick={onClose}>X</div>

                <table className='info-table'>
                    <tbody>
                        <tr>
                            <td>ID</td>
                            <td>{ticket.id}</td>
                        </tr>
                        <tr>
                            <td>Usuario</td>
                            <td>{getUserFullName(ticket?.user)}</td>
                        </tr>
                        <tr>
                            <td>Fecha</td>
                            <td>{moment(ticket.createdDate).format('DD/MM/YYYY HH:mm')}</td>
                        </tr>
                        <tr>
                            <td>Número de ticket</td>
                            <td>{ticket.ticketNumber}</td>
                        </tr>
                        <tr>
                            <td>Tienda</td>
                            <td>{ticket.store}</td>
                        </tr>
                        <tr>
                            <td>Estado</td>
                            <td>{ticket.status}</td>
                        </tr>
                        <tr>
                            <td>Productos</td>
                            <td><ul>
                                {ticket.ticketProducts.map(ticketProduct => (
                                    <li key={ticketProduct.id}>
                                        {ticketProduct.amount} X {ticketProduct.product.name}
                                    </li>
                                ))}
                            </ul></td>
                        </tr>
                    </tbody>
                </table>

                <br/>

                <ReactImageMagnify className='image-magnifier'
                                   {...{
                    smallImage: {
                        alt: 'Foto',
                        isFluidWidth: true,
                        src: photoUrl
                    },
                    largeImage: {
                        src: photoUrl,
                        width: 2000,
                        height: 2000
                    },
                }} />

                {secondPhotoUrl && 
                    <ReactImageMagnify className='image-magnifier image-magnifier--mt'
                        {...{
                        smallImage: {
                            alt: 'Foto',
                            isFluidWidth: true,
                            src: secondPhotoUrl
                        },
                        largeImage: {
                            src: secondPhotoUrl,
                            width: 2000,
                            height: 2000
                        },
                    }} />}

                <div className='action-buttons'>
                    {ticket.status !== 'APPROVED' &&
                        <button className='approve' onClick={approveTicket}>Aprobar</button>}
                    {ticket.status !== 'REJECTED' &&
                        <button className='reject' onClick={rejectTicket}>Rechazar</button>}
                </div>
            </div>
        </div>
    );
}

export default TicketDetail;
