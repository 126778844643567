import React, { useEffect } from 'react'
import ArrowImage from './images/flecha_morada.png';
import { Link } from 'react-router-dom';
import './PromotionFinishedModal.scss';

const PromotionFinishedModal = ({onClose}) => {
    useEffect(() => {
        document.body.style.overflowY = 'hidden';

        return () =>{
          document.body.style.overflowY = 'auto';
        }
    }, []);

    return (
        <div className='PromotionFinishedModal'>
            <div className='PromotionFinishedModal__content'>
                <div className='close-button' onClick={onClose}>
                    <div className='button-x'>
                        X
                    </div>
                </div>

                <h2 className='PromotionFinishedModal__title PromotionFinishedModal__title--1'>
                    ¡Gracias por sumar
                </h2>
                <h2 className='PromotionFinishedModal__title PromotionFinishedModal__title--2'>
                    diversión con factor Oreo!
                </h2>
                <p className='PromotionFinishedModal__text1'>
                    A partir de ahora ya no puedes sumar premios.<br/>
                    ¡Siguenos en nuestras redes y espera pronto nuevos juegos!
                </p>
                <p className='PromotionFinishedModal__text2'>
                    Puedes consultar el ranking general <span className='arrow-container-button'><img src={ArrowImage} alt="arrow" className='arrow-button' /></span> <Link to='/winners'>aquí</Link>.<br/>
                    !Hasta pronto¡
                </p>
            </div>            
        </div>
    );
}

export default PromotionFinishedModal;