/**
 * La Promoción tendrá vigencia desde el:
 *   19 de agosto de 2022 a las 00:01 horas (hora del centro de México) y 
 *      finalizará el 07 de octubre de 2022 a las 23:59 horas (hora del centro de México) o antes, 
 *      en el caso de presentarse alguna contingencia de fuerza mayor. (en lo sucesivo, la “Vigencia”).
 */

const showPromotionFinishModalDate = 1665205200000; //'2022-10-08 00:00'

//export const isPromotionFinishedDate = () => (showPromotionFinishModalDate <= Date.now() && Date.now() <= hidePromotionFinishModalDate);
export const isPromotionFinishedDate = () => showPromotionFinishModalDate <= Date.now();
