import React, {useCallback, useContext, useState} from 'react';
import './Login.scss';
import {ApiContext} from "../../../services/api/api-config";
import useFormState from "../../../hooks/useFormState";
import {LOGIN_STATE, LOGIN_LOADING_ID} from "tide-api";
import {useSelector} from "react-redux";
import GeneralContainer from '../../../components/layout/GeneralContainer/GeneralContainer';
import TextInput from '../../../components/forms/TextInput/TextInput';
import {getNotifier} from '../../../services/notifier';
import leftBottomCookie from '../../../assets/images/login/pleca_derecha_iniciar_sesion_web.png';
import rightBottomCookie from '../../../assets/images/login/pleca_derecha_inicar_sesion_web.png';
import loginMainImage from '../../../assets/images/login/familia_web.png';
import ReCAPTCHA from "react-google-recaptcha";
import {paths} from "../../../services/routes/notLoggedRoutes";
import {Link} from "react-router-dom";
import loginButton from '../../../assets/images/login-and-register/boton_inciar_sesion.png';
import loginFooterImage from '../../../assets/images/login/familia_web.png';

const googleCaptchaKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_API_KEY;

const Login = () => {
    const api = useContext(ApiContext);
    const [captchaCode, setCaptchaCode] = useState(null);

    const {form, handleSimpleChange} = useFormState({});

    const login = useCallback((e) => {
        e.preventDefault();
        if (!captchaCode)
            return getNotifier().error("Es necesario confirmar el captcha");

        if (!form.user || !form.pass) {
            getNotifier().error("Todos los campos son obligatorios");
            return;
        }

        api.login(form.user, form.pass, captchaCode);
    }, [api, form, captchaCode]);


    // --- login state message ------
    const loginState = useSelector(({api}) => api.loggedIn);
    const loading = useSelector(({loadingIds}) => loadingIds[LOGIN_LOADING_ID] || loadingIds['Login.me']);

    let loginMsg;
    switch (loginState) {
        case LOGIN_STATE.LOGIN_ERROR:
            loginMsg = 'Lo sentimos, hubo un error al iniciar sesión, verifica que el captcha sea correcto';
            break;
        case LOGIN_STATE.BAD_CREDENTIALS:
            loginMsg = 'El usuario y contraseña no coinciden';
            break;
        default:
            loginMsg = null;
            break;
    }

    return (
        <GeneralContainer className='GeneralContainer--display-block' backButton={true} hideMenuOnMobile={true} footer>
            <div className='Login'>
                <div className='Login__container'>
                    <div className='Login__left-column'>
                        <img src={loginMainImage} className='Login__left-image' alt='Login'/>

                    </div>
                    <div className='Login__right-column'>
                        <form onSubmit={login}>
                            <h1 className='Login__title'>INICIO DE SESIÓN</h1>
                            <div className='input-block'>
                                <TextInput
                                    placeholder="CORREO"
                                    label="CORREO"
                                    onChange={handleSimpleChange('user')}
                                    value={form.user || ''}
                                />
                            </div>
                            <div className='input-block'>
                                <TextInput
                                    placeholder="CONTRASEÑA"
                                    label="CONTRASEÑA"
                                    type="password"
                                    onChange={handleSimpleChange('pass')}
                                    value={form.pass || ''}
                                />
                            </div>

                            <div className='input-block space-between'>
                                <span className="forgot-pass-left">
                                <Link to={paths.forgotPass} className="forgot-pass">
                                    ¿OLVIDASTE TU CONTRASEÑA?
                                </Link>
                                    </span>
                                <span>
                                    <input type="checkbox"/>
                                    RECORDAR SESIÓN
                                </span>
                            </div>

                            {loginMsg &&
                                <p className='error-message'>{loginMsg}</p>}

                            <div className="captcha-container mx-auto d-flex justify-content-center">
                                <ReCAPTCHA sitekey={googleCaptchaKey} className='captcha' onChange={setCaptchaCode}/>
                            </div>
                            <button
                                className='oreo-button'
                                type='submit'
                                disabled={loading}
                            >
                                <img src={loginButton} alt="Login button" />
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            <img src={leftBottomCookie} className='Login__left-bottom-cookie' alt='Galleta'/>
            <img src={rightBottomCookie} className='Login__right-bottom-cookie' alt='Galleta'/>

            <div className='login-footer-mobile'>
                <img src={loginFooterImage} alt="Familia" />
            </div>
        </GeneralContainer>
    );
};

export default Login;

