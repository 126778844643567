import React, {useCallback, useState} from 'react';
import './Game.scss';
import Instructions from "./components/Instructions/Instructions";
import GeneralContainer from "../../../components/layout/GeneralContainer/GeneralContainer";
import {getRandomGame} from './controller/gameUtils';
import Logo from '../../../assets/images/logo_factor_oreo.png';
import BotLeft from '../../../assets/images/corners/pleca_izquierda_juego_web.png';
import BotRight from '../../../assets/images/corners/pleca_derecha_juego_web.png';
import Bottom from './assets/layout/pleca_juego_mobile.png';
import GamePlay from "./components/GamePlay/GamePlay";
import GameOver from "./components/GameOver/GameOver";
import classNames from "classnames";
import {useSelector} from "react-redux";
import { paths } from '../../../services/routes/appRoutes';

const Game = () => {

    const me = useSelector( s=>s.api.me );
    const [gamePhase, setGamePhase] = useState(0);
    const [game, setGame] = useState(()=>getRandomGame());
    const [result, setResult] = useState(null);

    const onGameOver = useCallback((result)=>{
        setResult(result);
        setGamePhase(2);
    },[]);

    const playAgain = useCallback(()=>{
        setGamePhase(0);
        setResult(null);
        setGame(getRandomGame());
    },[]);

    return (
        <GeneralContainer 
            className={classNames("Game", gamePhase===2&&'over' )} 
            menu={false} 
            unicorn
            backButtonRoute={paths.userHome}
        >

            <div className='game-header'>
                <img alt={'oreo'} src={Logo} className='logo'/>
                <div className='lives-container'>
                    <span className='lives'>{me.numOfLives}</span>
                </div>
            </div>
            <div className='game-container'>
                {gamePhase === 0 && <Instructions game={game} onContinue={()=>setGamePhase(1)}/>}
                {gamePhase === 1 && <GamePlay game={game} onGameOver={onGameOver} />}
                {gamePhase === 2 && <GameOver game={game} result={result} onPlayAgain={playAgain} />}
            </div>
            <div className='game-footer'>
                <img alt={'corner'} className='corner web left' src={BotLeft}/>
                <img alt={'corner'} className='corner web right' src={BotRight}/>
                <img alt={'bottom'} className='bottom mobile' src={Bottom}/>
            </div>
        </GeneralContainer>
    );
};

export default Game;
