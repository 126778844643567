import React from 'react'
import Select from "react-select";
import './DateSelector.scss';
import moment from 'moment';

const toYear = 2004;
const maxYears = 90;
const years = [...Array(toYear - (toYear-maxYears) + 1).keys()].map(x => x + (toYear-maxYears));
const yearOptions = years.map(year => ({label: year, value: year}));

export const CALENDAR_MONTHS = [
    "1",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12"
];
const monthOptions = CALENDAR_MONTHS.map((month, index) => ({label: month, value: index+1}));



const DateSelector = ({ handleSimpleChange ,text, form }) => {
    
    const dayOptions = [...Array(moment(`2000-01`, "YYYY-MM").daysInMonth() ).keys()].map(x => x + 1).map(x => ({ label: x, value: x}));
    return (
        
        <div className='DateSelector'>
            <label className='label-date'>{text}</label>
            <Select
                className='tide-react-select'
                classNamePrefix='tide-react-select'
                placeholder="Día"
                options={dayOptions}
                value={form.day}
                onChange={handleSimpleChange('day')}
            />
            <Select
                className='tide-react-select'
                classNamePrefix='tide-react-select'
                placeholder="Mes"
                options={monthOptions}
                value={form.month}
                onChange={handleSimpleChange('month')}
            /> 
            <Select
                className='tide-react-select'
                classNamePrefix='tide-react-select'
                placeholder="Año"
                options={yearOptions}
                value={form.year}
                onChange={handleSimpleChange('year')}
            />            
        </div>
    );
}

export default DateSelector;