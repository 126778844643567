import React from 'react';

const LoadingIcon = () => {

    return (
        <div className={"LoadingIcon"}>
            Loading...
        </div>
    );
};

export default LoadingIcon;
